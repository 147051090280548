import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import Wrapper from "../../layout/Logged/Wrapper";
import Grid from '@mui/material/Grid';
import {
	create,
	deleteBeneficiary,
	find,
	get,
	getBeneficiaryTypes,
	getRelationshipTypes,
	update
} from "../../actions/Beneficiaries";
import PropTypes from "prop-types";
import Loading from "../../components/Loading";
import BeneficiariesFormModal from "../../components/Beneficiaries/BeneficiariesFormModal";
import {fromAddress, setDefaults} from "react-geocode";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import BeneficiariesNewCard from "../../components/Beneficiaries/BeneficiariesNewCard";
import UpperInstructionCard from "../../components/UpperInstructionCard";
import PlusIcon from "../../images/Caminho 2269.svg";
import {useTranslation} from "react-i18next";
import {me} from "../../actions/Profile";
import MyAccountLeftMenu from "../../components/MyAccountLeftMenu";
import {capitalizeFirstLetter} from "../../utils/Helpers";
import {useNavigate} from "react-router-dom";

/**
 * @param e
 * @param beneficiaries
 * @param id
 * @param setProportion
 */
const handleProportionInput = (
	e,
	beneficiaries = [],
	id = null,
	setProportion = () => {
	},
) => {
	let inputProportion = e.target.value;
	const maxProportion = 100;
	const regexNumber = /^[1-9][0-9]?$|^100$/;

	let currentMaxProportionAllowed = maxProportion - calculateProportion(beneficiaries ?? [], id);

	if (inputProportion > currentMaxProportionAllowed) {
		setProportion(currentMaxProportionAllowed);
	} else {
		if (inputProportion === "" || regexNumber.test(inputProportion)) {
			if ((maxProportion - inputProportion) >= 0) {
				setProportion(inputProportion);
			}
		}
	}
}

/**
 * @param formModalOpen
 * @param id
 * @param setBeneficiaryTypeId
 * @param setBeneficiaryRelationshipTypeId
 * @param setName
 * @param setPostalCode
 * @param setTown
 * @param setCountry
 * @param setStreetName
 * @param setProportion
 * @param setAddress
 * @param setBirthdate
 * @param setPostCodeErrorText
 * @param setFoundAddress
 * @param setFormModalOpen
 * @param setId
 * @param find
 * @private
 */
const toggleFormModal = (
	formModalOpen = false,
	id: any = null,
	setBeneficiaryTypeId = () => {
	},
	setBeneficiaryRelationshipTypeId = () => {
	},
	setName = () => {
	},
	setPostalCode = () => {
	},
	setTown = () => {
	},
	setCountry = () => {
	},
	setStreetName = () => {
	},
	setProportion = () => {
	},
	setAddress = () => {
	},
	setBirthdate = () => {
	},
	setPostCodeErrorText = () => {
	},
	setFoundAddress = () => {
	},
	setFormModalOpen = () => {
	},
	setId = () => {
	},
	find = () => {
	}
) => {
	setFormModalOpen(!formModalOpen);
	if (id) {
		find(id);
		setId(id);
		setBeneficiaryTypeId(0);
		return;
	}
	setId(0);
	setBeneficiaryTypeId(1);
	setBeneficiaryRelationshipTypeId(0);
	setName("");
	setPostalCode("");
	setTown("");
	setCountry("");
	setStreetName("");
	setProportion("");
	setAddress([]);
	setPostCodeErrorText("");
	setFoundAddress(false);
	setBirthdate("");
}

const findBeneficiaryAddress = (
	postalCode = "",
	setPostCodeErrorText = () => {
	},
	setFoundAddress = () => {
	},
	setTown = () => {
	},
	setCountry = () => {
	}
) => {
	setPostCodeErrorText("");
	setFoundAddress(false);
	setDefaults({
		key: process.env.REACT_APP_GEOCODE_API_KEY, // Your API key here.
		language: "en", // Default language for responses.
		region: "uk", // Default region for responses.
	});
	fromAddress(postalCode)
		.then((response = {
				results: [{
					address_components: {
						long_name: "",
						short_name: "",
						types: [],
					}
				}]
			}) => {
				if (response.results && response.results.length > 0) {
					response.results[0].address_components.forEach(address => {
						if (address.types.filter(t => t === "postal_town").length > 0) {
							setTown(address.short_name);
						} else {
							setTown(response.results[0].address_components[3].short_name);
						}
						if (address.types.filter(t => t === "country").length > 0) {
							setCountry(address.long_name);
						}
					});
					setFoundAddress(true);
					setPostCodeErrorText("");
				}
			},
			(error) => {
				console.error(error);
				setPostCodeErrorText("Address not found with this postal code.");
				setFoundAddress(false);
			}
		)
		.catch((error) => {
			console.error(error);
			setPostCodeErrorText("Address not found with this postal code.");
			setFoundAddress(false);
		});
}

/**
 * @private
 */
const addBeneficiary = (
	name = "",
	postalCode = "",
	proportion = 0,
	beneficiaryTypeId,
	beneficiaryRelationshipTypeId,
	birthdate,
	town,
	country,
	streetName,
	doorNumber,
	id,
	formModalOpen,
	setFormModalOpen = () => {
	},
	create = () => {
	},
	update = () => {
	}
) => {
	if (!id) {
		if ((name && postalCode && proportion && beneficiaryTypeId && beneficiaryRelationshipTypeId) || (beneficiaryTypeId === 2)) {
			create({
				beneficiaries: {
					name,
					proportion,
					beneficiary_type_id: beneficiaryTypeId,
					beneficiary_relationship_type_id: beneficiaryTypeId === 2 ? null : beneficiaryRelationshipTypeId,
					birthdate: beneficiaryTypeId === 1 ? birthdate : null,
					address: {
						postal_code: postalCode,
						town,
						country,
						street_name: streetName,
						door_number: doorNumber,
					}
				}
			});
		}
	} else {
		update(id, {
			beneficiaries: {
				name,
				proportion,
				beneficiary_type_id: beneficiaryTypeId,
				beneficiary_relationship_type_id: beneficiaryRelationshipTypeId,
				birthdate: beneficiaryTypeId === 1 ? birthdate : null,
				address: {
					beneficiary_id: id,
					postal_code: postalCode,
					town,
					country,
					street_name: streetName,
					door_number: doorNumber,
				}
			}
		});
	}

	setFormModalOpen(!formModalOpen);
}

/**
 * @param beneficiaries
 * @param id
 * @returns {number}
 * @private
 */
const calculateProportion = (
	beneficiaries = [],
	id = null
): number => {
	let total = 0;

	if (beneficiaries.length > 0) {
		if (id) {
			beneficiaries?.filter((b = {id: null}) => b.id !== id)?.forEach((b = {
				proportion: 0,
			}) => total += parseFloat(b.proportion));
		} else {
			beneficiaries.forEach((b = {
				proportion: 0,
			}) => total += parseFloat(b.proportion));
		}
	}

	return total;
}

/**
 * @param beneficiaries
 * @param beneficiary_relationship_types
 * @param beneficiary_types
 * @param beneficiary
 * @param beneficiaries_loading
 * @param get
 * @param deleteBeneficiary
 * @param getBeneficiaryTypes
 * @param getRelationshipTypes
 * @param create
 * @param find
 * @param update
 * @param profile_data
 * @param profile_loading
 * @returns {JSX.Element}
 * @constructor
 */
const Beneficiaries = (
	{
	beneficiaries = [],
	beneficiary_relationship_types = [],
	beneficiary_types = [],
	beneficiary = [],
	beneficiaries_loading = false,
	get = () => {},
	deleteBeneficiary = () => {},
	getBeneficiaryTypes = () => {},
	getRelationshipTypes = () => {},
	create = () => {},
	find = () => {},
	update = () => {},
	profile_data = {
		language: "",
	},
	profile_loading = false,
}
) => {
	const [formModalOpen, setFormModalOpen] = useState(false),
		[beneficiaryTypeId, setBeneficiaryTypeId] = useState(1),
		[beneficiaryRelationshipTypeId, setBeneficiaryRelationshipTypeId] = useState(0),
		[name, setName] = useState(""),
		[postalCode, setPostalCode] = useState(""),
		[town, setTown] = useState(""),
		[country, setCountry] = useState(""),
		[streetName, setStreetName] = useState(""),
		[doorNumber, setDoorNumber] = useState(""),
		[proportion, setProportion] = useState(""),
		[address, setAddress] = useState([]),
		[postCodeErrorText, setPostCodeErrorText] = useState(""),
		[foundAddress, setFoundAddress] = useState(false),
		[birthdate, setBirthdate] = useState(""),
		[id, setId] = useState(null),
		{t} = useTranslation(),
		history = useNavigate();

	useEffect(() => {
		get();
		getBeneficiaryTypes();
		getRelationshipTypes();
	}, [
		get,
		getBeneficiaryTypes,
		getRelationshipTypes,
		profile_data,
	]);

	useEffect(() => {
		if (id) {
			if (beneficiary?.id) {
				if (beneficiary?.beneficiary_type_id === 1 && beneficiary?.birthdate) {
					let formattedMonth = beneficiary?.birthdate?.split("-");
					setBirthdate(formattedMonth[2] + "/" + formattedMonth[1] + "/" + formattedMonth[0]);
				} else {
					setBirthdate("");
				}
				setName(beneficiary?.name);
				setProportion(beneficiary?.proportion);
				if (beneficiaryTypeId === 0) {
					setBeneficiaryTypeId(beneficiary?.beneficiary_type_id);
				}
				setBeneficiaryRelationshipTypeId(beneficiary?.beneficiary_relationship_type_id);
				if (beneficiary?.address) {
					setPostalCode(beneficiary?.address?.postal_code);
					setDoorNumber(beneficiary?.address?.door_number);
					setStreetName(beneficiary?.address?.street_name);
				}
			}
		}
		const delayDebounceFn = setTimeout(() => {
			findBeneficiaryAddress(
				postalCode,
				setPostCodeErrorText,
				setFoundAddress,
				setTown,
				setCountry
			)
		}, 1000);
		return () => clearTimeout(delayDebounceFn);
	}, [
		beneficiary?.address,
		beneficiaryTypeId,
		id,
		setFormModalOpen,
		beneficiary?.id,
		beneficiary?.birthdate,
		beneficiary?.name,
		beneficiary?.proportion,
		beneficiary?.beneficiary_type_id,
		beneficiary?.beneficiary_relationship_type_id,
		postalCode
	]);

	if (beneficiaries_loading || profile_loading) {
		return (
			<Loading absolute/>
		);
	}

	return (
		<Wrapper
			hideFooter={true}
			history={history}
			textHeader={"Beneficiaries"}
			t={t}
		>
			<UpperInstructionCard
				title={t("individual.my_account.beneficiaries.title")}
				subtitle1={"Lorem Ipsum is simply dummy text of the printing and typesetting. Lorem Ipsum has been the industry’s"}
			/>
			<Grid
				xl={12}
				lg={12}
				md={12}
				item
				direction={"row"}
				container
				className={"grid"}
			>
				<MyAccountLeftMenu
					history={history}
					currentPage={"Beneficiaries"}
					t={t}
					capitalizeFirstLetter={capitalizeFirstLetter}
					myHeight={window.innerHeight}
				/>
				<Grid
					xl={9.5}
					lg={9.5}
					md={12}
					sm={12}
					xs={12}
					item
					direction={"row"}
					container
					justifyContent={"center"}
					id={"my-account-design"}
				>
					<Grid
						xl={9}
						lg={9}
						md={9}
						sm={9}
						xs={11}
						item
						justifyContent={"left"}
						direction={"row"}
						container
					>
						<Grid
							item
							xl={12}
							lg={12}
							md={12}
							sm={12}
							xs={12}
						>
							<Grid
								item
								xl={12}
								lg={12}
								md={12}
								sm={12}
								container
								className={"display"}
							>
								<Grid
									item
									xl={6}
									lg={8}
									md={8}
								>
									<Typography
										className={"beneficiaries-title"}
									>
										Add one or more beneficiaries to your account
									</Typography>
								</Grid>
							</Grid>
							<Grid
								item
								xl={12}
								lg={12}
								md={12}
								container
								className={"display"}
							>
								<Grid
									item
									xl={6}
									lg={8}
									md={8}
								>
									<Typography
										className={"beneficiaries-subtitle"}
									>
										Nominate a beneficiary who will receive a lump sum payment if you don’t take any, or all of your pension savings before you die.
									</Typography>
								</Grid>
							</Grid>
							<Grid
								item
								xl={12}
								lg={12}
								md={12}
								sm={12}
								xs={12}
								container
							>
								<Grid
									item
									xl={3}
									lg={4}
									md={4}
									sm={6}
									xs={6}
								>
									<Typography
										className={"beneficiaries-proportion"}
									>
										{t("individual.my_account.beneficiaries.total_proportion")}
									</Typography>
								</Grid>
								<Grid
									item
									xl={3}
									lg={4}
									md={4}
									sm={6}
									xs={6}
								>
									<Typography
										textAlign={"end"}
										className={"beneficiaries-proportion"}
									>
										{calculateProportion(beneficiaries)}%
									</Typography>
								</Grid>
							</Grid>
							<Grid
								item
								xl={12}
								lg={12}
								md={12}
								sm={12}
								xs={12}
								container
							>
								<Grid
									item
									xl={6}
									lg={8}
									md={8}
									sm={12}
									xs={12}
								>
									<LinearProgress
										variant={"determinate"}
										value={calculateProportion(beneficiaries)}
										className={"ruler"}
									/>
								</Grid>
							</Grid>
							<Grid
								item
								xl={12}
								lg={12}
								md={12}
								sm={12}
								xs={12}
								container
							>
								<Grid
									item
									xl={3}
									lg={4}
									md={4}
									sm={12}
									xs={12}
									onClick={() =>
										toggleFormModal(
											formModalOpen,
											null,
											setBeneficiaryTypeId,
											setBeneficiaryRelationshipTypeId,
											setName,
											setPostalCode,
											setTown,
											setCountry,
											setStreetName,
											setProportion,
											setAddress,
											setBirthdate,
											setPostCodeErrorText,
											setFoundAddress,
											setFormModalOpen,
											setId
										)
									}
								>
									<Typography
										className={"beneficiaries-button"}
									>
										<img
											src={PlusIcon}
											alt={"icon"}
										/>
										{t("individual.my_account.beneficiaries.add_beneficiaries")}
									</Typography>
								</Grid>
							</Grid>
							<Grid
								item
								xl={12}
								lg={12}
								md={12}
								sm={12}
								xs={12}
								container
							>
								<Grid
									item
									xl={3}
									lg={4}
									md={4}
									sm={12}
									xs={12}
								>
									<Typography
										className={"beneficiaries-proportion-header"}
									>
										{t("individual.my_account.beneficiaries.title")}
									</Typography>
								</Grid>
								<Grid
									container
									item
									xl={12}
									lg={12}
									md={12}
									sm={12}
									xs={12}
									className={"grid-general"}
									overflow={"scroll"}
								>
									{
										beneficiaries.map(b =>
											<BeneficiariesNewCard
												beneficiary={b}
												deleteBeneficiary={deleteBeneficiary}
												toggleFormModal={toggleFormModal}
												formModalOpen={formModalOpen}
												setBeneficiaryTypeId={setBeneficiaryTypeId}
												setBeneficiaryRelationshipTypeId={setBeneficiaryRelationshipTypeId}
												setName={setName}
												setPostalCode={setPostalCode}
												setTown={setTown}
												setCountry={setCountry}
												setStreetName={setStreetName}
												setProportion={setProportion}
												setBirthdate={setBirthdate}
												setAddress={setAddress}
												setPostCodeErrorText={setPostCodeErrorText}
												setFoundAddress={setFoundAddress}
												setFormModalOpen={setFormModalOpen}
												setId={setId}
												find={find}
												t={t}
											/>
										)
									}
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
				<BeneficiariesFormModal
					capitalizeFirstLetter={capitalizeFirstLetter}
					beneficiaries={beneficiaries}
					beneficiary_relationship_types={beneficiary_relationship_types}
					beneficiary_types={beneficiary_types}
					handleClose={() =>
						setFormModalOpen(!formModalOpen)
					}
					open={formModalOpen}
					beneficiaryTypeId={beneficiaryTypeId}
					beneficiaryRelationshipTypeId={beneficiaryRelationshipTypeId}
					name={name}
					postalCode={postalCode}
					town={town}
					birthdate={birthdate}
					country={country}
					streetName={streetName}
					doorNumber={doorNumber}
					proportion={proportion}
					address={address}
					postCodeErrorText={postCodeErrorText}
					foundAddress={foundAddress}
					id={id}
					setBeneficiaryTypeId={setBeneficiaryTypeId}
					setBeneficiaryRelationshipTypeId={setBeneficiaryRelationshipTypeId}
					setName={setName}
					setPostalCode={setPostalCode}
					setBirthdate={setBirthdate}
					setDoorNumber={setDoorNumber}
					setStreetName={setStreetName}
					setProportion={setProportion}
					setAddress={setAddress}
					setFormModalOpen={setFormModalOpen}
					handleProportionInput={handleProportionInput}
					addBeneficiary={addBeneficiary}
					create={create}
					update={update}
					t={t}
				/>
		</Wrapper>
	);
};

const mapStateToProps = state => {
	return {
		...state.Beneficiaries,
		...state.Profile,
	};
}

const mapDispatchToProps = dispatch => ({
	get: () => dispatch(get(['address'])),
	deleteBeneficiary: (id) => dispatch(deleteBeneficiary(id)),
	getBeneficiaryTypes: () => dispatch(getBeneficiaryTypes()),
	getRelationshipTypes: () => dispatch(getRelationshipTypes()),
	create: (data = {}) => dispatch(create(data)),
	find: (id) => dispatch(find(id, ['address'])),
	update: (id, data) => dispatch(update(id, data)),
	me: dispatch(me()),
});

mapStateToProps.propTypes = {
	beneficiaries: PropTypes.array,
};

mapDispatchToProps.propTypes = {
	me: PropTypes.func.isRequired,
	get: PropTypes.func.isRequired,
	getRelationshipTypes: PropTypes.func.isRequired,
	getBeneficiaryTypes: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Beneficiaries);