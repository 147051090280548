import {forceScrollUp, retrieveCollegiaAccessToken} from "../../utils/Helpers";
import ProfileService from "../../services/Profile";
import {
	CHECK_NIN_DUPLICATE,
	COMPLETE_WALKTHROUGH,
	CONFIRM_REQUEST_CHANGE_PROFILE_CODE,
	CREATE_FIRST_PASSWORD_ERROR,
	CREATE_FIRST_PASSWORD_STARTED,
	CREATE_FIRST_PASSWORD_SUCCESS,
	FETCH_EMPLOYERS_DATA,
	FETCH_PROFILE_DATA,
	GENERATE_HMRC_ACCESS_TOKEN_STATE,
	GET_ACTIVATION_TOKEN_CLEAR,
	GET_ACTIVATION_TOKEN_ERROR,
	GET_ACTIVATION_TOKEN_STARTED,
	GET_ACTIVATION_TOKEN_SUCCESS,
	IFA_ACCESS,
	IFA_ACCESS_ERROR,
	IFA_ACCESS_SUCCESS,
	LOGIN,
	MY_PROJECTIONS,
	PROCESS_PAST_EMPLOYMENTS,
	PROCESS_RESIDENCY_STATUS,
	PROFILE_CHANGE_CODE_CHECKED,
	REQUEST_CHANGE_EMAIL,
	RETRIEVE_NEW_PASSWORD,
	RETRIEVE_OPTIONS_VALUES, RETURN_MONTHLY_PROJECTION,
	UPDATE_MY_STATE_PENSION, UPDATE_PROFILE,
	UPDATE_RETIREMENT_AGE
} from "./types";
import {EMAIL_CHANGED} from "../ActivationCode/types";
import * as SnackBar from "../SnackBar";
import axios from "axios";
import Request from "../../utils/Request";

/**
 * Retrieve my profile data.
 *
 * @returns {function(...[*]=)}
 */
export const me = (scrollUp: boolean = false, loading: boolean = true) => dispatch => {
	if (retrieveCollegiaAccessToken()) {
		dispatch({
			type: FETCH_PROFILE_DATA,
			profile_loading: true,
			loading: true,
			showChangeInputBox: false,
		});
		dispatch({
			type: EMAIL_CHANGED,
			canProceed: false,
			errorMessage: "",
			showChangeInputBox: false,
		});

		new ProfileService()
			.me()
			.then(response => {
				dispatch({
					type: FETCH_PROFILE_DATA,
					profile_loading: false,
					loading: false,
					showChangeInputBox: false,
					profile_data: {
						...response?.data?.data
					},
				});

				localStorage.setItem("profile_data", JSON.stringify({
					...response?.data?.data
				}));
				if (window.location.pathname !== "/confirm-monthly-contribution") {
					if (response?.data?.data?.prepare_emandate?.active) {
						window.location = "/confirm-monthly-contribution";
					}
					else if (response?.data?.data?.individual_monthly?.active === true &&
						response?.data?.data?.payment_schedule === null) {
						window.location = "/confirm-monthly-contribution";
					}

				}

				if (scrollUp) {
					forceScrollUp();
				}
			})
			.catch(error => {
				if (error.response) {
					if (error.response.status === 401) {
						localStorage.clear();
						window.location = "/login";
					}
				}

				dispatch({
					type: FETCH_PROFILE_DATA,
					profile_loading: false,
					loading: false,
					showChangeInputBox: false,
					profile_data: JSON.parse(localStorage.getItem("profile_data")),
				});

				console.error(error);
			});
	} else {
		localStorage.clear();
		window.location = "/login";
		dispatch({
			type: FETCH_PROFILE_DATA,
			profile_loading: false,
			loading: false,
			showChangeInputBox: false,
		});
	}
};

/**
 * Finish the user's walkthrough
 *
 * @param forceRefresh
 * @returns {function(...[*]=)}
 */
export const completeWalkthrough = (forceRefresh = false) => dispatch => {
	if (retrieveCollegiaAccessToken()) {
		dispatch({
			type: COMPLETE_WALKTHROUGH,
			profile_loading: true,
		});

		new ProfileService()
			.completeWalkthrough()
			.then(response => {

				dispatch({
					type: COMPLETE_WALKTHROUGH,
					profile_loading: false,
					profile_data: {
						...response?.data?.data
					},
					walkthrough_complete: true,
					code_confirmed: false,
					showChangeInputBox: false,
				});

				if (forceRefresh) {
					dispatch(me());
				}
			})
			.catch(error => {
				if (error.response) {
					if (error.response.status === 401) {
						localStorage.clear();
						window.location = "/login";
					}
				}
				dispatch({
					type: FETCH_PROFILE_DATA,
					profile_loading: false,
					code_confirmed: false,
					showChangeInputBox: false,
				});
				console.error(error);
			});
	}
};

/**
 * @param data
 * @param redirectUrl
 * @returns {function(*): void}
 */
export const login = (data = false, redirectUrl: string = "/main-page") => dispatch => {
	dispatch({
		type: LOGIN,
		slider_loading: true,
		login_text: "",
		code_confirmed: false,
		showChangeInputBox: false,
	});

	if (data) {
		new ProfileService()
			.login(data)
			.then(response => {
				const {
					data = false
				} = response;
				
				if (data.data) {
					const {
						token = ""
					} = data.data;

					if (token !== "") {
						if (data.data.individualStatus < 1) {
							dispatch({
								type: LOGIN,
								slider_loading: false,
								login_text: "You cannot login at moment, contact hello@collegia.co.uk for more details.",
								code_confirmed: false,
								showChangeInputBox: false,
							});
						} else {
							dispatch({
								type: LOGIN,
								slider_loading: true,
								login_text: "",
								code_confirmed: false,
								showChangeInputBox: false,
							});
							localStorage.clear();
							localStorage.setItem("collegiaToken", token);
							window.location.href = redirectUrl;
						}
					} else {
						dispatch({
							type: LOGIN,
							slider_loading: false,
							login_text: "Email / password wrong",
							code_confirmed: false,
							showChangeInputBox: false,
						});
					}
				} else {
					dispatch({
						type: LOGIN,
						slider_loading: false,
						login_text: "Email / password wrong",
						code_confirmed: false,
						showChangeInputBox: false,
					});
				}
			})
			.catch(error => {
				dispatch({
					type: LOGIN,
					slider_loading: false,
					login_text: "Email / password wrong",
					code_confirmed: false,
					showChangeInputBox: false,
				});
				console.error(error);
			});
	}
};

/**
 * @param data
 * @returns {function(...[*]=)}
 */
export const updateRetirementAge = (data = false) => dispatch => {
	if (data) {
		dispatch({
			type: UPDATE_RETIREMENT_AGE,
			code_confirmed: false,
			showChangeInputBox: false,
		});
		if (data?.profiles?.simulation === false) {
			dispatch({
				type: UPDATE_RETIREMENT_AGE,
				slider_loading: false
			});
		} else if (data?.profiles?.simulation === true) {
			dispatch({
				type: UPDATE_RETIREMENT_AGE,
				slider_loading: true
			});
		}
		new ProfileService()
			.updateRetirementAge(data)
			.then((response) => {
				dispatch({
					type: UPDATE_RETIREMENT_AGE,
					slider_loading: false,
					my_projections: response?.data?.data,
					code_confirmed: false,
					showChangeInputBox: false,
				});
				if (data?.profiles?.simulation === false) {
					dispatch({
						type: UPDATE_RETIREMENT_AGE,
						profile_data: {
							expected_retirement_age: data.profiles.expected_retirement_age,
						},
					});
				}
			})
			.catch(error => {
				if (error?.response) {
					if (error?.response?.status === 401) {
						localStorage.clear();
						window.location = "/login";
					}
				}
				dispatch({
					type: UPDATE_RETIREMENT_AGE,
					slider_loading: false,
					code_confirmed: false,
					showChangeInputBox: false,
				});
				console.error(error);
			});
	}
};

/**
 * @param data
 * @returns {function(...[*]=)}
 */
export const modifyIfaAccess = (data = []) => dispatch => {
	if (data) {
		dispatch({
			type: IFA_ACCESS,
			profile_loading: true,
		});
		new ProfileService()
			.modifyIfaAccess(data)
			.then(() => {
				dispatch({
					type: IFA_ACCESS_SUCCESS,
					profile_loading: false,
				});
				window.location.reload();
			})
			.catch(error => {
				dispatch({
					type: IFA_ACCESS_ERROR,
					profile_loading: false,
				});
				console.error(error);
			});
	}
};

/**
 * @param data
 * @returns {function(...[*]=)}
 */
export const updateMyStatePension = (data = false) => dispatch => {
	if (data) {
		dispatch({
			type: UPDATE_MY_STATE_PENSION,
			profile_loading: true,
			profile_data: false,
			code_confirmed: false,
			state_pension_loading: true,
			showChangeInputBox: false,
		});
		new ProfileService()
			.updateMyStatePension(data)
			.then(response => {
				dispatch({
					type: FETCH_PROFILE_DATA,
					profile_data: {
						...response?.data?.data
					},
					state_pension_loading: false,
					profile_loading: false,
					code_confirmed: false,
					showChangeInputBox: false,
				});
				if (response?.data?.data.state_pension) {
					response.data.data.state_pension = {
						...data.profiles
					};
					dispatch({
						type: FETCH_PROFILE_DATA,
						profile_loading: false,
						profile_data: {
							...response?.data?.data
						},
						code_confirmed: false,
						showChangeInputBox: false,
						state_pension_loading: false
					});
				}
			})
			.catch(error => {
				console.error(error);
				if (error.response) {
					if (error.response.status === 401) {
						localStorage.clear();
						window.location = "/login";
					}
				}
				dispatch({
					type: UPDATE_MY_STATE_PENSION,
					profile_loading: false,
					profile_data: false,
					code_confirmed: false,
					showChangeInputBox: false,
					state_pension_loading: false,
				});

				dispatch(me(true));
			});
	}
};

/**
 * @returns {function(...[*]=)}
 */
export const myEmployers = (scrollUp: boolean = false) => dispatch => {
	new ProfileService()
		.myEmployers()
		.then(response => {
			dispatch({
				type: FETCH_EMPLOYERS_DATA,
				employers: response?.data?.data,
				code_confirmed: false,
				showChangeInputBox: false,
			});

			if (scrollUp) {
				forceScrollUp();
			}
		})
		.catch(error => {
			console.error(error);
			if (error.response) {
				if (error.response.status === 401) {
					localStorage.clear();
					window.location = "/login";
				}
			}
		});
};

/**
 * @param employerId
 * @param scrollUp
 * @returns {(function(*): void)|*}
 */
export const myProjections = (employerId: mixed = null, scrollUp: boolean = false) => dispatch => {
	dispatch({
		type: MY_PROJECTIONS,
		slider_loading: true
	});
	new ProfileService()
		.myProjections(employerId)
		.then(response => {
			dispatch({
				type: MY_PROJECTIONS,
				my_projections: response?.data?.data,
				code_confirmed: false,
				showChangeInputBox: false,
				slider_loading: false
			});
			if (scrollUp) {
				forceScrollUp();
			}
		})
		.catch(error => {
			console.error(error);
			if (error.response) {
				if (error.response.status === 401) {
					localStorage.clear();
					window.location = "/login";
				}
			}
		});
};

/**
 * @param data
 * @returns {function(...[*]=)}
 */
export const updateProfilePicture = (data: any[] = false) => dispatch => {
	if (data) {
		dispatch({
			type: FETCH_PROFILE_DATA,
			profile_loading: true,
		});
		const formData = new FormData();
		formData.append("picture", data?.picture);

		new ProfileService()
			.updateProfilePicture(formData)
			.then(response => {
				if (response?.data?.data) {
					dispatch(me());
				}
				dispatch({
					type: FETCH_PROFILE_DATA,
					profile_loading: false,
				});
			})
			.catch(error => {
				console.error(error);
				dispatch({
					type: FETCH_PROFILE_DATA,
					profile_loading: false,
				});
			});

	}
};

/**
 * @param id
 * @param data
 * @param refresh
 * @param redirectUrl
 * @returns {(function(*): void)|*}
 */
export const update = (id: any = false, data: any[] = false, refresh = true, redirectUrl = "/main-page") => dispatch => {
	if (id && data) {
		dispatch({
			type: UPDATE_PROFILE,
			canProceed: false,
			errorMessage: "",
			code_confirmed: false,
			showChangeInputBox: false,
			activation_code_loading: true,
			canProceedEmail: false,
			loading: true,
			profile_loading: true,
		});
		localStorage.removeItem("Forename");
		localStorage.removeItem("Surname");
		localStorage.removeItem("Gender");
		localStorage.removeItem("Birthdate");
		localStorage.removeItem("Mobile");
		new ProfileService()
			.update(id, data)
			.then(response => {
				if (refresh) {
					if (response?.data?.data && refresh) {
						window.location.href = redirectUrl;
					}
				} else {
					dispatch({
						type: UPDATE_PROFILE,
						canProceed: true,
						errorMessage: "",
						code_confirmed: false,
						showChangeInputBox: false,
						requestMailChange: false,
						activation_code_loading: false,
						canProceedEmail: true,
						loading: false,
						profile_loading: false,
					});
				}
				dispatch({
					type: UPDATE_PROFILE,
					loading: false,
					profile_loading: false,
				});
				dispatch(me());
			})
			.catch(error => {
				// Error 😨
				let message = "Please verify your data.";
				if (error.response) {
					/*
					 * The request was made and the server responded with a
					 * status code that falls out of the range of 2xx
					 */

					message = error.response.data.message;
				}

				dispatch({
					type: UPDATE_PROFILE,
					canProceed: false,
					errorMessage: message,
					code_confirmed: false,
					showChangeInputBox: false,
					activation_code_loading: false,
					loading: false,
					profile_loading: false,
				});
				console.error(error);
			});
	}
};

/**
 * @param data
 * @returns {function(...[*]=)}
 */
export const requestChangeEmail = (data: any[] = false) => dispatch => {
	if (data) {
		dispatch({
			type: REQUEST_CHANGE_EMAIL,
			request_change_email: false,
			request_change_password: false,
			operation: "",
			message_error: "",
		});
		new ProfileService()
			.requestChangeEmail(data)
			.then(response => {
				if (response?.data?.data) {
					dispatch({
						type: REQUEST_CHANGE_EMAIL,
						request_change_email: response?.data?.data,
						request_change_password: false,
						operation: data.profiles.operation,
					});
				} else {
					dispatch({
						type: REQUEST_CHANGE_EMAIL,
						request_change_email: false,
						request_change_password: false,
						operation: "",
					});
				}
			})
			.catch(error => {
				dispatch({
					type: REQUEST_CHANGE_EMAIL,
					request_change_email: false,
					request_change_password: false,
					operation: "",
				});
				console.error(error);
				if (error.response) {
					if (error.response.status === 401) {
						localStorage.clear();
						window.location = "/login";
					}
				}
			});
	}
};

/**
 * @returns {function(...[*]=)}
 * @param id
 * @param data
 */
export const confirmChangeProfileCode = (
	data: any[] = {
		profiles: {
			operation: "",
		}
	},
	id: number = 0,
) => dispatch => {
	if (data) {
		dispatch({
			type: CONFIRM_REQUEST_CHANGE_PROFILE_CODE,
			request_change_email_code_confirmed: false,
			request_change_password_code_confirmed: false,
			code_confirmed: false,
			profile_loading: true,
			message_error: ""
		});

		new ProfileService()
			.confirmChangeProfileCode(data)
			.then((response) => {
				if (response?.data?.data) {
					switch (data.profiles.operation.toLowerCase()) {
						case "e-mail":
							dispatch({
								type: CONFIRM_REQUEST_CHANGE_PROFILE_CODE,
								request_change_email_code_confirmed: response?.data?.data,
								request_change_password_code_confirmed: false,
								operation: data.profiles.operation,
								code_confirmed: response?.data?.data,
								profile_loading: false,
								message_error: "",
							});
							break;
						case "password":
							dispatch({
								type: CONFIRM_REQUEST_CHANGE_PROFILE_CODE,
								request_change_email_code_confirmed: false,
								request_change_password_code_confirmed: response?.data?.data,
								operation: data?.profiles?.operation,
								code_confirmed: response?.data?.data,
								profile_loading: false,
								message_error: "",
							});
							break;
						case "personal information":
							const individual = {
								profiles: {
									forename: localStorage.getItem("Forename"),
									surname: localStorage.getItem("Surname"),
									gender: localStorage.getItem("Gender"),
									birthdate: localStorage.getItem("Birthdate"),
									mobile: localStorage.getItem("Mobile"),
								}
							}

							new ProfileService()
								.update(id, individual)
								.then(() => {
									window.location.href = "/my-account/personal-information";
								})
								.catch(error => {
									let message = "Please verify your data.";
									if (error.response) {
										message = error.response.data.message;
									}
									dispatch({
										type: EMAIL_CHANGED,
										canProceed: false,
										errorMessage: message,
										code_confirmed: false,
										showChangeInputBox: false,
									});
									console.error(error);
								});
							break;
						default:
							dispatch({
								type: CONFIRM_REQUEST_CHANGE_PROFILE_CODE,
								request_change_email_code_confirmed: false,
								request_change_password_code_confirmed: response?.data?.data,
								operation: data?.profiles?.operation,
								confirm_code_error: true,
								code_confirmed: false,
								profile_loading: false,
								message_error: "Your code doesn't match! Please verify your e-mail."
							});
							break;
					}
				} else {
					dispatch({
						type: CONFIRM_REQUEST_CHANGE_PROFILE_CODE,
						request_change_email_code_confirmed: false,
						request_change_password_code_confirmed: response?.data?.data,
						operation: data?.profiles?.operation,
						confirm_code_error: true,
						code_confirmed: false,
						profile_loading: false,
						message_error: "Your code doesn't match! Please verify your e-mail."
					});
				}
			})
			.catch(error => {
				dispatch({
					type: CONFIRM_REQUEST_CHANGE_PROFILE_CODE,
					request_change_email: false,
					request_change_password: false,
					operation: "",
					confirm_code_error: true,
					code_confirmed: false,
				});
				console.error(error);
				if (error.response) {
					if (error.response.status === 401) {
						localStorage.clear();
						window.location = "/login";
					}
				}
			});
	}
};

/**
 *
 * @param code
 * @returns {function(...[*]=)}
 */
export const checkProfileChangeCode = (code: string = "") => dispatch => {
	if (code !== "") {
		new ProfileService()
			.checkProfileChangeCode(code)
			.then(response => {
				if (response?.data?.data) {
					dispatch({
						type: PROFILE_CHANGE_CODE_CHECKED,
						...response?.data?.data,
					});
				} else {
					window.location.href = "/my-account";
				}
			})
			.catch(error => console.error(error));
	}
};

/**
 * @param data
 */
export const updateMyEmail = (data: any[] = false) => dispatch => {
	dispatch({
		type: PROFILE_CHANGE_CODE_CHECKED,
		message_error: ""
	});
	if (data) {
		new ProfileService()
			.updateMyEmail(data)
			.then(response => {
				dispatch({
					type: PROFILE_CHANGE_CODE_CHECKED,
					message_error: ""
				});
				if (response?.data?.data?.success) {
					localStorage.clear();
					window.location.href = "/login";
				} else {
					dispatch({
						type: PROFILE_CHANGE_CODE_CHECKED,
						message_error: response?.data?.data?.message
					});
				}
			})
			.catch(error => console.error(error));
	}
};

/**
 * @param data
 * @returns {function(...[*]=)}
 */
export const updateMyPassword = (data: any[] = false) => dispatch => {
	if (data) {
		dispatch({
			type: PROFILE_CHANGE_CODE_CHECKED,
			message_error: ""
		});
		new ProfileService()
			.updateMyPassword(data)
			.then(response => {
				if (response?.data?.data) {
					window.location.href = "/logout";
				} else {
					dispatch({
						type: PROFILE_CHANGE_CODE_CHECKED,
						message_error: "Code is invalid"
					});
				}
			})
			.catch(error => {
				dispatch({
					type: PROFILE_CHANGE_CODE_CHECKED,
					message_error: ""
				});
				console.error(error);
			});
	}
};

/**
 *
 * @returns {function(...[*]=)}
 */
export const closeAccount = () => dispatch => {
	new ProfileService()
		.closeAccount()
		.then(response => {
			if (response?.data?.data) {
				window.location.href = "/account-deleted";
				localStorage.clear();
			}
		})
		.catch(error => {

			if (error.response) {
				if (error.response.status === 401) {
					localStorage.clear();
					window.location = "/login";
				}
			}
			console.error(error);
		});
};

/**
 * @param data
 * @returns {function(...[*]=)}
 */
export const updateMyAddress = (data: any[] = false) => dispatch => {
	if (data) {

		dispatch({
			type: FETCH_PROFILE_DATA,
			profile_loading: true,
			login_text: "",
		});
		new ProfileService()
			.updateMyAddress(data)
			.then(response => {
				if (response?.data?.data) {
					window.location.reload();
				}

				dispatch({
					type: FETCH_PROFILE_DATA,
					profile_loading: false,
					login_text: "",
				});
			})
			.catch(error => {
				if (error.response.data.data === "{\"address1\":[\"The address1 field is required.\"]}") {
					dispatch(SnackBar.error("The address line 1 field is required!"));
				} else if (error.response.data.data === "{\"address2\":[\"The address2 field is required.\"]}") {
					dispatch(SnackBar.error("The Town / City field is required!"));
				} else if (error.response.data.data === "{\"address4\":[\"The address4 field is required.\"]}") {
					dispatch(SnackBar.error("The House / Flat Number field is required!"));
				} else if (error.response.data.data === "{\"postal_code\":[\"The postal_code field is required.\"]}") {
					dispatch(SnackBar.error("The postal_code field is required!"));
				} else if (error.response.data.data === "{\"country\":[\"The country field is required.\"]}") {
					dispatch(SnackBar.error("The country field is required!"));
				}
			});

		dispatch({
			type: FETCH_PROFILE_DATA,
			profile_loading: false,
			login_text: "",
		});
	}
};

/**
 * @returns {function(...[*]=)}
 */
export const logOut = () => dispatch => {
	new ProfileService()
		.logOut()
		.then((response) => {
			sessionStorage.clear();
			localStorage.clear();
			window.location.href = "/login";
		})
		.catch(error => {
			console.error(error);
			if (error.response) {
				if (error.response.status === 401) {
					localStorage.clear();
					window.location = "/login";
				}
			}
		});
};

/**
 * @param data
 * @returns {function(...[*]=)}
 */
export const passwordChangeTokenRequest = (data: any[] = false) => dispatch => {
	if (data) {
		new ProfileService()
			.updatePassword(data ?? false)
			.catch(error => console.error(error));
	}
};

/**
 * @param token
 * @returns {(function(*): void)|*}
 */
export const checkForgotPasswordToken = (token: string = "") => dispatch => {
	if (token) {
		new ProfileService()
			.checkForgotPasswordToken(token ?? "")
			.then(response => response?.data?.data ? false : window.location.href = "/login")
			.catch(error => console.error(error));
	}
};

/**
 * @param data
 * @returns {function(...[*]=)}
 */
export const requestNewPassword = (data: any[] = false) => dispatch => {
	if (data) {
		dispatch({
			type: RETRIEVE_NEW_PASSWORD,
			profile_loading: false,
			login_text: "",
		});

		new ProfileService()
			.requestNewPassword(data ?? false)
			.then(response => {
				if (response?.data?.data) {
					dispatch({
						type: RETRIEVE_NEW_PASSWORD,
						profile_loading: false,
						login_text: "",
					});
					localStorage.setItem("passwordRetrieveEmail", data.forgot_password.email);
					window.location = "/forgot-password/request-sent";
				} else {
					dispatch({
						type: RETRIEVE_NEW_PASSWORD,
						profile_loading: false,
						login_text: "E-mail not found. Try again!",
					});
					localStorage.setItem("passwordRetrieveEmail", "");
				}
			})
			.catch(error => {
				dispatch({
					type: RETRIEVE_NEW_PASSWORD,
					profile_loading: false,
					login_text: "E-mail not found. Try again!",
				});
				console.error(error);
				localStorage.setItem("passwordRetrieveEmail", "");
			})
	}
};

/**
 * @param data
 * @returns {function(...[*]=)}
 */
export const retrieveOptionsValue = (data: any[] = false) => dispatch => {
	if (data) {
		new ProfileService()
			.retrieveOptionsValues(data ?? false)
			.then(response => dispatch({
				type: RETRIEVE_OPTIONS_VALUES,
				options_value: response?.data?.data,
			}))
			.catch(error => console.error(error));
	}
};

/**
 * @param data
 * @returns {(function(*): void)|*}
 */
export const checkDuplicateNin = (data: any[] = false) => dispatch => {
	dispatch({
		type: CHECK_NIN_DUPLICATE,
		exist_nin: false,
		valid_nin: false,
	});
	if (data) {
		new ProfileService()
			.checkDuplicateNin(data ?? false)
			.then(response => dispatch({
				type: CHECK_NIN_DUPLICATE,
				exist_nin: response?.data?.data,
			}))
			.catch(error => console.error(error));
	}
};

/**
 * @param data
 * @returns {(function(*))|*}
 */
export const isValidNationalInsuranceNumber = (data: {} = {
	national_insurance_number: "",
}) => dispatch => {
	dispatch({
		type: CHECK_NIN_DUPLICATE,
		valid_nin: false,
	});
	if (data) {
		new ProfileService()
			.isValidNationalInsuranceNumber(data)
			.then(response => {
				if (response?.data?.data) {
					dispatch({
						type: CHECK_NIN_DUPLICATE,
						valid_nin: response?.data?.data === 0
					});
				}
			})
			.catch(error => console.error(error));
	}
};



/**
 * @param data
 * @returns {(function(*): void)|*}
 */
export const changePersonalData = (data: any[] = false) => dispatch => {
	if (data) {
		dispatch({
			type: REQUEST_CHANGE_EMAIL,
			loading: true,
			showChangeInputBox: false,
			errorMessage: "",
			code_confirmed: false,
		});

		new ProfileService()
			.changePersonalData(data ?? false)
			.then(response => {
				if (response?.data?.data) {
					dispatch({
						type: REQUEST_CHANGE_EMAIL,
						loading: false,
						showChangeInputBox: true,
						errorMessage: "",
						code_confirmed: false,
					});
				} else {
					dispatch({
						type: REQUEST_CHANGE_EMAIL,
						loading: false,
						showChangeInputBox: true,
						errorMessage: "Something went wrong, please contact Collegia support.",
						code_confirmed: false,
					});
				}
			})
			.catch(error => {
				dispatch({
					type: REQUEST_CHANGE_EMAIL,
					loading: false,
					showChangeInputBox: true,
					errorMessage: "Something went wrong, please contact Collegia support.",
				});
				console.error(error);
			});
	}
};

/**
 * @returns {(function(*): void)|*}
 */
export const setConfirmCodeToFalse = () => dispatch => {
	dispatch({
		type: REQUEST_CHANGE_EMAIL,
		code_confirmed: null,
	});
};

/**
 * @param data
 * @returns {(function(*): void)|*}
 */
export const uploadDdiFile = (data: any[] = false) => dispatch => {
	dispatch({
		type: FETCH_PROFILE_DATA,
		loading: true,
	});
	if (data) {
		const formData = new FormData();
		formData.append("pdf", data?.pdf);
		const contributionData = data?.contributionData?.contributionValue + "-" +
			data?.contributionData?.selectedDay + "-" + data?.contributionData?.source_of_fund_id;
		new ProfileService()
			.uploadDdiFile(formData, contributionData)
			.then(async response => {
				if (response?.data?.data) {
					let webHookUrl;
					let slackData;
					if (process.env.REACT_APP_ENV === "production") {
						webHookUrl = "https://hooks.slack.com/services/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";
						slackData = {
							"text": "New Ddi file has been uploaded in PROD!\n" +
								`National Insure Number: ${response?.data?.data[0]}\n` +
								`Uploaded By: ${response?.data?.data[1]}\n` +
								`Primary Email: ${response?.data?.data[2]}\n` +
								`Account Type: ${response?.data?.data[3]}\n` +
								`Contribution Value: ${response?.data?.data[4]}\n` +
								`Selected Day: ${response?.data?.data[5]}\n` +
								`Source Fund Name: ${response?.data?.data[6]}\n` +
								`Ddi Link: ${response?.data?.data[7]}\n`
						}
					} else {
						webHookUrl = "https://hooks.slack.com/services/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";
						slackData = {
							"text": "New Ddi file has been uploaded in DEV!\n" +
								`National Insure Number: ${response?.data?.data[0]}\n` +
								`Uploaded By: ${response?.data?.data[1]}\n` +
								`Primary Email: ${response?.data?.data[2]}\n` +
								`Account Type: ${response?.data?.data[3]}\n` +
								`Contribution Value: ${response?.data?.data[4]}\n` +
								`Selected Day: ${response?.data?.data[5]}\n` +
								`Source Fund Name: ${response?.data?.data[6]}\n` +
								`Ddi Link: ${response?.data?.data[7]}\n`
						}
					}

					let res = await axios.post(webHookUrl, JSON.stringify(slackData), {
						withCredentials: false,
						transformRequest: [(slackData, headers) => {
							delete headers.post["Content-Type"];
							return slackData;
						}]
					});
					if (res.status === 200) {
						dispatch(SnackBar.success("Your file has been upload!"));
					}
					dispatch(me());
					dispatch({
						type: FETCH_PROFILE_DATA,
						loading: false,
					});
				}
			})
			.catch((response) => dispatch(SnackBar.error(response?.data?.data[0])));
	}
};

/**
 * @param data
 * @returns {(function(*): void)|*}
 */
export const getActivationToken = (data: any[] = false) => dispatch => {
	if (data) {
		dispatch({
			type: GET_ACTIVATION_TOKEN_STARTED,
			token_loading: true
		});
		new ProfileService()
			.getActivationToken(data)
			.then(response => {
				dispatch({
					type: GET_ACTIVATION_TOKEN_SUCCESS,
					token_loading: false,
					token: response?.data?.data?.token ?? null,
					first_login: response?.data?.data?.first_login ?? false,
					token_active: response?.data?.data?.token_active ?? false,
					individual_id: response?.data?.data?.individual_id ?? null
				});
			})
			.catch(error => {
				console.error(error);
				dispatch({
					type: GET_ACTIVATION_TOKEN_ERROR,
					token_loading: false
				});
			});
	}
};

export const createFirstPassword = (data: any[] = false) => dispatch => {
	if (data) {
		dispatch({
			type: CREATE_FIRST_PASSWORD_STARTED,
			token_loading: true
		});
		new ProfileService()
			.createFirstPassword(data)
			.then(response => {
				dispatch({
					type: CREATE_FIRST_PASSWORD_SUCCESS,
					token_loading: false
				});

				window.location.href = '/login';
			})
			.catch(error => {
				console.error(error);
				dispatch({
					type: CREATE_FIRST_PASSWORD_ERROR,
					token_loading: false
				});
			})
	}
};

/**
 * @returns {(function(*): void)|*}
 */
export const clearActivationToken = () => dispatch => {
	dispatch({
		type: GET_ACTIVATION_TOKEN_CLEAR,
		token: false
	});
};

export const generateHMRCAccessTokenState = (data) => dispatch => {
	dispatch({
		type: GENERATE_HMRC_ACCESS_TOKEN_STATE,
		token_loading: true
	});
	new ProfileService()
		.generateHMRCAccessTokenState(data)
		.then(response => {
			dispatch({
				type: GENERATE_HMRC_ACCESS_TOKEN_STATE,
				token_loading: false,
			});

			window.location.href = response?.data?.data.grant_url;
		})
		.catch(error => {
			console.error(error);
			dispatch({
				type: GENERATE_HMRC_ACCESS_TOKEN_STATE,
				token_loading: false
			});
		});
};

export const processPastEmployments = (data) => dispatch => {
	dispatch({
		type: PROCESS_PAST_EMPLOYMENTS,
		loading: true
	});

	new ProfileService()
		.processPastEmployments(data)
		.then(() => dispatch({
			type: PROCESS_PAST_EMPLOYMENTS,
			loading: false
		}))
		.catch(error => {
			dispatch({
				type: PROCESS_PAST_EMPLOYMENTS,
				loading: false
			});
			console.error(error);
		});
};

export const processResidencyStatus = (data) => dispatch => {
	dispatch({
		type: PROCESS_RESIDENCY_STATUS,
		loading: true
	});

	new ProfileService()
		.processResidencyStatus(data)
		.then(() => dispatch({
			type: PROCESS_RESIDENCY_STATUS,
			loading: false
		}))
		.catch(error => {
			dispatch({
				type: PROCESS_RESIDENCY_STATUS,
				loading: false
			});
			console.error(error);
		});
};


/**
 * @param data
 * @returns {function(...[*]=)}
 */
export const returnMonthlyProjections = (data = false) => dispatch => {
	if (data) {
		dispatch({
			type: RETURN_MONTHLY_PROJECTION,
			my_projections: [],
			profile_data: [],
			monthly_projections_loading: true,
			monthly_projections: []
		});
		new ProfileService()
			.returnMonthlyProjections(data)
			.then((response) => {
				dispatch({
					type: RETURN_MONTHLY_PROJECTION,
					my_projections: response?.data?.data?.projection,
					profile_data: response?.data?.data,
					monthly_projections_loading: false,
					monthly_projections: response?.data?.data.monthly_projections
				});
				if (data?.profiles?.simulation === false) {
					dispatch({
						type: RETURN_MONTHLY_PROJECTION,
						my_projections: response?.data?.data.projection,
						profile_data: [],
						monthly_projections: response?.data?.data.monthly_projections
					});
				}
			})
			.catch(error => {
				if (error?.response) {
					if (error?.response?.status === 401) {
						localStorage.clear();
						window.location = "/login";
					}
				}
				dispatch({
					type: RETURN_MONTHLY_PROJECTION,
					my_projections: [],
					profile_data: [],
					monthly_projections_loading: false,
					monthly_projections: []
				});
				console.error(error);
			});
	}
};

export const mainPage = (scrollUp: boolean = false) => dispatch => {
	if (retrieveCollegiaAccessToken()) {
		dispatch({
			type: FETCH_PROFILE_DATA,
			profile_loading: true,
			loading: true,
			showChangeInputBox: false,
		});
		dispatch({
			type: EMAIL_CHANGED,
			canProceed: false,
			errorMessage: "",
			showChangeInputBox: false,
		});

		new ProfileService()
			.mainPage()
			.then(response => {
				dispatch({
					type: FETCH_PROFILE_DATA,
					profile_loading: false,
					loading: false,
					showChangeInputBox: false,
					profile_data: {
						...response?.data?.data
					},
				});

				localStorage.setItem("profile_data", JSON.stringify({
					...response?.data?.data
				}));
				if (window.location.pathname !== "/confirm-monthly-contribution") {
					if (response?.data?.data?.prepare_emandate?.active) {
						window.location = "/confirm-monthly-contribution";
					}
					else if (response?.data?.data?.individual_monthly?.active === true &&
						response?.data?.data?.payment_schedule === null) {
						window.location = "/confirm-monthly-contribution";
					}

				}

				if (scrollUp) {
					forceScrollUp();
				}
			})
			.catch(error => {
				if (error.response) {
					if (error.response.status === 401) {
						localStorage.clear();
						window.location = "/login";
					}
				}

				dispatch({
					type: FETCH_PROFILE_DATA,
					profile_loading: false,
					loading: false,
					showChangeInputBox: false,
					profile_data: JSON.parse(localStorage.getItem("profile_data")),
				});

				console.error(error);
			});
	} else {
		localStorage.clear();
		window.location = "/login";
		dispatch({
			type: FETCH_PROFILE_DATA,
			profile_loading: false,
			loading: false,
			showChangeInputBox: false,
		});
	}
};

export const returnUser = () => dispatch => {
	dispatch({
		type: FETCH_PROFILE_DATA,
		profile_loading: true,
		loading: true,
		profile_data: {},
	});
	Request.get("/profile/return-user")
		.then(response => {
			console.log("response?");
			console.log(response);
			dispatch({
				type: FETCH_PROFILE_DATA,
				profile_loading: false,
				loading: false,
				profile_data: {
					...response?.data?.data
				},
			});
		})
		.catch(error => {
			dispatch({
				type: FETCH_PROFILE_DATA,
				profile_loading: false,
				loading: false,
				profile_data: {},
			});
			if (error.response) {
				if (error.response.status === 401) {
					localStorage.clear();
					window.location = "/login";
				}
			}

			console.error(error);
		});
};