import {Dialog, DialogContent, Grid} from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import {createAutoCorrectedDatePipe} from "../../utils/Helpers";
import React from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import RenderTypesButtons from "./RenderTypesButtons";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import MaskedInput from "react-text-mask/dist/reactTextMask";


/**
 * @private
 * @param beneficiaryTypeId
 * @param beneficiaryRelationshipTypeId
 * @param name
 * @param postalCode
 * @param town
 * @param country
 * @param streetName
 * @param doorNumber
 * @param proportion
 */
function _renderDisabledButton(
	beneficiaryTypeId: 1,
	beneficiaryRelationshipTypeId: 0,
	name: "",
	postalCode: "",
	town: "",
	country: "",
	streetName: "",
	doorNumber: "",
	proportion: "",
) {
	if (beneficiaryTypeId === 2) {
		if (
			!name ||
			!postalCode ||
			!town ||
			!streetName ||
			!beneficiaryTypeId ||
			!doorNumber ||
			!proportion
		) {
			return "button-2-modal";
		}

		return "button-1-modal";
	}
	if (
		!name ||
		!postalCode ||
		!town ||
		!streetName ||
		!beneficiaryTypeId ||
		!beneficiaryRelationshipTypeId ||
		!doorNumber ||
		!proportion
	) {
		return "button-2-modal";
	}

	return "button-1-modal";
}

/**
 * @param capitalizeFirstLetter
 * @param beneficiaries
 * @param beneficiary_relationship_types
 * @param beneficiary_types
 * @param handleClose
 * @param open
 * @param beneficiaryTypeId
 * @param beneficiaryRelationshipTypeId
 * @param name
 * @param postalCode
 * @param town
 * @param birthdate
 * @param country
 * @param streetName
 * @param doorNumber
 * @param proportion
 * @param postCodeErrorText
 * @param foundAddress
 * @param id
 * @param setBeneficiaryTypeId
 * @param setBeneficiaryRelationshipTypeId
 * @param setName
 * @param setPostalCode
 * @param setBirthdate
 * @param setStreetName
 * @param setDoorNumber
 * @param setProportion
 * @param setFormModalOpen
 * @param handleProportionInput
 * @param create
 * @param update
 * @param addBeneficiary
 * @param t
 * @returns {JSX.Element}
 * @constructor
 */
const BeneficiariesFormModal = (
	{
		capitalizeFirstLetter = word => word,
		beneficiaries = [],
		beneficiary_relationship_types = [],
		beneficiary_types = [],
		handleClose = () => {
		},
		open = false,
		beneficiaryTypeId = 1,
		beneficiaryRelationshipTypeId = 0,
		name = "",
		postalCode = "",
		town = "",
		birthdate = "",
		country = "",
		streetName = "",
		doorNumber = "",
		proportion = "",
		postCodeErrorText = "",
		foundAddress = false,
		id = null,
		setBeneficiaryTypeId = () => {
		},
		setBeneficiaryRelationshipTypeId = () => {
		},
		setName = () => {
		},
		setPostalCode = () => {
		},
		setBirthdate = () => {
		},
		setStreetName = () => {
		},
		setDoorNumber = () => {
		},
		setProportion = () => {
		},
		setFormModalOpen = () => {
		},
		handleProportionInput = () => {
		},
		create = () => {
		},
		update = () => {
		},
		addBeneficiary = () => {
		},
		t = (key) => key,
	}
): JSX.Element => (
	<Dialog
		id={'new-modal-beneficiary'}
		open={open}
		onClose={() => false}
		aria-labelledby={"Beneficiaries-form-modal"}
		aria-describedby={"Beneficiaries-form-modal"}
	>
		<Grid
			xs={12}
			sm={12}
			md={12}
			lg={12}
			direction={"row"}
			container
			item
		>
			<Grid
				item
				lg={12}
				xl={12}
				sm={12}
				md={12}
				textAlign={"end"}
				marginTop={2}
				marginBottom={5}
				marginRight={2}
				onClick={() => handleClose()}
			>
				<CloseIcon/>
			</Grid>
			<Grid
				lg={12}
				xl={12}
				sm={12}
				md={12}
				item
				justifyContent={"center"}
				container
			>
				<Typography
					align={"center"}
					className={"modal-title"}
				>
					{t("individual.my_account.beneficiaries.new_beneficiary_title")}
				</Typography>
				<Typography
					align={"center"}
					className={"modal-subtitle"}
				>
					Add the information for the beneficiaries you want to add into your account.
				</Typography>
			</Grid>
		</Grid>
		<DialogContent>
			<Grid
				xs={12}
				sm={12}
				lg={12}
				md={12}
				item
				container
				justifyContent={"center"}
			>
				<RenderTypesButtons
					beneficiary_types={beneficiary_types ?? []}
					beneficiary_type_id={beneficiaryTypeId ?? 1}
					setBeneficiaryTypeId={setBeneficiaryTypeId}
					t={t}
				/>
				<Grid
					xs={11}
					sm={11}
					lg={11}
					md={11}
					item
					container
					justifyContent={"space-between"}
				>
					<Grid
						xs={12}
						sm={12}
						lg={12}
						md={12}
						item
					>
						<OutlinedInput
							variant={"outlined"}
							className={"textfield1"}
							placeholder={
								beneficiaryTypeId === 1 ?
									t("individual.my_account.beneficiaries.full_name_input") :
									t("individual.my_account.beneficiaries.institution_name")
							}
							onChange={event => setName(event.target.value)}
							value={name}
							fullWidth
						/>
					</Grid>
					<Grid
						xs={5.9}
						sm={5.9}
						lg={5.9}
						md={5.9}
						item
					>
						{
							beneficiaryTypeId === 1 &&
							<MaskedInput
								variant={"outlined"}
								className={"birthdate"}
								placeholder={t("individual.birthdate")}
								value={birthdate}
								mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
								onChange={event => setBirthdate(event.target.value)}
								pipe={createAutoCorrectedDatePipe("dd mm yyyy", {
									minYear: 1900, maxYear: new Date().getFullYear()
								})}
							/>
						}
					</Grid>
					<Grid
						xs={5.9}
						sm={5.9}
						lg={5.9}
						md={5.9}
						item
					>
						<OutlinedInput
							variant={"outlined"}
							onChange={event => handleProportionInput(event, beneficiaries, id, setProportion)}
							value={proportion}
							placeholder={t("individual.my_account.beneficiaries.proportion")}
							className={"textfield1"}
							fullWidth
						/>
					</Grid>
					<Grid
						xs={5.9}
						sm={5.9}
						lg={5.9}
						md={5.9}
						item
						alignSelf={"end"}
					>
						{
							beneficiaryTypeId === 1 &&
							<TextField
								onChange={event => setBeneficiaryRelationshipTypeId(event.target.value)}
								value={beneficiaryRelationshipTypeId}
								className={"relation"}
								label={t("individual.my_account.beneficiaries.relationship_to_you")}
								fullWidth
								select
							>
								<MenuItem key={0} value={0} disabled>
									{t("individual.select_an_option")}
								</MenuItem>
								{
									beneficiary_relationship_types?.map((option = {
										id: null,
										name: "",
									}) => (
										<MenuItem key={option.id} value={option.id}>
											{option.name}
										</MenuItem>
									))
								}
							</TextField>
						}
					</Grid>
					<Grid
						xs={5.9}
						sm={5.9}
						lg={5.9}
						md={5.9}
						item
					>
						<OutlinedInput
							variant={"outlined"}
							onChange={event => setPostalCode(event.target.value)}
							value={postalCode}
							placeholder={t("individual.my_account.beneficiaries.postcode")}
							className={"textfield1"}
							fullWidth
						/>
					</Grid>
					{
						foundAddress && !postCodeErrorText && (
							<>
								<Grid
									xs={12}
									sm={12}
									lg={12}
									md={12}
									item
								>
									<OutlinedInput
										variant={"outlined"}
										value={country}
										placeholder={capitalizeFirstLetter(t("individual.my_account.beneficiaries.country"))}
										className={"textfield1"}
										disabled
										fullWidth
									/>
								</Grid>
								<Grid
									xs={5.9}
									sm={5.9}
									lg={5.9}
									md={5.9}
									item
								>
									<OutlinedInput
										variant={"outlined"}
										onChange={event => setStreetName(event.target.value)}
										value={streetName}
										placeholder={t("individual.my_account.beneficiaries.street_avenue_input")}
										className={"textfield1"}
										fullWidth
									/>
								</Grid>
								<Grid
									xs={5.9}
									sm={5.9}
									lg={5.9}
									md={5.9}
									item
								>
									<OutlinedInput
										variant={"outlined"}
										onChange={event => setDoorNumber(event.target.value)}
										value={doorNumber}
										placeholder={t("individual.my_account.beneficiaries.door_number_flat_number_input")}
										className={"textfield1"}
										fullWidth
									/>
								</Grid>
							</>
						)
					}
					<Grid
						xs={5.9}
						sm={5.9}
						lg={5.9}
						md={5.9}
						item
						onClick={() => {
							_renderDisabledButton(
								beneficiaryTypeId,
								beneficiaryRelationshipTypeId,
								name,
								postalCode,
								town,
								country,
								streetName,
								doorNumber,
								proportion,
							) === "button-1-modal" &&
							addBeneficiary(
								name,
								postalCode,
								proportion,
								beneficiaryTypeId,
								beneficiaryRelationshipTypeId,
								birthdate,
								town,
								country,
								streetName,
								doorNumber,
								id,
								open,
								setFormModalOpen,
								create,
								update,
							);
						}}
					>
						<Typography
							className={
								_renderDisabledButton(
									beneficiaryTypeId,
									beneficiaryRelationshipTypeId,
									name,
									postalCode,
									town,
									country,
									streetName,
									doorNumber,
									proportion,
								)
							}
							textAlign={"center"}
						>
							{t("individual.my_account.address.save_button")}
						</Typography>
					</Grid>
					<Grid
						xs={5.9}
						sm={5.9}
						lg={5.9}
						md={5.9}
						item
					>
						<Typography
							className={
								_renderDisabledButton(
									beneficiaryTypeId,
									beneficiaryRelationshipTypeId,
									name,
									postalCode,
									town,
									country,
									streetName,
									doorNumber,
									proportion,
								)
							}
							textAlign={"center"}
						>
							{t("individual.discard")}
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		</DialogContent>
	</Dialog>
);

BeneficiariesFormModal.propTypes = {
	beneficiaries: PropTypes.array.isRequired,
	beneficiary_relationship_types: PropTypes.array.isRequired,
	beneficiary_types: PropTypes.array.isRequired,
	handleClose: PropTypes.func.isRequired,
	open: PropTypes.bool.isRequired,
	beneficiaryTypeId: PropTypes.number.isRequired,
	beneficiaryRelationshipTypeId: PropTypes.number.isRequired,
	name: PropTypes.string.isRequired,
	postalCode: PropTypes.string.isRequired,
	town: PropTypes.string.isRequired,
	birthdate: PropTypes.string.isRequired,
	country: PropTypes.string.isRequired,
	streetName: PropTypes.string.isRequired,
	doorNumber: PropTypes.string.isRequired,
	proportion: PropTypes.string.isRequired,
	postCodeErrorText: PropTypes.string.isRequired,
	foundAddress: PropTypes.bool.isRequired,
	id: PropTypes.number,
	setBeneficiaryTypeId: PropTypes.func.isRequired,
	setBeneficiaryRelationshipTypeId: PropTypes.func.isRequired,
	setName: PropTypes.func.isRequired,
	setPostalCode: PropTypes.func.isRequired,
	setBirthdate: PropTypes.func.isRequired,
	setStreetName: PropTypes.func.isRequired,
	setDoorNumber: PropTypes.func.isRequired,
	setProportion: PropTypes.func.isRequired,
	setFormModalOpen: PropTypes.func.isRequired,
	handleProportionInput: PropTypes.func.isRequired,
	create: PropTypes.func.isRequired,
	update: PropTypes.func.isRequired,
	addBeneficiary: PropTypes.func.isRequired
}

export default BeneficiariesFormModal;