import React, {useEffect} from "react";

import {retrieveCollegiaAccessToken} from "../../utils/Helpers";
import Wrapper from "../../layout/Logged/Wrapper";
import Grid from '@mui/material/Grid';
import Slide from '@mui/material/Slide';
import {employerInfo} from "../../actions/Employers";
import EmployerHeaderInfo from "../../components/ConnectionDetails/EmployerHeaderInfo";
import CompanyInformationGrid from "../../components/ConnectionDetails/CompanyInformationGrid";

import {connect} from "react-redux";
import ActionsButtons from "../../components/ConnectionDetails/ActionsButtons";
import {PrimaryHeader} from "../../components/Headers/PrimaryHeader";
import Loading from "../../components/Loading";
import {me} from "../../actions/Profile";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

/**
 * @param employer_data
 * @param code
 * @param history
 * @param t
 * @returns {JSX.Element|string}
 */
const renderContent = (
	employer_data = {},
	code = "",
	history = () => {
	},
	t = key => key,
): JSX.Element | string => {

	if (employer_data) {
		if (employer_data?.active) {
			return (
				<Grid
					width={"100%"}
				>
					<EmployerHeaderInfo
						employer_data={employer_data}
						t={t}
					/>
					<CompanyInformationGrid
						code={code}
						employer_data={employer_data}
						t={t}
					/>
					<ActionsButtons
						code={code}
						history={history}
						optOutButton={true}
						t={t}
					/>
				</Grid>
			);
		}

		return (
			<Grid
				width={"100%"}
			>
				<EmployerHeaderInfo
					employer_data={employer_data}
					t={t}
				/>
				<CompanyInformationGrid
					code={code}
					employer_data={employer_data}
					t={t}
				/>
				<ActionsButtons
					code={code}
					history={history}
					optOutButton={false}
					t={t}
				/>
			</Grid>
		);
	}

	return "Loading...";
}

/**
 * @param employer_data
 * @param match
 * @param employer_loading
 * @param employerInfo
 * @param history
 * @returns {JSX.Element}
 * @constructor
 */
const ConnectionDetails = (
	{
		employer_data = {},
		match = {
			params: {
				token: ""
			}
		},
		employer_loading = false,
		employerInfo = () => {
		},
	}
): JSX.Element => {
	const code = match.params && match.params.code ?
			match.params.code
			:
			"",
		{t} = useTranslation(),
		history = useNavigate();

	if (!code) {
		window.location = "/auto-enrolment";
	}

	useEffect(() => {
		if (code) {
			employerInfo(code);
		}
	}, [employerInfo, code]);

	if (!retrieveCollegiaAccessToken()) {
		window.location = "/";
	}

	if (employer_loading) {
		return (
			<Loading absolute/>
		);
	}
	return (
		<Wrapper t={t}>
			<Slide in={true} direction={"up"}>
				<div>
					<Grid
						direction={"column"}
						alignItems={"center"}
						alignContent={"center"}
						id={"connection-details-container"}
						container
						item
					>
						<PrimaryHeader
							history={history}
							backPage={"/auto-enrolment"}
							backIcon
						>
							{t("individual.connection_details.title")}
						</PrimaryHeader>
						{
							renderContent(
								employer_data,
								code,
								history,
								t
							)
						}
					</Grid>
				</div>
			</Slide>
		</Wrapper>
	);
}


const mapStateToProps = state => state.Employers;

const mapDispatchToProps = dispatch => ({
	employerInfo: (code: string = "") => dispatch(employerInfo(code)),
	me: dispatch(me()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConnectionDetails);