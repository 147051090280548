// importing material ui components.
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Slider from '@mui/material/Slider';
import {getAge} from "../../../utils/Helpers";


/**
 * @param retirementAge
 * @returns {string}
 */
function renderClassNames(retirementAge = 67): string {

	if (retirementAge < 67) {
		return "pot-minimum";
	}

	return "pot-comfortable";
}

/**
 * @param profile_data
 * @returns {number}
 */
function setTheMinSliderValue(profile_data: any = false): number {
	if (profile_data) {
		if (profile_data.birthdate) {
			if (getAge(profile_data.birthdate) > 55) {
				return getAge(profile_data.birthdate) + 1;
			}
		}
	}

	return 55;
}

/**
 * @param profile_data
 * @param retirementAge
 * @param setRetirementAge
 * @param setSimulation
 * @returns {JSX.Element}
 * @constructor
 */
const RetirementAgeSlider = (
	{
		profile_data = [],
		retirementAge = 67,
		setRetirementAge = () => {
		},
		setSimulation = () => {
		}
	}
): JSX.Element => (
	<div
		id={"retirement-age-slider-card-container"}
	>
		<Card
			className={"card-container"}
		>
			<CardContent>
				<Slider
					className={renderClassNames(retirementAge)}
					value={retirementAge}
					min={setTheMinSliderValue(profile_data)}
					max={75}
					onChange={(event, newValue) => {
						setRetirementAge(newValue);
						setSimulation(true);
					}}
				/>
			</CardContent>
		</Card>
	</div>
);

export default RetirementAgeSlider;