import React, {useEffect, useState} from 'react';

import Grid from '@mui/material/Grid';
import FirstConfirmation from "../components/Home/OptOutPage/FirstConfirmation";
import SecondConfirmation from "../components/Home/OptOutPage/SecondConfirmation";
import {retrieveByToken} from "../actions/ActivationToken";
import Header from "../components/ActivationPage/Header";
import Footer from "../components/ActivationPage/Footer";
import Loading from "../components/Loading";
import Wrapper from "../layout/Wrapper";

// redux actions.
import {optOut} from "../actions/OptOut";
import {connect} from "react-redux";

/**
 * @param token
 * @param currentStep
 * @param individual
 * @param setCurrentStep
 * @param history
 * @returns {null}
 */
const renderContent = (
	token: string = "",
	currentStep: number = 0,
	individual: any = [],
	setCurrentStep: Function = () => {
	},
	history: Function = () => {
	}
) => {

	switch (currentStep) {
		case 0:
			return (
				<FirstConfirmation
					proceed={() => setCurrentStep(currentStep + 1)}
					history={history}
					individual={individual}
					token={token}
				/>
			)

		case 1:
			return (
				<SecondConfirmation
					proceed={() => setCurrentStep(currentStep + 1)}
					individual={individual}
					history={history}
					token={token}
				/>
			)
		default:
			return "";
	}

};

/**
 * @param token_data
 * @param retrieveByToken
 * @param history
 * @returns {JSX.Element}
 * @constructor
 */
const OptOut = ({
	token_data = {
		token: "",
	} ?? false,
	retrieveByToken = () => {
	},
	history = () => {
	}
}): JSX.Element => {

	const [currentStep, setCurrentStep] = useState();

	useEffect(() => {
		retrieveByToken(token_data.token);
	}, [retrieveByToken, token_data.token]);

	if (!token_data?.individual) {
		return (
			<Loading absolute/>
		);
	}
	return (
		<Wrapper>
			<Header
				token={token_data.token}
				history={history}
			/>
			<Grid
				id={"optOutModalContentContainer"}
				alignItems={"center"}
				alignContent={"center"}
				direction={"column"}
				container
				item
			>
				{
					renderContent(
						token_data.token,
						currentStep,
						token_data.individual,
						setCurrentStep,
						history
					)
				}
				<Footer/>
			</Grid>
		</Wrapper>
	);
}

/**
 * @param dispatch
 * @returns {{optOut: (function(*=): *), retrieveByToken: (function(*=, *=): *)}}
 */
const mapDispatchToProps = dispatch => ({
	optOut: (data = false) => dispatch(optOut(data)),
	retrieveByToken: (token = false, modalLoading = true) => dispatch(retrieveByToken(token, modalLoading)),
});

/**
 * @param state
 * @returns {*}
 */
const mapStateToProps = state => {
	const {
		ActivationToken
	} = state;

	return {
		...ActivationToken
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(OptOut);