import NotificationProvider from "../components/NotificationProvider";
import Wrapper from "../layout/Logged/Wrapper";
import {connect} from "react-redux";
import {generateHMRCAccessTokenState, me} from "../actions/Profile";
import Grid from "@mui/material/Grid";
import Loading from "../components/Loading";
import {useState} from "react";
import {OutlinedInput} from "@mui/material";
import Button from "@mui/material/Button";
import GeneralInformation from "../components/ResidencyStatus/GeneralInformation";
import AuthorizationInformation from "../components/ResidencyStatus/AuthorizationInformation";

/**
 * @param history
 * @param profile_data
 * @param token_loading
 * @param generateHMRCAccessTokenState
 * @returns {JSX.Element}
 * @constructor
 */
const AllowHMRCResidencyStatusInfo = (
	{
		history = {
			push: (url: string = "") => url,
		},
		profile_data = {
			forename: "",
			surname: "",
			national_insurance_number: "",
			birthdate: "",
		},
		token_loading = false,
		generateHMRCAccessTokenState = (data) => data,
	}
) => {

	const [forename, setForename] = useState(profile_data.forename);
	const [surname, setSurname] = useState(profile_data.surname);
	const [national_insurance_number, setNationalInsuranceNumber] = useState(profile_data.national_insurance_number);
	const [birthdate, setBirthdate] = useState(profile_data.birthdate);


	if (token_loading) {
		return (
			<Loading absolute/>
		);
	}

	return (
		<NotificationProvider>
			<Wrapper
				history={history}
				profile_data={profile_data}
			>
				<Grid
					xs={12}
					md={12}
					sm={12}
					xl={12}
					id={"allow-hmrc-past-employers-page"}
					item
					container
				>
					<GeneralInformation/>
					<AuthorizationInformation/>

					<Grid
						xs={12}
						md={12}
						sm={12}
						xl={12}
						id={"utr-text-field-container"}
						item
						container
					>
						<label htmlFor={"forename"}>
							Forename
						</label>
						<OutlinedInput
							placeholder={"Forename"}
							value={profile_data.forename}
							onChange={(event) => setForename(event.target.value)}
							name={"forename"}
							id={"forename"}
							error={forename === ""}
							disabled={profile_data.forename !== ""}
							fullWidth
						/>
						<small>
							{
								forename === "" && (
									"Please provide your forename."
								)
							}
						</small>
					</Grid>

					<Grid
						xs={12}
						md={12}
						sm={12}
						xl={12}
						id={"utr-text-field-container"}
						item
						container
					>
						<label htmlFor={"surname"}>
							Surname
						</label>
						<OutlinedInput
							placeholder={"Surname"}
							value={profile_data.surname}
							onChange={(event) => setSurname(event.target.value)}
							name={"surname"}
							id={"surname"}
							error={surname === ""}
							disabled={profile_data.surname !== ""}
							fullWidth
						/>
						<small>
							{
								surname === "" && (
									"Please provide the surname"
								)
							}
						</small>
					</Grid>

					<Grid
						xs={12}
						md={12}
						sm={12}
						xl={12}
						id={"utr-text-field-container"}
						item
						container
					>
						<label htmlFor={"national_insurance_number"}>
							NINO (National insurance number)
						</label>
						<OutlinedInput
							placeholder={"NINO"}
							value={profile_data.national_insurance_number}
							onChange={(event) => setNationalInsuranceNumber(event.target.value)}
							name={"national_insurance_number"}
							id={"national_insurance_number"}
							error={national_insurance_number === ""}
							disabled={profile_data.national_insurance_number !== ""}
							fullWidth
						/>
						<small>
							{
								national_insurance_number === "" && (
									"Please provide the national insurance number."
								)
							}
						</small>
					</Grid>


					<Grid
						xs={12}
						md={12}
						sm={12}
						xl={12}
						id={"utr-text-field-container"}
						item
						container
					>
						<label htmlFor={"birthdate"}>
							Birthdate
						</label>
						<OutlinedInput
							placeholder={"birthdate"}
							value={profile_data.birthdate ?? birthdate}
							onChange={(event) => setBirthdate(event.target.value)}
							name={"birthdate"}
							id={"birthdate"}
							error={national_insurance_number === ""}
							disabled={profile_data.birthdate !== ""}
							fullWidth
						/>
						<small>
							{
								birthdate === "" && (
									"Please provide the national insurance number."
								)
							}
						</small>
					</Grid>
					{
						(
							birthdate !== "" ||
							national_insurance_number !== "" ||
							forename !== "" ||
							surname !== ""
						) && (
							<Grid
								xs={12}
								md={12}
								sm={12}
								xl={12}
								id={"allow-button-container"}
								item
								container
							>
								<Button
									variant={"outlined"}
									onClick={() => generateHMRCAccessTokenState({
										profiles: {
											birthdate: birthdate ?? profile_data.birthdate,
											national_insurance_number: national_insurance_number ??
												profile_data.national_insurance_number,
											forename: forename ?? profile_data.forename,
											surname: surname ?? profile_data.surname,
											scope: "read:ras",
										}
									})}
								>
									Grant authorization
								</Button>
							</Grid>
						)
					}
				</Grid>
			</Wrapper>
		</NotificationProvider>
	);
};

const mapStateToProps = state => state.Profile;

const mapDispatchToProps = dispatch => ({
	me: dispatch(me()),
	generateHMRCAccessTokenState: (data = {}) => dispatch(generateHMRCAccessTokenState(data)),
});


export default connect(mapStateToProps, mapDispatchToProps)(AllowHMRCResidencyStatusInfo);