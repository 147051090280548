import {connect} from 'react-redux';
import {me, processResidencyStatus} from "../actions/Profile";
import NotificationProvider from "../components/NotificationProvider";
import Wrapper from "../layout/Logged/Wrapper";
import {Link, useLocation} from "react-router-dom";
import React, {useEffect} from "react";
import Grid from "@mui/material/Grid";
import Loading from "../components/Loading";

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
	const {search} = useLocation();

	return React.useMemo(() => new URLSearchParams(search), [search]);
}

/**
 * @param history
 * @param profile_data
 * @param loading
 * @param processResidencyStatus
 * @returns {JSX.Element}
 * @constructor
 */
const ResidencyStatusAccepted = (
	{
		history = {
			push: (url: string = "") => url,
		},
		profile_data = {},
		loading = false,
		processResidencyStatus = (data) => data,
	}
): JSX.Element => {

	const code = useQuery().get("code");
	const state = useQuery().get("state");

	useEffect(() => processResidencyStatus({
		profiles: {
			code,
			state,
		}
	}), [
		code,
		state,
		processResidencyStatus,
	]);

	if (loading) {
		return (
			<Loading absolute/>
		);
	}

	return (
		<NotificationProvider>
			<Wrapper
				history={history}
				profile_data={profile_data}
			>
				<Grid
					xs={12}
					sm={12}
					lg={12}
					xl={12}
					md={12}
					id={"allow-hmrc-past-employers-page"}
					item
					container
				>
					<Grid
						xs={12}
						sm={12}
						lg={12}
						xl={12}
						md={12}
						item
					>
						<h3>
							And now?
						</h3>
						<p>
							Now you don't need to do anything else.
							<br/>
							Collegia will look at your residency status.
						</p>
					</Grid>
					<Grid
						xs={12}
						sm={12}
						lg={12}
						xl={12}
						md={12}
						item
					>
						<h3>
							Importance to you gather this information to Collegia
						</h3>
						<p>
							With this information you'll help Collegia to collect your GOV TOP UP contributions more
							easily.
						</p>
					</Grid>
					<Grid
						xs={12}
						sm={12}
						lg={12}
						xl={12}
						md={12}
						item
					>
						<h3>
							Moving on with your pension
						</h3>
						<p>
							Now you gathering a great future to you, but you can have a great future with Collegia.
						</p>
					</Grid>
					<Grid
						xs={12}
						sm={12}
						lg={12}
						xl={12}
						md={12}
						item
					>
						<h3>
							Improving your current pension
						</h3>
						<ul>
							<li>
								<Link to={"/extra-contributions/new"}>
									Make a one-off contribution
								</Link>
							</li>
						</ul>
					</Grid>
				</Grid>
			</Wrapper>
		</NotificationProvider>
	);
}


const mapStateToProps = state => state.Profile;

const mapDispatchToProps = dispatch => ({
	me: dispatch(me()),
	processResidencyStatus: (data) => dispatch(processResidencyStatus(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(ResidencyStatusAccepted);