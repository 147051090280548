import React from "react";
import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";
import NoCompany from '../../../images/undraw_files_6b3d.svg';

/**
 * @param props
 * @returns {JSX.Element}
 */
function renderLogo(props = {
	employer_data: {
		employer: {
			company_logo: "",
		}
	}
}): JSX.Element {
	if (props.employer_data) {
		if (props.employer_data.employer && props.employer_data.employer.company_logo !== null) {
			return (
				<img
					src={props.employer_data.employer.company_logo}
					alt={props.employer_data.employer.title}
				/>
			);
		}
	}

	return (
		<img
			className={"img"}
			src={NoCompany}
			alt={"No company logo"}
		/>
	);
}

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const Logo = (props = {
	employer_data: {
		employer: {
			company_logo: "",
		}
	}
}): JSX.Element => (
	<Grid
		xs={12}
		sm={12}
		md={12}
		lg={12}
		item
		container
		alignItems={"center"}
		alignContent={"center"}
		direction={"column"}
	>
		<Grid
			className={"employer-image-holder"}
			item
		>
			{renderLogo(props)}
		</Grid>
	</Grid>
);

Logo.propTypes = {
	employer_data: PropTypes.shape({
		employer: PropTypes.shape({
			logo: PropTypes.string,
		}),
	}).isRequired,
};

export default Logo;