import React from "react";

import Routes from "./utils/Routes";
import {retrieveCollegiaAccessToken} from "./utils/Helpers";


export default class App extends React.Component {
	constructor(props) {
		super(props);
		if (retrieveCollegiaAccessToken()) {
			this.logOutInterval = setTimeout(
				() => {
					window.location.href = "/logout";
				},
				900000 // 15 minutes
			);
		}
	}

	_logOutInterval;

	/**
	 * @returns {*}
	 */
	get logOutInterval() {
		return this._logOutInterval;
	}

	/**
	 * @param value
	 */
	set logOutInterval(value: null) {
		this._logOutInterval = value;
	}

	/**
	 * @param prevProps
	 * @param prevState
	 * @param snapshot
	 */
	componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
		if (this.props !== prevProps) {
			window.$crisp.push(["set", "user:email", this?.props?.profile_data?.email ?? ""]);
			window.$crisp.push(["set", "user:nickname", this?.props?.profile_data?.forename ?? ""]);
			window.$crisp.push(["set", "user:trial_period", this?.props?.profile_data?.trial_period ?? ""]);

		}
	}

	componentDidMount() {
		const root = document.getElementById("root");

		if (root) {
			if (retrieveCollegiaAccessToken()) {
				root.onmousemove = () => {
					clearTimeout(this.logOutInterval);
					delete this.logOutInterval;
					this.logOutInterval = setTimeout(
						() => window.location.href = "/logout",
						// () => false,
						900000 // 15 minutes
					);
				};
			}
		}

		window.$crisp = [];
		window.CRISP_WEBSITE_ID = "adab5a98-001f-4db0-8c48-2dc6bb8b074f";

		(function () {
			var d = document;
			var s = d.createElement("script");

			s.src = "https://client.crisp.chat/l.js";
			s.async = 1;
			d.getElementsByTagName("head")[0].appendChild(s);

		})();

		if(window.screen.width < 300) {
			window.$crisp.push(['do', 'chat:hide']);
		}
	}

	componentWillUnmount() {
		if (this.logOutInterval) {
			clearTimeout(this.logOutInterval);
			delete this.logOutInterval;
		}
	}

	/**
	 * @returns {*}
	 */
	render = () => <Routes/>;
}
