import React from "react";
import PropTypes from "prop-types";

import Paper from "@mui/material/Paper";
import Logo from "./HeaderInfo/Logo";
import CompanyTitle from "./HeaderInfo/CompanyTitle";
import CompanyConnectionStatus from "./HeaderInfo/CompanyConnectionStatus";

/**
 * @param employer_data
 * @param t
 * @returns {JSX.Element}
 * @constructor
 */
const EmployerHeaderInfo = (
	{
		employer_data = {},
		t = key => key,
	}
): JSX.Element => (
	<Paper
		id={"connection-details-info-header"}
	>
		<Logo
			employer_data={employer_data}
		/>

		<CompanyTitle
			employer_data={employer_data}
		/>
		<CompanyConnectionStatus
			employer_data={employer_data}
			t={t}
		/>
	</Paper>
);

EmployerHeaderInfo.propTypes = {
	employer_data: PropTypes.object.isRequired,
};

export default EmployerHeaderInfo;