import React, {useEffect, useState} from "react";
import Wrapper from "../../layout/Logged/Wrapper";
import Grid from '@mui/material/Grid';
import {
	getParams,
	notSignedMandates,
	retrieveCollegiaAccessToken
} from "../../utils/Helpers";
import {connect} from "react-redux";
import {me} from "../../actions/Profile";
import {get, retrievePaymentInfo} from "../../actions/ExtraContributions";
import Button from "@mui/material/Button";
import ExtraContributionCard from "../../components/NewContributions/ExtraContributionCard";
import CalendarIcon from "../../images/calendar-icon-white.svg";
import OneOffIcon from "../../images/one-off-icon-black.svg";
import Loading from "../../components/Loading";
import Typography from "@mui/material/Typography";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {Pagination} from "@mui/material";
import {useTranslation, withTranslation} from 'react-i18next';
import {useNavigate} from "react-router-dom";

function returnFormattedDate(contributionDate: string = "") {
	const dateObject = new Date(contributionDate + 'T00:00:00');
	const monthAbbreviations = [
		'JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN',
		'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'
	];

	const day = dateObject.getDate();
	const month = monthAbbreviations[dateObject.getMonth()];
	const year = dateObject.getFullYear();

	return `${day} ${month} ${year}`;
}

/**
 * @param contributions
 * @returns {unknown[]}
 * @private
 */
const _renderCardsList = (contributions: any[] = []) => (
	contributions.map((contribution, index) => (
		<Grid container key={index}>
			{
				contribution?.transactions.length > 0 &&
				<Grid container>
					{
						contribution?.transactions.map((transactions, index) => (
							<ExtraContributionCard
								bank={contribution.bank_provider}
								type={contribution.contribution_type}
								status={contribution.provider_status}
								value={transactions.formatted_individual_contribution}
								date={returnFormattedDate(transactions.date_of_payment)}
								key={index}
							/>
						))
					}
				</Grid>
			}
		</Grid>
		)
	)
);


/**
 * @param contributions
 * @param history
 * @param t
 * @returns {JSX.Element}
 * @private
 */
function _renderMonthlyButton(contributions: any[] = [], history, t = (key) => key,) {
	if (contributions !== false && contributions?.length > 0) {
		let hasMonthly = contributions?.filter(contribution => contribution.contribution_type === "Monthly" && contribution.active === true);
		if (hasMonthly.length > 0) {
			hasMonthly = hasMonthly.filter(monthly => monthly.provider_status !== "CANCELLED");
			if (hasMonthly.length > 0) {
				return (
					<Button
						className={"active-monthly"}
					>
						<Grid
							lg={12}
							xl={12}
							sm={12}
							md={12}
							justifyContent={'center'}
							className={"active-monthly-grid"}
							item
							container
						>
							<Grid
								lg={12}
								xl={12}
								sm={12}
								xs={12}
								md={12}
								justifyContent={'center'}
								className={"active-monthly-grid"}
								item
								container
							>
								<Grid
									md={12}
									sm={12}
									xl={12}
									xs={12}
									lg={12}
									justifyContent={'space-between'}
									alignItems={"center"}
									item
									container
								>
									<Grid className={"grid"}>
										<img
											src={CalendarIcon}
											alt={""}
										/>
										<Typography
											className={"contributions-type"}
											variant={"p"}
										>
											{t("individual.extra_contributions.monthly.set")}
										</Typography>
									</Grid>
									<Typography
										className={"contributions-status accepted"}
										variant={"p"}
									>
										{hasMonthly[0].provider_status}
										<FiberManualRecordIcon
											color={"green"}
											className={"active-icon"}
										/>
									</Typography>
								</Grid>
								<Grid
									md={12}
									sm={12}
									xl={12}
									xs={12}
									lg={12}
									paddingTop={1}
									justifyContent={'space-between'}
									item
									container
								>
									<Typography
										className={"bank-name"}
										variant={"p"}
									>
										{hasMonthly[0].bank_provider}
									</Typography>
									<Typography
										className={"contributions-value"}
										variant={"p"}
									>
										{hasMonthly[0].contribution_value}
									</Typography>
								</Grid>
							</Grid>
						</Grid>
					</Button>
				)
			}

		}
	}

	return (
		<Button
			className={"modal-button monthly"}
			onClick={() => history("/extra-contributions/new/Monthly", {
				state: {
					index: 0,
					step: "Monthly",
					firstContribution: contributions.length <= 0,
					modalOpen: contributions.length <= 0,
				},
			})}
		>
			<Grid paddingRight={1}>
				<img
					src={CalendarIcon}
					alt={""}
				/>
			</Grid>
			Set Monthly contributions
		</Button>
	);
}

/**
 * @param profile_data
 * @param contributions
 * @param retrievePaymentInfo
 * @param history
 * @param get
 * @param contributionsLoading
 * @param loading
 * @returns {JSX.Element}
 * @constructor
 */
const ExtraContributions = (
	{
		profile_data = [],
		contributions = [],
		retrievePaymentInfo = () => {
		},
		get = () => {
		},
		contributionsLoading = false,
		loading = false,
	}
): JSX.Element => {

	if (!retrieveCollegiaAccessToken()) {
		window.location = "/login";
	}

	const [oldPage, setOldPage] = useState(1),
		[currentPage, setCurrentPage] = useState(1),
		[abortController, setAbortController] = useState(new AbortController()),
		[uri] = useState(getParams(window.location.href)),
		{t} = useTranslation(),
		history = useNavigate();

	useEffect(() => {
		if (contributions === false) {
			get(false, oldPage, currentPage, abortController, true);
			notSignedMandates();
		}
	}, [currentPage, oldPage, get, abortController, contributions]);

	useEffect(() => {
		const delayDebounceFn = setTimeout(() => {
			if (uri !== "") {
				if (uri.standing_order_id) {
					retrievePaymentInfo(uri.standing_order_id ?? "", "InsertValue");
				} else if (uri.payment_id) {
					retrievePaymentInfo(uri.payment_id ?? "", "one-off");
				}
			}
		}, 15000);
		return () => clearTimeout(delayDebounceFn);
	});

	useEffect(() => {
		const delayDebounceFn = setTimeout(() => {
			get(false, oldPage, currentPage, abortController, false);
			notSignedMandates();
		}, 15000);
		return () => clearTimeout(delayDebounceFn);
	});

	useEffect(() => {
		const delayDebounceFn = setTimeout(() => {
			setAbortController(new AbortController());
			setOldPage(currentPage);
		}, 15000);
		return () => clearTimeout(delayDebounceFn);
	});

	if (contributionsLoading || loading) {
		return (
			<Loading absolute/>
		);
	}

	return (
		<Wrapper
			history={history}
			textHeader={"Extra Contributions"}
			hideFooter={true}
			t={t}
		>
			<Grid
				lg={12}
				xl={12}
				sm={12}
				md={12}
				xs={12}
				id={"extra-contributions-design"}
				container
				item
			>
				<Grid
					className={"extra-contributions-container"}
					container
				>
					<Grid
						lg={12}
						xl={12}
						sm={12}
						md={12}
						xs={12}
						justifyContent={'center'}
						item
						container
					>
						<Grid
							lg={9}
							xl={7.75}
							sm={11}
							md={11}
							xs={11}
							className={'button-grid'}
							alignItems={"end"}
							item
							container
						>
							{
								_renderMonthlyButton(profile_data?.extra_contributions, history, t)
							}
							<Button
								className={"modal-button one-off"}
								onClick={() => history("/extra-contributions/new/One-off", {
									state: {
										index: 3,
										step: "One-off",
										firstContribution: contributions.length <= 0,
										modalOpen: contributions.length <= 0,
									},
								})}
							>
								<Grid paddingRight={1} paddingBottom={1}>
									<img
										src={OneOffIcon}
										alt={t("individual.extra_contributions.make_one_off_contribution_button")}
									/>
								</Grid>
								{t("individual.extra_contributions.make_one_off_contribution_button")}
							</Button>
						</Grid>
					</Grid>
					{
						_renderCardsList(contributions?.data)
					}
					{
						contributions?.data && contributions?.last_page > 1 && (
							<Grid
								lg={12}
								xl={12}
								sm={11}
								md={11}
								xs={11}
								justifyContent={"center"}
								marginTop={5}
								item
								container
							>
								<Pagination
									page={contributions?.current_page}
									count={contributions?.last_page}
									onChange={(e, value) => {
										if (value !== currentPage) {
											setOldPage(currentPage);
											setCurrentPage(value);
											get(true, oldPage, value, new AbortController());
										}
									}}
								/>
							</Grid>
						)}
				</Grid>
			</Grid>
		</Wrapper>
	);
};

const mapStateToProps = state => {
	const {
		Profile = [],
		ExtraContributions = [],
	} = state;

	return {
		...Profile,
		...ExtraContributions,
	};
};
/**
 * @param dispatch
 * @returns {{myProjections: (function(): *)}}
 */
const mapDispatchToProps = dispatch => ({
	retrievePaymentInfo: (orderId: string = "", type: string = "") => dispatch(retrievePaymentInfo(orderId, type)),
	get: (
		scrollUp: boolean = false,
		oldPage: number = 1,
		pageNumber: number = 1,
		abortController
	) => dispatch(get(scrollUp, oldPage, pageNumber, abortController)),
	me: dispatch(me()),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ExtraContributions));
