import React, {useState} from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Slide from "@mui/material/Slide";
import {connect} from "react-redux";
import {get} from "../actions/EmploymentStatus";
import Loading from "../components/Loading";
import Button from "@mui/material/Button";
import {me, update} from "../actions/Profile";
import {PrimaryHeader} from "../components/Headers/PrimaryHeader";
import {useTranslation} from "react-i18next";

/**
 * @param employment_status_loading
 * @param employment_status
 * @param profile_data
 * @param history
 * @param update
 * @returns {JSX.Element}
 * @constructor
 */
const EmploymentStatus = (
	{
		employment_status_loading = false,
		employment_status = [],
		profile_data = {
			id: null,
		},
		history = {
			push: () => {
			},
		},
		update = () => {
		}
	}
): JSX.Element => {
	const [state, setState] = useState({
			employment_status_id: 0,
			is_other_selected: false,
			otherName: ""
		}),
		{t} = useTranslation();

	const {
		employment_status_id = 0,
		is_other_selected = false,
		otherName = "",
	} = state;

	/**
	 *
	 * @returns {boolean}
	 * @private
	 */
	const _shouldDisableButton = () => {
		if (!state.is_other_selected) {
			return state.employment_status_id === 0;
		} else {
			return state.otherName === "";
		}
	};

	if (employment_status_loading) {
		return (
			<Loading absolute/>
		);
	}

	return (
		<Grid id={"container-select-employment-status"}>
			<Grid>
				<PrimaryHeader
					history={history}
					backIcon
					backPage={"/main-menu"}
				>
					{t("individual.employment_status.title")}
				</PrimaryHeader>

				<Slide
					direction={"up"}
					in={true}
				>
					<Paper
						id={"paper-holder"}
					>
						<Grid
							xs={12}
							sm={12}
							lg={12}
							md={12}
							item
							container
						>
							<Grid
								xs={12}
								sm={12}
								lg={12}
								md={12}
								item
								container
							>
								<Typography
									className={"title"}
								>
									{t("individual.please_inform_us_your_current_employment_status")}
								</Typography>
							</Grid>
							<FormControl
								className={"employment-status-form-control"}
								fullWidth>
								<Select
									variant={"outlined"}
									fullWidth
									value={employment_status_id}
									onChange={(event) => {
										let isOther = employment_status
											.filter(e => e.id === event.target.value);

										if (isOther[0].name.toLowerCase() === "other") {
											setState({
												...state,
												is_other_selected: true,
												employment_status_id: event.target.value,
											});

										} else {
											setState({
												...state,
												is_other_selected: false,
												employment_status_id: event.target.value,
											});
										}
									}}
								>
									<MenuItem
										value={0}
										disabled
										selected
									>
										{t("individual.employment_status.form.select.default_input")}
									</MenuItem>
									{
										employment_status.map(e =>
											<MenuItem
												value={e.id}
												key={e.id}
											>
												{e.name}
											</MenuItem>
										)
									}
								</Select>
							</FormControl>
							{
								is_other_selected && (
									<FormControl
										className={"employment-status-form-control"}
										fullWidth
									>
										<OutlinedInput
											placeholder={"Type your other employment status"}
											name={"otherName"}
											id={"otherName"}
											value={otherName}
											onChange={(event) =>
												setState({
													...state,
													otherName: event.target.value
												})
											}
											fullWidth
										/>
									</FormControl>
								)
							}
							<FormControl
								className={"employment-status-form-control"}
								fullWidth
							>
								<Button
									className={"primary"}
									variant={"outlined"}
									disabled={_shouldDisableButton()}
									onClick={() =>
										update(
											profile_data.id, {
												profiles: {
													employment_status_id,
													is_other_selected,
													otherName
												}
											}
										)
									}
								>
									{t("individual.employment_status.form.update_employment_status_button")}
								</Button>
							</FormControl>
						</Grid>
					</Paper>
				</Slide>
			</Grid>
		</Grid>
	);
};

const mapStateToProps = state => {
	const {
		Profile = [],
		EmploymentStatus = [],
	} = state;

	return {
		...Profile,
		...EmploymentStatus,
	}
};

const mapDispatchToProps = dispatch => ({
	me: dispatch(me()),
	get: dispatch(get()),
	update: (id, data) => dispatch(update(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmploymentStatus);
