import InvestmentApproachService from "../../services/InvestmentApproach";
import {FETCH_INVESTMENT_APPROACHES, UPDATE_INVESTMENT_APPROACHES} from "./types";
import {forceScrollUp} from "../../utils/Helpers";
import {me} from "../Profile";

/**
 * @returns {function(...[*]=)}
 */
export const get = (scrollUp: boolean = false) => dispatch => {
	dispatch({
		type: FETCH_INVESTMENT_APPROACHES,
		investment_approach_loading: true,
	});
	new InvestmentApproachService()
		.active()
		.then(response => {
			dispatch({
				type: FETCH_INVESTMENT_APPROACHES,
				investment_approach_loading: false,
				...response.data.data,
				scrollUp
			});
			if (scrollUp) {
				forceScrollUp();
			}
		})
		.catch(error => {
			console.error(error);
			if (error.response) {
				if (error.response.status === 401) {
					localStorage.clear();
					window.location = "/login";
				}
			}
		});
};

/**
 * @param data
 * @returns {function(...[*]=)}
 */
export const updateInvestmentApproach = (data: any = false) => dispatch => {
	dispatch({
		type: UPDATE_INVESTMENT_APPROACHES,
		investment_approach_loading: true
	});
	if (data) {
		new InvestmentApproachService()
			.updateInvestmentApproach(data)
			.then(response => {
				dispatch({
					type: UPDATE_INVESTMENT_APPROACHES,
					investment_approach_loading: false
				});
				if (response.data.data) {
					dispatch(me());
				}
			})
			.catch(error => {
				dispatch({
					type: UPDATE_INVESTMENT_APPROACHES,
					investment_approach_loading: false
				});
				if (error.response) {
					if (error.response.status === 401) {
						localStorage.clear();
						window.location = "/login";
					}
				}

				console.error(error);
			});
	}
};