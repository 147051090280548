import ActivationToken from "../../services/ActivationToken";
import * as SnackBar from "../SnackBar";

/**
 * @param data
 * @returns {function(...[*]=)}
 */
export const optOut = (data = false) => dispatch => {
	if (data) {
		new ActivationToken()
			.optOut(data)
			.then(response => {
				window.location = "/account-deleted";
			})
			.catch(error => {
				dispatch(SnackBar.error("An unexpected error occurred. Please contact support and inform code OPT-11."), 9000);
				console.error(error);
			});
	}
};