import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import React from 'react';
import CurrencyInput from "react-currency-input-field";

/**
 * @returns {JSX.Element}
 * @constructor
 * @param value
 */
const CurrencyInputCustom = React.forwardRef((props, ref) => {
	const {onChange, ...other} = props;
	return (
		<CurrencyInput
			ref={ref}
			{...other}
			intlConfig={{
				min: 0,
				currency: "GBP"
			}}
			onValueChange={(value, name) =>
				onChange({
					target: {value, name}
				})
			}
		/>
	);
});

/**
 * @param pensionProvider
 * @param pensionScheme
 * @param membershipNumber
 * @param totalPot
 * @param setPensionProvider
 * @param setPensionScheme
 * @param setMembershipNumber
 * @param setTotalPot
 * @param create
 * @param history
 * @param t
 * @returns {JSX.Element}
 * @constructor
 */
const Form = (
	{
		pensionProvider = "",
		pensionScheme = "",
		membershipNumber = "",
		totalPot = 0,
		setPensionProvider = () => {
		},
		setPensionScheme = () => {
		},
		setMembershipNumber = () => {
		},
		setTotalPot = () => {
		},
		create = () => {
		},
		history = () => {
		},
		t = key => key,
	}
): JSX.Element => {

	const handleOnValueChange = ({target: {value}}) => {
		setTotalPot(value);
	};

	return (
		<Grid
			xs={12}
			md={12}
			sm={12}
			lg={12}
			item
			container
			direction={"column"}
			alignContent={"center"}
			alignItems={"center"}
			id={"frmAddNewPension"}
		>
			<TextField
				variant={"outlined"}
				name={"pension_provider"}
				placeholder={t("individual.other_pensions.new.pension_provider_input")}
				className={"default-input-form"}
				onChange={event => setPensionProvider(event.target.value)}
				value={pensionProvider}
				fullWidth
			/>
			<TextField
				variant={"outlined"}
				name={"pension_scheme"}
				placeholder={t("individual.other_pensions.new.pension_scheme_input")}
				className={"default-input-form"}
				onChange={event => setPensionScheme(event.target.value)}
				value={pensionScheme}
				fullWidth
			/>
			<TextField
				variant={"outlined"}
				name={"membership_number"}
				placeholder={t("individual.other_pensions.new.membership_input")}
				className={"default-input-form"}
				onChange={event => setMembershipNumber(event.target.value)}
				value={membershipNumber}
				fullWidth
			/>
			<TextField
				className={"default-input-form"}
				fullWidth
				onChange={handleOnValueChange}
				InputProps={{inputComponent: CurrencyInputCustom}}
				value={totalPot < 0 ? 0 : totalPot}
			/>
			<Button
				variant={"contained"}
				color={"primary"}
				className={"save-button"}
				onClick={() =>
					create({
						other_pensions: {
							total_pot: totalPot,
							membership_number: membershipNumber,
							pension_scheme: pensionScheme,
							pension_provider: pensionProvider,
							active: true,
						}
					})
				}
				disabled={
					!totalPot ||
					!membershipNumber ||
					!pensionScheme ||
					totalPot <= 0
				}
			>
				{t("individual.other_pensions.new.save_button")}
			</Button>
			<Button
				variant={"contained"}
				className={"cancel-button"}
				onClick={() => history("/other-pensions")}
			>
				{t("individual.cancel.button")}
			</Button>
		</Grid>
	)
}

export default Form;