// importing the default actions.
import {CONFIRM_CODE, EMAIL_CHANGED, RESEND_CODE} from "../../actions/ActivationCode/types";
import {resolveReduxState} from "../../utils/Helpers";

/**
 * @type {{activation_code_loading: boolean, activationCodeData: boolean}}
 */
const initial_state = {
	activation_code_loading: false,
	activationCodeData: false,
	errorMessage: "",
	canProceed: false,
	canProceedEmail: false,
};

/**
 * @param state
 * @param action
 * @returns {{activation_code_loading: boolean}|{valueOf, (): boolean}|boolean}
 */
const ActivationCodeStore = (state = initial_state, action = false) => {
	if (action) {
		const {type = false} = action;

		if (type) {
			if (type === RESEND_CODE) return resolveReduxState(state, action);
			else if (type === CONFIRM_CODE) return resolveReduxState(state, action);
			else if (type === EMAIL_CHANGED) return resolveReduxState(state, action);
		}
	}

	return state;
};

export default ActivationCodeStore;