import {
	GET_EMPLOYMENT_STATUS,
} from "./types";
import EmploymentStatusService from "../../services/EmploymentStatus";

/**
 * @param relationships
 * @returns {(function(*): void)|*}
 */
export const get = () => dispatch => {
	dispatch({
		type: GET_EMPLOYMENT_STATUS,
		employment_status_loading: true,
		employment_status: [],
	});

	new EmploymentStatusService()
		.get()
		.then(response => dispatch({
				type: GET_EMPLOYMENT_STATUS,
				employment_status_loading: false,
				employment_status: response?.data?.data,
			})
		)
		.catch(error => {
			dispatch({
				type: GET_EMPLOYMENT_STATUS,
				employment_status_loading: true,
				employment_status: [],
			});
			console.error(error);
		});
}