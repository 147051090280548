import React from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const MenuCards = (props = {
	history: () => {
	},
	link: "",
	image: "",
	alt: "",
	text: "",
	imageMargin: false,
}): JSX.Element => {
	return (
		<Card
			className={"my-account-cards"}
			onClick={() => props?.history(props?.link)}
		>
			<CardContent
				className={"card-padding-adjust-style"}
			>
				<Grid
					className={"my-account-cards-content"}
					direction={"row"}
					alignItems={"center"}
					container
					item
					spacing={3}
				>
					<Grid
						xs={2}
						lg={2}
						md={2}
						item
						textAlign={"center"}
					>
						<img
							className={"img-size"}
							src={props?.image}
							alt={props?.alt}
						/>
					</Grid>
					<Grid
						xs={9}
						lg={9}
						md={9}
						item
						textAlign={"start"}
					>
						<Typography className={"font-size-responsive-with-margin"}>
							{props?.text}
						</Typography>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
}

export default MenuCards;