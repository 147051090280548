import React from 'react';
import './styles/index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {CssBaseline} from "@mui/material"
import {createRoot} from "react-dom/client";
import './i18n';

// added css normalize for all webpages.
import "./styles/wlecome-page-webflow/normalize.css";

// importing the provider.
import {Provider} from 'react-redux';

// importing the store.
import store from './store';
import Snackbar from "./components/SnackBar";
import {iOS} from "./utils/Helpers";

iOS();

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
	<Provider store={store}>
		<CssBaseline/>
		<Snackbar/>
		<App/>
		<div id="crisp-client"/>
	</Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
