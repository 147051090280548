import React from "react";

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import PropTypes from "prop-types";

/**
 * @param props
 * @returns {JSX.Element}
 */
const ActionsButtons = (props = {
	code: "",
	history: {
		push: () => {
		},
	}
}): JSX.Element => {
	if (props.optOutButton) {
		return (
			<Paper
				id={"buttons-holder-container"}
			>
				<Grid
					xs={12}
					sm={12}
					md={12}
					lg={12}
					item
					container
				>
					<Grid
						xs={12}
						sm={12}
						md={12}
						lg={12}
						item
						container
						alignItems={"center"}
						alignContent={"center"}
						direction={"column"}
						className={"contribution-history-container"}
					>
						<Button
							variant={"contained"}
							color={"primary"}
							onClick={() => props.history(`/contributions-history/${props.code}`)}
						>
							{props.t("individual.contribution_history.title")}
						</Button>
					</Grid>

					<Grid
						xs={12}
						sm={12}
						md={12}
						lg={12}
						item
						container
						alignItems={"center"}
						alignContent={"center"}
						direction={"column"}
						className={"contribution-want-out-container"}
					>
						<Button
							variant={"outlined"}
							onClick={() => props.history(`/opt-out/employer/${props.code}`)}
						>
							{props.t("individual.i_want_to_opt_out")}
						</Button>
					</Grid>
				</Grid>
			</Paper>
		)
	} else {
		return (
			<Paper
				id={"buttons-holder-container"}
			>
				<Grid
					xs={12}
					sm={12}
					md={12}
					lg={12}
					item
					container
				>
					<Grid
						xs={12}
						sm={12}
						md={12}
						lg={12}
						item
						container
						alignItems={"center"}
						alignContent={"center"}
						direction={"column"}
						className={"contribution-history-container"}
					>
						<Button
							variant={"contained"}
							color={"primary"}
							onClick={() => props.history(`/contributions-history/${props.code}`)}
						>
							{props.t("individual.contribution_history.title")}
						</Button>
					</Grid>
				</Grid>
			</Paper>
		)
	}

}

ActionsButtons.propTypes = {
	code: PropTypes.string,
    history: PropTypes.shape({
	    history: PropTypes.shape({
		    push: PropTypes.func,
	    }),
    }),
    optOutButton: PropTypes.bool,
};

export default ActionsButtons;