import {connect} from 'react-redux';
import {me, processPastEmployments} from "../actions/Profile";
import NotificationProvider from "../components/NotificationProvider";
import Wrapper from "../layout/Logged/Wrapper";
import {Link, useLocation} from "react-router-dom";
import React, {useEffect, useTransition} from "react";
import Grid from "@mui/material/Grid";
import Loading from "../components/Loading";


// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
	const {search} = useLocation();

	return React.useMemo(() => new URLSearchParams(search), [search]);
}

/**
 * @param history
 * @param profile_data
 * @param loading
 * @param processPastEmployments
 * @returns {JSX.Element}
 * @constructor
 */
const AccessEmployerHistoryAccepted = (
	{
		history = {
			push: (url: string = "") => url,
		},
		profile_data = {},
		loading = false,
		processPastEmployments = (data) => data,
	}
) => {

	const code = useQuery().get("code");
	const state = useQuery().get("state");
	const {t} = useTransition();

	useEffect(() => processPastEmployments({
		profiles: {
			code,
			state,
		}
	}), [
		code,
		state,
		processPastEmployments,
	]);

	if (loading) {
		return (
			<Loading absolute/>
		);
	}

	return (
		<NotificationProvider>
			<Wrapper
				history={history}
				profile_data={profile_data}
				t={t}
			>
				<Grid
					xs={12}
					sm={12}
					lg={12}
					xl={12}
					md={12}
					id={"allow-hmrc-past-employers-page"}
					item
					container
				>
					<Grid
						xs={12}
						sm={12}
						lg={12}
						xl={12}
						md={12}
						item
					>
						<h3>
							And now?
						</h3>
						<p>
							Now you don't need to do anything else.
							<br/>
							Collegia will look at your previously employments counting by this actual year
							({new Date().getFullYear()}) and backwards.
						</p>
					</Grid>
					<Grid
						xs={12}
						sm={12}
						lg={12}
						xl={12}
						md={12}
						item
					>
						<h3>
							Importance to you gather this information to Collegia
						</h3>
						<p>
							With this previously employment history information, Collegia can access your previous(ly)
							employment(s) and make it easy to us request from your past employment(s) your pension
							values transfer to us.
						</p>
					</Grid>
					<Grid
						xs={12}
						sm={12}
						lg={12}
						xl={12}
						md={12}
						item
					>
						<h3>
							Moving on with your pension
						</h3>
						<p>
							Now you gathering a great future to you, but you can have a great future with Collegia.
						</p>
					</Grid>
					<Grid
						xs={12}
						sm={12}
						lg={12}
						xl={12}
						md={12}
						item
					>
						<h3>
							Improving your current pension
						</h3>
						<p>
							<ul>
								<li>
									<Link to={"/extra-contributions/new/one-off"}>
										{t("individual.extra_contributions.make_one_off_contribution_button")}
									</Link>
								</li>
							</ul>
						</p>
					</Grid>
				</Grid>
			</Wrapper>
		</NotificationProvider>
	);
}


const mapStateToProps = state => state.Profile;

const mapDispatchToProps = dispatch => ({
	me: dispatch(me()),
	processPastEmployments: (data) => dispatch(processPastEmployments(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(AccessEmployerHistoryAccepted);