import React from "react";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";

/**
 * @param props
 * @returns {string|*}
 */
function renderCompanyTittle(props = {
	employer_data: {
		employer: {
			title: "",
		}
	}
}): string {

	if (props.employer_data) {
		if (props.employer_data.employer) {
			return props.employer_data.employer.title;
		}
	}

	return "";
}

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const CompanyTitle = (props = {
	employer_data: {
		employer: {
			title: "",
		}
	}
}): JSX.Element => (
	<Grid
		xs={12}
		sm={12}
		md={12}
		lg={12}
		item
		container
		className={"employer-title-holder"}
	>
		<Grid
			xs={12}
			sm={12}
			md={12}
			lg={12}
			item
			container
			alignItems={"center"}
			alignContent={"center"}
			direction={"column"}
		>
			<Grid
				className={`wrapper-holder`}
				item
			>
				<Typography
					variant={"h5"}
				>
					{renderCompanyTittle(props)}
				</Typography>
			</Grid>
		</Grid>
	</Grid>
);

CompanyTitle.propTypes = {
	employer_data: PropTypes.shape({
		employer: PropTypes.shape({
            title: PropTypes.string,
        }),
	}).isRequired,
};

export default CompanyTitle;