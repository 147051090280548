import {useEffect, useState} from 'react';
import Loading from "../components/Loading";

// redux actions.
import {connect} from "react-redux";
import {retrieveCollegiaAccessToken} from "../utils/Helpers";
import {closeAccount, me} from "../actions/Profile";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Slide from '@mui/material/Slide';
import Wrapper from "../layout/Logged/Wrapper";
import FirstWarning from "../components/OptOut/FirstWarning";
import ConfirmOptOut from "../components/OptOut/ConfirmOptOut";
import {PrimaryHeader} from "../components/Headers/PrimaryHeader";

const CloseAccount = (
	{
		me = () => {
		},
		history = {
			push: () => {
			}
		},
		closeAccount = () => {
		},
		profile_data = {},
	}
): JSX.Element => {
	const [currentStep, setCurrentStep] = useState(1);

	useEffect(() => {
		if (!retrieveCollegiaAccessToken()) {
			window.location.href = "/login";
		}

		const menu = document.getElementById("menu"),
			footer = document.getElementById("activation-page-footer");
		if (menu) {
			menu.style.display = "none";
		}

		if (footer) {
			footer.style.position = "initial";
		}

		me();
	}, [me]);

	/**
	 * @returns {void}
	 */
	const _goNextStep = () => {
		setCurrentStep(currentStep + 1);
	};

	/**
	 *
	 * @param currentStep
	 * @param profile_data
	 * @returns {JSX.Element}
	 * @private
	 */
	const _renderContent = (currentStep = 0, profile_data = false) => {
		switch (currentStep) {
			case 0:
				return (
					<FirstWarning
						history={history}
						proceed={_goNextStep}
					/>
				);
			case 1:
				return (
					<ConfirmOptOut
						profile_data={profile_data}
						history={history}
						closeAccount={closeAccount}
					/>
				);
			default:
				setCurrentStep(0);
		}
	};

	if (!profile_data) {
		return (
			<Loading absolute/>
		);
	}

	return (
		<Wrapper
			history={history}
		>
			<Slide in={true} direction={"up"}>
				<div>
					<Grid
						direction={"column"}
						alignItems={"center"}
						alignContent={"center"}
						id={"opt-out-container"}
						container
						item
					>
						<PrimaryHeader
							history={history}
							backIcon
							backPage={"/my-account"}
						>
							Close account
						</PrimaryHeader>
						<Paper
							id={"optOutModalContentContainer"}
						>
							{_renderContent(currentStep, profile_data)}
						</Paper>
					</Grid>
				</div>
			</Slide>
		</Wrapper>
	);
};

/**
 * @param dispatch
 * @returns {{optOut: (function(*=): *), retrieveByToken: (function(*=, *=): *)}}
 */
const mapDispatchToProps = dispatch => ({
	closeAccount: () => dispatch(closeAccount()),
	me: (scrollUp: boolean = false) => dispatch(me(scrollUp)),
});

/**
 * @param state
 * @returns {*}
 */
const mapStateToProps = state => {
	const {
		Profile
	} = state;

	return {
		...Profile
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CloseAccount);