import React from "react";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import PropTypes from "prop-types";

/**
 * @param props
 * @returns {string}
 */
function renderConnectionStatusLabel(props = {
	employer_data: {
		active: false,
	}
}): string {

	if (props.employer_data) {
		if (props.employer_data.active) {
			return props.t("individual.ae.cards.active_connection");
		}
	}

	return props.t("individual.ae.cards.inactive_label");
}

/**
 * @param props
 * @returns {string}
 */
function renderDotClassName(props = {
	employer_data: {
		active: false,
	}
}): string {
	if (props.employer_data) {
		if (props.employer_data.active) {
			return "active-icon";
		}
	}

	return "inactive-icon";
}

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const CompanyConnectionStatus = (props = {
	employer_data: {
		active: false,
	},
	t: key => key,
}): JSX.Element => (
	<Grid
		xs={12}
		sm={12}
		md={12}
		lg={12}
		item
		container
		alignItems={"center"}
		alignContent={"center"}
		direction={"column"}
		className={"employer-connection-status-holder"}
	>
		<Typography
			variant={"body1"}
		>
            <span>
                {renderConnectionStatusLabel(props)}
            </span>
			<IconButton
				aria-label="settings"
				className={renderDotClassName(props)}
				disableFocusRipple
				disableRipple
				disableTouchRipple
			>
				<FiberManualRecordIcon/>
			</IconButton>
		</Typography>
	</Grid>
);

CompanyConnectionStatus.propTypes = {
	employer_data: PropTypes.shape({
		active: PropTypes.bool,
	}).isRequired,
};

export default CompanyConnectionStatus;