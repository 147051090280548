import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Slide from "@mui/material/Slide";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {me} from "../actions/Profile";

/**
 * @param history
 * @returns {JSX.Element}
 * @constructor
 */
const EmploymentStatus = (
	{
		history = {},
	}
): JSX.Element => {
	const {t} = useTranslation();
	return (
		<Slide direction={"up"} in={true}>
			<Grid
				xs={12}
				sm={12}
				md={12}
				lg={12}
				id={"inner-active-your-pension-container"}
				container
				item
			>
				<Grid
					xs={12}
					sm={12}
					md={12}
					lg={12}
					item
				>
					<IconButton
						onClick={() => history("/main-page")}
						className={'header-close-button'}
						disableFocusRipple
						disableTouchRipple
						disableRipple
					>
						<CloseIcon/>
					</IconButton>
				</Grid>
				<Grid
					id={"body-wrapper"}
					item
				>
					<Grid
						xs={12}
						md={12}
						lg={12}
						sm={12}
						container
						item
					>
						<Grid
							xs={12}
							sm={12}
							md={12}
							lg={12}
							item
						>
							<Typography
								component={"p"}
								variant={"caption"}
								className={"information-caption"}
							>
								{t("individual.for_regulatory_purposes_we_need_your")}
								<br/>
							</Typography>

							<Typography
								component={"h1"}
								className={"information-caption"}
								variant={"caption"}
							>
								<strong>
									{t("individual.employment_status")}
								</strong>
							</Typography>
						</Grid>
					</Grid>

					<Grid
						xs={12}
						sm={12}
						md={12}
						lg={12}
						item
					>
						<Button
							variant={"outlined"}
							onClick={() => history("/employment-status")}
						>
							{t("individual.lets_do_it")}
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</Slide>
	);
};

const mapStateToProps = state => state;

const mapDispatchToProps = dispatch => ({
	me: dispatch(me()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmploymentStatus);