import React from "react";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from '@mui/material/Paper';
import moment from "moment";
import {capitalizeFirstLetter, formatMoney} from "../../utils/Helpers";

/**
 * @param data
 * @returns {string|number}
 */
function calculateContributions(data: any = {
	employer: {
		papdis: [],
	}
}): string | number {
	if (data) {

		if (data.employer) {
			if (data.employer.papdis && data.employer.papdis.length > 0) {
				return formatMoney(data?.employer?.papdis[0]?.transaction?.total_contribution ?? 0, 2);
			}
		}
	}

	return 0;
}

/**
 * @param props
 * @returns {JSX.Element}
 */
function renderFullInfo(props: any = {
	employer_data: {
		active: false,
		created_at: "",
		inactivation_reason: "",
		inactivation_date: "",
	},
	t: key => key,
}): JSX.Element {

	if (props.employer_data.active) {
		return (
			<div
				style={{
					width: "100%"
				}}
			>
				<Grid
					xs={12}
					md={12}
					lg={12}
					sm={12}
					className={"company-item-info"}
					item
					container
				>
					<Grid
						xs={6}
						md={6}
						lg={6}
						sm={6}
						className={"info"}
						item
					>
						<Typography paragraph>
							{capitalizeFirstLetter(props.t("individual.extra_contributions.monthly.title"))}
						</Typography>
					</Grid>

					<Grid
						xs={6}
						md={6}
						lg={6}
						sm={6}
						className={"value"}
						direction={"column"}
						alignContent={"flex-end"}
						alignItems={"flex-end"}
						item
						container
					>
						<Grid
							xs={6}
							md={6}
							lg={6}
							sm={6}
							item
							container
						>
							<Grid
								xs={12}
								md={12}
								lg={12}
								sm={12}
								item
							>
								<Typography paragraph>
									£ {calculateContributions(props.employer_data ?? false)}
								</Typography>
							</Grid>
						</Grid>
					</Grid>
				</Grid>

				<Grid
					xs={12}
					md={12}
					lg={12}
					sm={12}
					className={"company-item-info"}
					item
					container
				>
					<Grid
						xs={6}
						md={6}
						lg={6}
						sm={6}
						className={"info"}
						item
					>
						<Typography paragraph>
							{props.t("individual.connection_details.connected_since")}
						</Typography>
					</Grid>

					<Grid
						xs={6}
						md={6}
						lg={6}
						sm={6}
						className={"value"}
						direction={"column"}
						alignContent={"flex-end"}
						alignItems={"flex-end"}
						item
						container
					>
						<Grid
							xs={6}
							md={6}
							lg={6}
							sm={6}
							item
							container
						>
							<Grid
								xs={12}
								md={12}
								lg={12}
								sm={12}
								item
							>
								<Typography paragraph>
									{moment(props.employer_data.created_at ?? new Date()).format("D/M/Y")}
								</Typography>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</div>
		);
	} else {
		return (
			<Paper
				id={"opt-out-background-grid-cover"}
			>

				<Grid
					xs={12}
					md={12}
					lg={12}
					sm={12}
					item
					container
					direction={"column"}
					alignContent={"center"}
					alignItems={"center"}
					className={"refund-info"}
				>
					<Grid
						item
					>
						<Typography
							align={"center"}
							paragraph
							className={"red-text"}
						>
							{props?.employer_data?.inactivation_reason?.includes("Employer") ?
								'They have disconnected you from their pension contributions on ' + moment(props?.employer_data?.inactivation_date).format("DD/MM/YYYY")
								:
								props.t("individual.connection_details.you_opt_out_message", {
									date: moment(props?.employer_data?.inactivation_date).format("DD/MM/YYYY")
								})
							}
						</Typography>
					</Grid>
				</Grid>
			</Paper>
		)
	}
}

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const CompanyInformationGrid = (props = {
	code: "",
	employer_data: {
		active: false,
		created_at: "",
		inactivation_reason: "",
		inactivation_date: "",
	},
	t: key => key,
}): JSX.Element => (
	<Grid
		xs={12}
		sm={12}
		md={12}
		lg={12}
		item
		container
		id={"company-grid-container"}
	>
		<Grid
			xs={12}
			md={12}
			lg={12}
			sm={12}
			className={"company-item-info"}
			item
			container
		>
			<Grid
				xs={6}
				md={6}
				lg={6}
				sm={6}
				className={"info"}
				item
			>
				<Typography paragraph>
					{props.t("individual.connection_details.employer_id")}
				</Typography>
			</Grid>

			<Grid
				xs={6}
				md={6}
				lg={6}
				sm={6}
				className={"value"}
				direction={"column"}
				alignContent={"flex-end"}
				alignItems={"flex-end"}
				item
				container
			>
				<Grid
					xs={6}
					md={6}
					lg={6}
					sm={6}
					item
					container
				>
					<Grid
						xs={12}
						md={12}
						lg={12}
						sm={12}
						item
						className={props.employer_data.active === false ? "inactive-code" : ""}
					>
						<Typography paragraph>
							{props.code}
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		</Grid>

		{renderFullInfo(props)}

	</Grid>
);

export default CompanyInformationGrid;