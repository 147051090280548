import React, { useState} from "react";
import Wrapper from "../../layout/Logged/Wrapper";
import Grid from "@mui/material/Grid";
import {capitalizeFirstLetter, MathMonthObject, range} from "../../utils/Helpers";
import {me, myProjections} from "../../actions/Profile";
import {connect} from "react-redux";
import Button from "@mui/material/Button";
import {get} from "../../actions/SourceOfFunds";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import {NumericFormat as NumberFormat} from 'react-number-format';
import UpperInstructionCard from "../../components/UpperInstructionCard";
import {withTranslation} from "react-i18next";
import {simulation} from "../../actions/Projections";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";

/**
 * @param array
 * @param id
 * @param t
 * @returns {*|string}
 */
function findArrayElementById(array, id, t = key => key,) {
	if (id === 0)
		return t("individual.extra_contributions.new.select_source_of_funds_select_default_input");
	return (array.find((element) => {
		return element.id === id;
	})).name;
}

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const NumberFormatCustom = React.forwardRef((props, ref) => {
	const {onChange, ...other} = props;
	const step = useParams()?.step ?? "one-off";
	return (
		<NumberFormat
			{...other}
			getInputRef={ref}
			onValueChange={(values) => {
				if (typeof values.value === "function") {
					values.value = values.value.replace(/^0+/, '')
				}
				onChange({
					target: {
						name: props.name,
						value: values.floatValue,
					},
				});
			}}
			thousandSeparator={","}
			decimalSeparator={"."}
			decimalScale={2}
			isAllowed={(values) => {
				const {floatValue} = values;
				if (step === "One-off") {
					return floatValue <= 30000 || floatValue === undefined
				}
				return floatValue <= 2600 || floatValue === undefined
			}}
			prefix="£ "
			allowNegative={false}
			allowLeadingZeros={false}
			isNumericString
		/>
	);
});

/**
 * @param sourceOfFunds
 * @returns {JSX.Element}
 * @constructor
 */
const InsertValue = (
	{
		sourceOfFunds = [],
	}
): JSX.Element => {
	const [sourceOfFundName, setSourceOfFundName] = useState(""),
		[sourceOfFundId, setSourceOfFundId] = useState(0),
		[value, setValue] = useState(""),
		[dateOfPayment, setDateOfPayment] = useState(""),
		[openSelectDay, setOpenSelectDay] = useState(false),
		[openSourceOfFunds, setOpenSourceOfFunds] = useState(false),
		[manualSourceOfFund, setManualSourceOfFund] = useState(""),
		history = useNavigate(),
		step = useParams()?.step ?? "one-off",
		{t} = useTranslation();

	let today = new Date(),
		date = "Today (" + today.getDate() + " " + MathMonthObject(t)[today.getMonth()].monthFullName + " " + today.getFullYear() + ")";

	let mobileTitle = step === "Monthly" ?
		"How much would you like to transfer monthly to your Collegia Account?"
		:
		"How much do you want to transfer to your Collegia account?";
	let mobileSubtitle1 = "For security reasons, the first contribution will be made instantly via Open Banking." +
		"This way we can verify that you are the account holder.";

	return (
		<Wrapper
			hideFooter={true}
			t={t}
			history={history}
		>
			<Grid
				alignContent={"center"}
				alignItems={"center"}
				direction={"column"}
				item
				container
			>
				<UpperInstructionCard
					title={mobileTitle}
					subtitle1={mobileSubtitle1}
				/>
				<Grid
					item
					container
					id={"new-contributions"}
				>
					<Grid
						width={"100%"}
						className={"title-container"}
					>
						<Typography className={"how-much-transfer"}>
							{
								step === "Monthly" ?
									t("individual.extra_contributions.new.title_monthly")
									:
									t("individual.extra_contributions.new.title")
							}
						</Typography>
						{
							step === "Monthly" &&
							<Typography
								className={"security-reasons"}
								dangerouslySetInnerHTML={{
									__html: t("individual.extra_contributions.new.monthly.security_message")
								}}
							/>
						}

					</Grid>

					<Grid width={"100%"}>
						<FormControl
							fullWidth
							className={"text-field-style"}
						>
							<TextField
								className={value && "text-with-value"}
								placeholder={t("individual.extra_contributions.new.min_of_10_pounds", {
									value: "10,00"
								})}
								InputProps={{inputComponent: NumberFormatCustom}}
								onChange={(event) => setValue(event.target.value)}
								value={value}
							/>
						</FormControl>

						{
							step === "Monthly" ? (
									<span className={"span-min-style-max-value"}
									      dangerouslySetInnerHTML={{
											  __html: t("individual.extra_contributions.new.max_value_contribution_label", {
												  value: "2,600.00."
											  })
									      }}
									/>
								)
								:
								(
									<span className={"span-min-style-max-value"}
									      dangerouslySetInnerHTML={{
											      __html: t("individual.extra_contributions.new.max_value_contribution_label", {
												      value: "30,000.00"
											      })
										      }}
									/>
								)
						}


						<FormControl
							fullWidth
							className={"select-style"}
						>
							<Select
								IconComponent={() => ""}
								onChange={(event) => {
									setSourceOfFundId(event.target.value);
									setSourceOfFundName(findArrayElementById(sourceOfFunds, event.target.value, t));
								}}
								defaultValue={0}
								open={openSourceOfFunds}
								onClose={() => setOpenSourceOfFunds(false)}
								onOpen={() => setOpenSourceOfFunds(true)}
								endAdornment={
									<InputAdornment position={"end"}>
										<KeyboardArrowDownIcon
											className={"arrow-icon-style"}
											onClick={() => setOpenSourceOfFunds(true)}
										/>
									</InputAdornment>
								}
							>
								<MenuItem
									value={0}
								>
									{t("individual.extra_contributions.new.select_source_of_funds_select_default_input")}
								</MenuItem>
								{
									sourceOfFunds?.map((s = {
										id: 0,
										active: true,
										created_at: "",
										name: "",
										updated_at: ""
									}, index) => (
										<MenuItem
											key={index}
											value={s?.id}
										>
											{s?.name}
										</MenuItem>
									))
								}
							</Select>
						</FormControl>

						<Divider className={sourceOfFundName === "Other" ? "divider-margin" : ""}/>

						{
							sourceOfFundName === "Other" &&
							<FormControl
								fullWidth
								className={"text-field-style"}
							>
								<TextField
									className={manualSourceOfFund && "text-with-value"}
									placeholder={"Please inform your source of fund."}
									onChange={(event) => setManualSourceOfFund(event.target.value)}
									value={manualSourceOfFund}
								/>
							</FormControl>
						}

						<FormControl fullWidth>
							<span className={"span-min-style-max-value"}>
								{t("individual.extra_contributions.new.you_need_inform_collegia_message")}
							</span>
						</FormControl>

						<FormControl className={"government-top-up-value"}>
							<Typography
								className={value ? "typography-government-top-up-with-value" : "typography-government-top-up-no-value"}
							>
								{
									value ? ("£ " + (value * 0.25).toFixed(2)) : "+ 0.00"
								}
							</Typography>
						</FormControl>

						<Divider/>

						<FormControl fullWidth>
							<span className={"span-min-style-government-top-up"}>
								{capitalizeFirstLetter(t("individual.government_top_up"))}
							</span>
						</FormControl>


						{
							step === "Monthly" &&
							<Grid width={"100%"}>
								<FormControl className={"text-field-contribution-date"} fullWidth>
									<Typography className={"first-contribution-style"}>
										First contribution will happen at:
										<strong className={"strong-style"}>
											{" " + date}
										</strong>
									</Typography>
								</FormControl>

								<Divider/>

								<FormControl className={"select-style"} fullWidth>
									<Select
										onChange={(event) => setDateOfPayment(event.target.value)}
										IconComponent={() => ""}
										defaultValue={0}
										open={openSelectDay}
										MenuProps={{
											anchorOrigin: {
												vertical: "bottom",
												horizontal: "left"
											},
											menuPaper: {
												maxHeight: 200
											}
										}}
										onClose={() => setOpenSelectDay(false)}
										onOpen={() => setOpenSelectDay(true)}
										endAdornment={
											<InputAdornment position={"end"}>
												<KeyboardArrowDownIcon
													className={"arrow-icon-style"}
													onClick={() => setOpenSelectDay(true)}
												/>
											</InputAdornment>
										}
										fullWidth
									>
										<MenuItem
											value={0}
										>
											<span dangerouslySetInnerHTML={{
												__html: t("individual.extra_contributions.new.monthly.day_message")
											}}/>
										</MenuItem>
										{
											range(1, 31).map(r =>
												<MenuItem
													key={r}
													value={r}
												>
													{r}
												</MenuItem>
											)
										}
									</Select>
								</FormControl>
								<Divider/>
							</Grid>
						}
						<Grid
							xs={12}
							sm={12}
							lg={12}
							md={12}
							xl={12}
							spacing={1}
							className={"grid-button-align"}
							container
							item
						>
							<Grid
								xs={12}
								sm={12}
								lg={6}
								md={6}
								xl={6}
								item
							>
								<Button
									className={"continue-button-style"}
									disabled={
										!value ||
										value < 10 ||
										(step === "Monthly" && !dateOfPayment) ||
										!sourceOfFundName ||
										sourceOfFundId === 0 ||
										(sourceOfFundName === "Other" && manualSourceOfFund.trim() === "")
									}
									onClick={() =>
										step === "Monthly"
											? history(`/add-contribution/monthly`, {
												state: {
													step,
													contributionValue: value,
													selectedDay: dateOfPayment,
													source_of_fund_id: sourceOfFundId,
													is_other: sourceOfFundName === "Other",
													name: manualSourceOfFund,
												},
											})
											: history(`/add-contribution/one-off`, {
											state: {
												step,
												contributionValue: value,
												selectedDay: dateOfPayment,
												source_of_fund_id: sourceOfFundId,
												is_other: sourceOfFundName === "Other",
												name: manualSourceOfFund,
											},
										})
									}
									fullWidth
								>
									{t("individual.login.continue_button")}
								</Button>
							</Grid>
							<Grid
								xs={12}
								sm={12}
								lg={6}
								md={6}
								xl={6}
								item
							>
								<Button
									className={"back-button-style"}
									onClick={() => history(`/extra-contributions/`)}
									fullWidth
								>
									{t("individual.enrolled_by_employer.enter_personal_information.back_button")}
								</Button>
							</Grid>
						</Grid>

						{
							step === "Monthly" && (
								<Typography className={"note-style"}>
									<strong className={"strong-style"}>
										{"NOTE: "}
									</strong>
									For the month of February, in case you choose either the 29 or 30, the contribution will
									happen in the next working day of the month.
								</Typography>
							)
						}

					</Grid>
				</Grid>
			</Grid>
		</Wrapper>
	);
}

/**
 * @param state
 * @returns {*}
 */
const mapStateToProps = state => {
	const {
		OtherPensions = [],
		Profile = [],
		SourceOfFunds = [],
	} = state;

	return {
		...OtherPensions,
		...Profile,
		...SourceOfFunds,
	};
}

/**
 * @param dispatch
 * @returns {{myProjections: (function(): *)}}
 */
const mapDispatchToProps = dispatch => ({
	myProjections: (scrollUp: boolean = false) => dispatch(myProjections(null, scrollUp)),
	simulation: (data: any[] = false) => dispatch(simulation(data)),
	get: dispatch(get()),
	me: dispatch(me()),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(InsertValue));