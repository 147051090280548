import React, {useEffect, useState} from 'react';

import {me, updateMyAddress, updateProfilePicture} from "../../actions/Profile";
import {connect} from "react-redux";
import Wrapper from "../../layout/Logged/Wrapper";
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Slide from '@mui/material/Slide';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import {
	setDefaults,
	fromAddress,
} from "react-geocode";
import {CountryRegionData} from "react-country-region-selector";
import MenuItem from "@mui/material/MenuItem";
import Loading from "../../components/Loading";
import Typography from "@mui/material/Typography";
import HeaderInformation from "../../components/Profile/MyAccount/HeaderInformation";
import {useTranslation} from "react-i18next";
import MyAccountLeftMenu from "../../components/MyAccountLeftMenu";
import {capitalizeFirstLetter} from "../../utils/Helpers";
import {useNavigate} from "react-router-dom";

/**
 * @param address1
 * @param address2
 * @param address3
 * @param address4
 * @param country
 * @param postalCode
 * @param profile_data
 * @returns {string}
 * @private
 */
function _disable (address1, address2, address3, address4, country, postalCode, profile_data): string {

	if (
		!address1 ||
		!address2 ||
		!address4 ||
		!postalCode ||
		!country ||
		(
			address1 === profile_data?.addresses[0]?.address1 &&
			address2 === profile_data?.addresses[0]?.address2 &&
			address3 === profile_data?.addresses[0]?.address3 &&
			address4 === profile_data?.addresses[0]?.address4
		)
	) {
		return "button-2";
	}

	return "button-1";
}

/**
 * @param event
 * @param addressComponents
 * @param setAddressComponents
 */
const postCodeChange = (
	event: any = false,
	addressComponents: any = [],
	setAddressComponents = () => {
	}
) => {
	if (event && event.target && event.target.value) {
		setDefaults({
			key: process.env.REACT_APP_GEOCODE_API_KEY, // Your API key here.
			language: "en", // Default language for responses.
			region: "uk", // Default region for responses.
		});
		fromAddress(event.target.value)
			.then((response) => {
					const {
						status = "OK"
					} = response;
					if (status === "OK") {
						setAddressComponents([{
							address: response?.results[0]?.formatted_address,
							components: response?.results[0]?.address_components,
						}]);
					}
				}
			)
			.catch(error => console.error(error));
	}
};

/**
 * @param value
 * @param setPostalCode
 * @param setAddress1
 * @param setAddress2
 * @param setAddress3
 * @param setAddress4
 * @param setCountry
 * @private
 */
const optionSelected = (
	value = [],
	setPostalCode = () => {
	},
	setAddress1 = () => {
	},
	setAddress2 = () => {
	},
	setAddress3 = () => {
	},
	setAddress4 = () => {
	},
	setCountry = () => {
	}
) => {
	const postal_code = value?.components?.map(v => v.types.filter(t => t === "postal_code").length > 0 ? v?.long_name : false).filter(v => v)[0],
		country = value?.components?.map(v => v.types.filter(t => t === "country").length > 0 ? v?.short_name : false).filter(v => v)[0];

	let address1 = value?.components?.map(v => v.types.filter(t => t === "route").length > 0 ? v?.long_name : false).filter(v => v)[0];
	let address2 = value?.components?.map(v => v.types.filter(t => t === "postal_town").length > 0 ? v?.long_name : false).filter(v => v)[0];

	value?.components?.forEach((v, index) => {
		if (v) {
			if (v.types.filter(t => t === "country").length > 0) {
				delete value.components[index];
			}
		}
	});

	setPostalCode(postal_code);
	setAddress1(address1);
	setAddress2(address2);
	setAddress3("");
	setAddress4("");
	setCountry(country);
};

/**
 * @param address1
 * @param address2
 * @param address3
 * @param address4
 * @param postal_code
 * @param country
 * @param updateMyAddress
 * @private
 */
const save = (
	address1 = "",
	address2 = "",
	address3 = "",
	address4 = "",
	postal_code = "",
	country = "",
	updateMyAddress = () => {
	}
) => {
	let data = {
		profiles: {
			address1,
			address2,
			address3,
			address4,
			postal_code,
			country,
			active: true,
		}
	}, tempArray = address1.split(",");

	if (tempArray.length > 0) {
		tempArray.forEach((t, index) => {
			if (t.trim() === "undefined" || t.trim() === undefined) {
				delete tempArray[index];
			}
		})
	}

	data.profiles.address1 = tempArray.filter(el => el !== null).join(", ");

	updateMyAddress(data);
};

/**
 * @param event
 * @param addressComponents
 * @param setAddress1
 * @param setAddress2
 * @param setCountry
 * @param setAddressComponents
 * @private
 */
const onChange = (
	event: any = [],
	addressComponents = [],
	setAddress1 = () => {
	},
	setAddress2 = () => {
	},
	setCountry = () => {
	},
	setAddressComponents = () => {
	},
) => {
	if (event.target.value === "") {
		setAddress1("");
		setAddress2("");
		setCountry("GB");
		setAddressComponents([]);
	}
	postCodeChange(event, addressComponents, setAddressComponents);
}

/**
 * @param profile_data
 * @param profile_loading
 * @param updateMyAddress
 * @returns {JSX.Element}
 * @constructor
 */
const Address = (
	{
		profile_data = {
			forename: "",
			surname: "",
			email: "",
		},
		profile_loading = false,
		updateMyAddress = () => {
		},
	}
): JSX.Element => {

	const [address1, setAddress1] = useState(""),
		[address2, setAddress2] = useState(""),
		[address3, setAddress3] = useState(""),
		[address4, setAddress4] = useState(""),
		[postalCode, setPostalCode] = useState(""),
		[country, setCountry] = useState(""),
		[addressComponents, setAddressComponents] = useState([]),
		{t} = useTranslation(),
		history = useNavigate();

	const myHeight = window.innerHeight;
	const initialState = {alt: "", srcPdf: null};
	const [{src, picture}, setPreview] = useState(initialState);
	const hiddenFileInput = React.useRef(null);

	const handleUpload = e => {
		if (e.target.files && e.target.files.length > 0) {
			setPreview({
				src: e.target.files[0],
				picture: URL.createObjectURL(e.target.files[0])
			});
		}
	};

	const handleClick = () => {
		hiddenFileInput.current.click();
	};

	useEffect(() => {
		if (profile_data?.id) {
			setAddress1(profile_data?.addresses[0]?.address1);
			setAddress2(profile_data?.addresses[0]?.address2);
			setAddress3(profile_data?.addresses[0]?.address3);
			setAddress4(profile_data?.addresses[0]?.address4);
			setPostalCode(profile_data?.addresses[0]?.postal_code);
			setCountry(profile_data?.addresses[0]?.country);
		}

	}, [profile_data?.addresses, profile_data?.id]);
	if (profile_loading) {
		return (
			<Wrapper hideFooter={true} t={t}>
				<Slide in={true} direction={"up"}>
					<div>
						<Loading absolute/>
					</div>
				</Slide>
			</Wrapper>
		);
	}

	return (
		<Wrapper
			hideFooter={true}
			history={history}
			t={t}
			textHeader={"Address"}
		>
			<Grid
				xl={12}
				lg={12}
				md={12}
				item
				direction={"row"}
				container
				className={"grid"}
			>
				<MyAccountLeftMenu
					history={history}
					currentPage={"Address"}
					t={t}
					capitalizeFirstLetter={capitalizeFirstLetter}
					myHeight={myHeight}
				/>
				<Grid
					xl={9.5}
					lg={9.5}
					md={12}
					sm={12}
					xs={12}
					item
					direction={"row"}
					container
					justifyContent={"center"}
					maxHeight={"750px"}
				>
					<Grid
						xl={9}
						lg={9}
						md={9}
						sm={9}
						xs={11}
						item
						justifyContent={"center"}
						direction={"row"}
						container
					>
						<HeaderInformation
							profile_data={profile_data}
							src={src}
							picture={picture}
							hiddenFileInput={hiddenFileInput}
							setPreview={setPreview}
							handleClick={handleClick}
							handleUpload={handleUpload}
							updateProfilePicture={updateProfilePicture}
							t={t}
						/>
						<Grid
							xl={12}
							lg={12}
							md={12}
							sm={12}
							xs={12}
							className={"grid-text"}
							item
						>
							<Typography
								className={"label"}
							>
								{t("individual.my_account.address.type_your_postal_code_input")}
							</Typography>
							<FormControl
								fullWidth
							>
								<Autocomplete
									getOptionLabel={addressComponents => addressComponents.address ?? ""}
									options={addressComponents}
									onChange={(event, value) =>
										optionSelected(
											value,
											setPostalCode,
											setAddress1,
											setAddress2,
											setAddress3,
											setAddress4,
											setCountry
										)
									}
									renderInput={(params) => (
										<TextField
											{...params}
											label={t("individual.my_account.address.type_your_postal_code_input")}
											margin={"normal"}
											variant={"outlined"}
											className={"address"}
											onChange={event =>
												onChange(
													event,
													addressComponents,
													setAddress1,
													setAddress2,
													setCountry,
													setAddressComponents
												)
											}
										/>
									)}
									freeSolo
									disableClearable
								/>
							</FormControl>
						</Grid>
						<Grid
							xl={6}
							lg={6}
							md={6}
							sm={6}
							xs={12}
							className={"grid-text"}
							item
						>
							<Typography
								className={"label"}
							>
								{t("individual.my_account.address.postal_code_input")}
							</Typography>
							<TextField
								className={"textfield"}
								value={postalCode}
								onChange={(e) => setPostalCode(e.target.value)}
								fullWidth
							/>
						</Grid>
						<Grid
							xl={6}
							lg={6}
							md={6}
							sm={6}
							xs={12}
							className={"grid-text"}
							item
						>
							<Typography
								className={"label"}
							>
								{t("individual.my_account.address.address_line_1_input")}
							</Typography>
							<TextField
								id={"gender"}
								className={"textfield"}
								value={address1}
								fullWidth
								onChange={event => setAddress1(event.target.value)}
							/>

						</Grid>
						<Grid
							xl={6}
							lg={6}
							md={6}
							sm={6}
							xs={12}
							className={"grid-text"}
							item
						>
							<Typography
								className={"label"}
							>
								{t("individual.my_account.address.house_flat_number_input")}
							</Typography>
							<TextField
								className={"textfield"}
								value={address4}
								onChange={event => setAddress4(event.target.value)}
								fullWidth
							/>
						</Grid>
						<Grid
							xl={6}
							lg={6}
							md={6}
							sm={6}
							xs={12}
							className={"grid-text"}
							item
						>
							<Typography
								className={"label"}
							>
								{t("individual.my_account.address.town_city_input")}
							</Typography>
							<TextField
								className={"textfield"}
								value={address2}
								onChange={event => setAddress2(event.target.value)}
								fullWidth
							/>
						</Grid>
						<Grid
							xl={6}
							lg={6}
							md={6}
							sm={6}
							xs={12}
							className={"grid-text"}
							item
						>
							<Typography
								className={"label"}
							>
								{t("individual.my_account.address.address_line_3")}
							</Typography>
							<TextField
								className={"textfield"}
								value={address3}
								onChange={event => setAddress3(event.target.value)}
								fullWidth
							/>
						</Grid>
						<Grid
							xl={6}
							lg={6}
							md={6}
							sm={6}
							xs={12}
							className={"grid-text"}
							item
						>
							<Typography
								className={"label"}
							>
								{capitalizeFirstLetter(t("individual.my_account.beneficiaries.country"))}
							</Typography>
							<TextField
								className={"textfield"}
								value={country}
								onChange={event => setCountry(event.target.value)}
								disabled
								select
								fullWidth
							>
								{
									CountryRegionData.map((option, index) => (
										<MenuItem key={index} value={option[1]}>
											{option[0]}
										</MenuItem>
									))
								}
							</TextField>
						</Grid>
						<Grid
							xl={6}
							lg={6}
							md={6}
							sm={6}
							xs={12}
							className={"grid-text"}
							item
							onClick={() => {
								_disable(address1, address2, address3, address4, country, postalCode, profile_data) === "button-1" &&
								save(
									address1,
									address2,
									address3,
									address4,
									postalCode,
									country,
									updateMyAddress
								);
							}}
						>
							<Typography
								className={_disable(address1, address2, address3, address4, country, postalCode, profile_data)}
								textAlign={"center"}
							>
								{t("individual.my_account.address.change_my_address")}
							</Typography>
						</Grid>
						<Grid
							xl={6}
							lg={6}
							md={6}
							sm={6}
							xs={12}
							item
							onClick={() => {
								_disable(address1, address2, address3, address4, country, postalCode, profile_data) === "button-1" &&
								setAddress1(profile_data?.addresses[0]?.address1);
								setAddress2(profile_data?.addresses[0]?.address2);
								setAddress3(profile_data?.addresses[0]?.address3);
								setAddress4(profile_data?.addresses[0]?.address4);
								setPostalCode(profile_data?.addresses[0]?.postal_code);
								setCountry(profile_data?.addresses[0]?.country);
							}}
						>
							<Typography
								className={_disable(address1, address2, address3, address4, country, postalCode, profile_data)}
								textAlign={"center"}
							>
								{t("individual.discard_changes")}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Wrapper>
	);
}

const mapStateToProps = state => state.Profile;

/**
 * @param dispatch
 * @returns {{me: (function(): *), updateProfilePicture: (function(*[]=): *)}}
 */
const mapDispatchToProps = dispatch => ({
	me: dispatch(me()),
	updateProfilePicture: (data: any[] = false) => dispatch(updateProfilePicture(data)),
	updateMyAddress: (data: any[] = false) => dispatch(updateMyAddress(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Address)