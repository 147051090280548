import React, {useEffect} from "react";
import {connect} from "react-redux";
import Grid from "@mui/material/Grid";
import {me} from "../actions/Profile";
import Paper from "@mui/material/Paper";
import Slide from "@mui/material/Slide";
import Wrapper from "../layout/Logged/Wrapper";
import CircleIcon from "@mui/icons-material/Circle";
import {Cell, Pie, PieChart} from 'recharts';
import {getAssetsLocation} from "../actions/AssetsLocation";
import Loading from "../components/Loading";
import {useTranslation} from "react-i18next";
import Typography from "@mui/material/Typography";
import SideMenu from "../components/CollegiaAccount/SideMenu";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import {useNavigate} from "react-router-dom";

/**
 * @returns {JSX.Element}
 * @constructor
 * @param percent
 */
const renderPercent = (percent) => {
	let percentString = (Math.round(percent * 100) / 100).toFixed(1).toString();
	return (
		<Typography align={"right"}>
			<strong>
				{percentString + " %"}
			</strong>
		</Typography>
	)
}

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const Portfolio = (props = {}): JSX.Element => {
	const {
			profile_data,
			profile_loading,
			getAssetsLocation,
			assets_location,
			assets_location_loading,
		} = props,
		{t} = useTranslation(),
		history = useNavigate();

	useEffect(() => {
		if (profile_data?.individual_investment_approach?.id) {
			getAssetsLocation(profile_data.individual_investment_approach.id);
		}
	}, [
		profile_loading,
		profile_data,
		props?.profile_data?.individual_investment_approach?.id,
		getAssetsLocation,
	]);
	if (profile_loading || assets_location_loading) {
		return <Loading absolute/>
	}

	return (
		<Wrapper
			profile_data={profile_data}
			hideFooter={true}
			t={t}
			history={history}
		>
			<Slide in={true} direction={"up"}>
				<Grid
					xs={12}
					lg={12}
					md={12}
					sm={12}
					direction={"row"}
					container
					item
				>
					<SideMenu
						history={history}
						t={t}
						portfolio={""}
					/>
					<Grid
						xs={12}
						sm={12}
						md={12}
						lg={9.5}
						xl={9.5}
						direction={"column"}
						id={"contribution-history-style"}
						container
						item
						alignItems={"center"}
						alignContent={"center"}
					>
						<Paper
							id={"contribution-history-paper-holder"}
						>
							<Typography
								fontSize={25}
								textAlign={"left"}
							>
								{t("individual.collegia_account.inner_menu.portfolio")}
							</Typography>
							{
								assets_location?.length > 0 ?
									<Grid>
										<Typography>
											{"Your current investment is"}
										</Typography>
										<Typography>
											{profile_data?.fund?.name}
										</Typography>
										<Grid
											alignContent={"center"}
											alignItems={"center"}
											direction={"column"}
											item
											container
										>
											<PieChart
												width={350}
												height={350}
											>
												<text
													x={175}
													y={165}
													className={"text-pie-recharts"}
												>
													{t("individual.collegia.portfolio.actual_asset")}
												</text>
												<text
													x={175}
													y={200}
													className={"text-pie-recharts"}
												>
													{t("individual.collegia.portfolio.oct_dec")}
												</text>
												<Pie
													dataKey="value"
													data={assets_location}
													cx="50%"
													cy="50%"
													isAnimationActive={true}
													innerRadius={110}
													outerRadius={160}
													fill="#82ca9d"
												>
													{
														assets_location.map((entry, index) =>
															<Cell
																key={index}
																fill={
																	assets_location[index].color
																}
															/>
														)
													}
												</Pie>
											</PieChart>
										</Grid>
										<Grid
											direction={"column"}
											alignItems={"center"}
											alignContent={"flex-start"}
											container
											item
											marginTop={"4%"}
											marginBottom={"4%"}
										>
											<span
												className={"li-text-style"}
											>
												{t("individual.collegia.portfolio.source", {
													source: "AllianceBernstein",
												})}
											</span>
										</Grid>

										<Grid
											id={"assets-location"}
											xs={12}
											sm={12}
											lg={12}
											md={12}
											direction={"row"}
											alignItems={"center"}
											justifyContent={"space-between"}
											item
											container
										>
											<div
												className={"table"}
											>
												{
													assets_location.map((entry, index) =>

														<Card
															key={index}
															className={"table-row"}
														>
															<CardHeader
																className={"table-cell circle-cell"}
																action={
																	<CircleIcon
																		htmlColor={assets_location[index].color}
																		className={"circle-class-style"}
																	/>
																}
															/>
															<CardContent className={"table-cell"}>
																<Grid
																	xs={12}
																	sm={12}
																	lg={12}
																	xl={12}
																	md={12}
																	item
																	justifyContent={"space-between"}
																	container
																>
																	{assets_location[index]?.name}
																	{renderPercent(
																		assets_location[index]?.value
																	)}
																</Grid>
															</CardContent>
														</Card>
													)
												}
											</div>
										</Grid>
									</Grid>
									:
									<Grid
										alignContent={"center"}
										alignItems={"center"}
										direction={"column"}
										item
										container
										padding={5}
									>
										<h3>
											{t("individual.collegia.portfolio.you_dont_have_funds")}
										</h3>
									</Grid>
							}
						</Paper>
					</Grid>
				</Grid>
			</Slide>
		</Wrapper>
	);
}

const mapStateToProps = state => {
	const {
		Profile,
		AssetsLocation,
	} = state;

	return {
		...Profile,
		...AssetsLocation,
	};
};

const mapDispatchToProps = dispatch => ({
	me: dispatch(me()),
	getAssetsLocation: (id: number = 0) => dispatch(getAssetsLocation(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Portfolio);
