import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {retrieveCollegiaAccessToken} from "../../utils/Helpers";
import Grid from '@mui/material/Grid';
import Wrapper from "../../layout/Logged/Wrapper";
// import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {me, setConfirmCodeToFalse, updateProfilePicture} from "../../actions/Profile";
import HeaderInformation from "../../components/Profile/MyAccount/HeaderInformation";
import Loading from "../../components/Loading";
import Typography from "@mui/material/Typography";
import Person from "../../images/main-menu/Personal.svg"
import Beneficiaries from "../../images/main-menu/Beneficiaries.svg";
import Address from "../../images/main-menu/localization.svg";
import Relief from "../../images/main-menu/Tax relief.svg";
import Details from "../../images/main-menu/Details.svg";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

// const _renderCloseAccountButton = (
// 	profile_data: any[] = {
// 		canCloseAccount: false,
// 	},
// 	t = key => key,
// ) => {
// 	if (profile_data) {
// 		if (profile_data.canCloseAccount) {
// 			return (
// 				<Grid
// 					xs={12}
// 					md={12}
// 					lg={12}
// 					sm={12}
// 					item
// 					container
// 					className={"base-content"}
// 					onClick={() => this.props.history("/close-account")}
// 				>
// 					<Grid
// 						xs={6}
// 						md={6}
// 						lg={6}
// 						sm={6}
// 						item
// 						container
// 						alignContent={"flex-start"}
// 						alignItems={"flex-start"}
// 						direction={"column"}
// 						className={"close-account"}
// 					>
//                         <span>
// 	                        {t("individual.my_account.menu.close_account")}
//                         </span>
// 					</Grid>
// 					<Grid
// 						xs={6}
// 						md={6}
// 						lg={6}
// 						sm={6}
// 						item
// 						container
// 						alignContent={"flex-end"}
// 						alignItems={"flex-end"}
// 						direction={"column"}
// 					>
// 						<KeyboardArrowRightIcon/>
// 					</Grid>
// 				</Grid>
// 			);
// 		}
// 	}
//
// 	return [];
// };

/**
 * @param profile_data
 * @param profile_loading
 * @param updateProfilePicture
 * @param setConfirmCodeToFalse
 * @returns {JSX.Element}
 * @constructor
 */
const MyAccount = (
	{
		profile_data = [],
		profile_loading = false,
		updateProfilePicture = () => {
		},
		setConfirmCodeToFalse = () => {
		},
	}
): JSX.Element => {
	const {t} = useTranslation();
	useEffect(() => {
		setConfirmCodeToFalse();
	}, [
		setConfirmCodeToFalse
	]);
	const history = useNavigate();
	const initialState = {alt: "", srcPdf: null};
	const [{src, picture}, setPreview] = useState(initialState);
	const hiddenFileInput = React.useRef(null);

	const handleUpload = e => {
		if (e.target.files && e.target.files.length > 0) {
			setPreview({
				src: e.target.files[0],
				picture: URL.createObjectURL(e.target.files[0])
			});
		}
	};

	const handleClick = () => {
		hiddenFileInput.current.click();
	};

	if (!retrieveCollegiaAccessToken()) {
		window.location = "/";
	}

	if (profile_loading) {
		return (
			<Loading absolute/>
		);
	}

	return (
		<Wrapper
			history={history}
			hideFooter={true}
			t={t}
		>
			<Grid
				md={12}
				sm={12}
				item
				container
				justifyContent={"center"}
				id={"my-account"}
			>
				<HeaderInformation
					profile_data={profile_data}
					src={src}
					picture={picture}
					hiddenFileInput={hiddenFileInput}
					setPreview={setPreview}
					handleClick={handleClick}
					handleUpload={handleUpload}
					updateProfilePicture={updateProfilePicture}
					t={t}
				/>
				<Grid
					className={"grid"}
					md={11}
					xs={11}
					item
					onClick={() => history("/my-account/personal-information")}
				>
					<Typography
						className={"text"}
					>
						<img
							src={Person}
							alt={"Person"}
							className={"img"}
						/>
						Personal Information
					</Typography>
				</Grid>
				<Grid
					className={"grid"}
					md={11}
					xs={11}
					item
					onClick={() => history("/my-account/address")}
				>
					<Typography
						className={"text"}
					>
						<img
							src={Address}
							alt={"Address"}
							className={"img"}
						/>
						Address
					</Typography>
				</Grid>
				<Grid
					className={"grid"}
					md={11}
					xs={11}
					item
					onClick={() => history("/my-account/login-details")}
				>
					<Typography
						className={"text"}
					>
						<img
							src={Details}
							alt={"Details"}
							className={"img"}
						/>
						{t("individual.my_account.menu.personal_information")}
					</Typography>
				</Grid>
				<Grid
					className={"grid"}
					md={11}
					xs={11}
					item
					onClick={() => history("/my-account/beneficiaries")}
				>
					<Typography
						className={"text"}
					>
						<img
							src={Beneficiaries}
							alt={"Beneficiaries"}
							className={"img"}
						/>
						Beneficiaries
					</Typography>
				</Grid>
				<Grid
					className={"grid"}
					md={11}
					xs={11}
					item
					onClick={() => window.$crisp.push(['do', 'chat:open'])}
				>
					<Typography
						className={"text"}
					>
						<img
							src={Relief}
							alt={"Relief"}
							className={"img"}
						/>
						Tax relief
					</Typography>
				</Grid>
			</Grid>
		</Wrapper>
	);
}

const mapStateToProps = state => state.Profile;

/**
 * @param dispatch
 * @returns {{me: (function(): *), updateProfilePicture: (function(*[]=): *)}}
 */
const mapDispatchToProps = dispatch => ({
	me: dispatch(me()),
	updateProfilePicture: (data: any[] = false) => dispatch(updateProfilePicture(data)),
	setConfirmCodeToFalse: () => dispatch(setConfirmCodeToFalse()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyAccount)