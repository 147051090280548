import React, {useEffect, useState} from "react";

import Wrapper from "../../layout/Logged/Wrapper";
import Grid from '@mui/material/Grid';
import {employerInfo, optOut} from "../../actions/Employers";
import {connect} from "react-redux";
import {retrieveCollegiaAccessToken} from "../../utils/Helpers";
import SecondStep from "../OptOutEmployer/SecondStep";
import {me, myProjections} from "../../actions/Profile";
import Loading from "../../components/Loading";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";

/**
 * @param currentStep
 * @param code
 * @param profile_data
 * @param employer_data
 * @param my_projections
 * @param setCurrentStep
 * @param optOut
 * @param history
 * @param t
 * @returns {JSX.Element|string}
 */
const renderAccordinglyWithStep = (
	currentStep: number = 0,
	code: number = 0,
	profile_data: any = [],
	employer_data = {},
	my_projections: any = [],
	setCurrentStep = () => {
	},
	optOut = () => {
	},
	history = () => {
	},
	t = key => key
) => {
	switch (currentStep) {
		case 1:
			return (
				<SecondStep
					code={code}
					employer_data={employer_data}
					my_projections={my_projections}
					optOut={optOut}
					profile_data={profile_data}
					history={history}
					insideApp={true}
					t={t}
				/>
			);
		default:
			return "";
	}
}

/**
 * @param employer_loading
 * @param profile_data
 * @param employer_data
 * @param my_projections
 * @param match
 * @param employerInfo
 * @param myProjections
 * @param optOut
 * @param history
 * @returns {JSX.Element}
 * @constructor
 */
const OptOutEmployer = (
	{
		employer_loading = false,
		profile_data = [],
		employer_data = {},
		my_projections = [],
		employerInfo = () => {
		},
		myProjections = () => {
		},
		optOut = () => {
		},
	}
): JSX.Element => {

	const [currentStep, setCurrentStep] = useState(1),
		code = useParams().code,
		{t} = useTranslation(),
		history = useNavigate();

	useEffect(() => {
		if (code) {
			employerInfo(code);
			myProjections(code);
		}
	}, [
		code,
		employerInfo,
		myProjections
	]);

	if (employer_loading) {
		return (
			<Loading absolute/>
		);
	}

	if (!code) {
		window.location = "/auto-enrolment";
	}

	if (!retrieveCollegiaAccessToken()) {
		window.location = "/";
	}

	return (
		<Wrapper
			history={history}
			hideFooter={true}
			t={t}
		>
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				direction={"row"}
				justifyContent={"center"}
				id={"opt-out-inside-app-container"}
				container
				item
			>
				{
					renderAccordinglyWithStep(
						currentStep,
						code,
						profile_data,
						employer_data,
						my_projections,
						setCurrentStep,
						optOut,
						history,
						t
					)
				}
			</Grid>
		</Wrapper>
	)
}


const mapStateToProps = state => {
	const {
		Employers = [],
		Profile = [],
	} = state;

	return {
		...Employers,
		...Profile,
	};
};

const mapDispatchToProps = dispatch => ({
	employerInfo: (code: string = "") => dispatch(employerInfo(code)),
	myProjections: (employerId: mixed = null) => dispatch(myProjections(employerId)),
	optOut: (data: any = false) => dispatch(optOut(data)),
	me: dispatch(me()),
});

export default connect(mapStateToProps, mapDispatchToProps)(OptOutEmployer);