import React from "react";

import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Slide from '@mui/material/Slide';
import Grid from '@mui/material/Grid';
import Wrapper from "../../layout/Logged/Wrapper";
import OtherPensionsImageHeader from "../../components/OtherPensions/OtherPensionsImageHeader";
import {me, myProjections} from "../../actions/Profile";
import {connect} from "react-redux";
import ExpectedPensionIncomeCard from "../../components/YourPension/AdjustRetirementAge/ExpectedPensionIncomeCard";
import {erase, get} from "../../actions/OtherPensions";
import TextHolder from "../../components/OtherPensions/TextHolder";
import AddNewPensionLinkButton from "../../components/OtherPensions/AddNewPensionLinkButton";
import OtherPensionsCardList from "../../components/OtherPensions/OtherPensionsCardList";
import {PrimaryHeader} from "../../components/Headers/PrimaryHeader";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import ReportOutlinedIcon from "@mui/icons-material/ReportOutlined";
import {useTranslation} from "react-i18next";

/**
 * @param other_pensions
 * @param erase
 * @param history
 * @param profile_data
 * @param t
 * @returns {JSX.Element}
 */
const renderContent = (
	other_pensions: any[] = [],
	erase = () => {
	},
	history = () => {
	},
	profile_data = {},
	t = key => key,
): JSX.Element => {
	if (other_pensions.length > 0) {
		return (
			<Paper
				className={"paper-holder-list"}
			>
				{_renderRequestEmploymentPastDataCard(profile_data, history)}
				<AddNewPensionLinkButton
					history={history}
					t={t}
				/>
				<Typography
					className={"title"}
					paragraph
				>
					{t("individual.recently_added_in_projections")}
				</Typography>
				{
					other_pensions.map(pension =>
						<OtherPensionsCardList
							key={pension.id}
							pension={pension}
							erase={erase}
							t={t}
						/>
					)
				}
			</Paper>
		);
	}
	return (
		<Paper
			className={"paper-holder"}
		>
			{_renderRequestEmploymentPastDataCard(profile_data, history)}
			<OtherPensionsImageHeader/>
			<TextHolder t={t}/>
			<AddNewPensionLinkButton
				history={history}
				t={t}
			/>
		</Paper>
	);
}

const _renderRequestEmploymentPastDataCard = (profile_data = {}, history = {push: (url) => url}) => {

	if (process.env.REACT_APP_ENV === "production") return [];

	if (!profile_data.previously_hmrc_employment_info || !profile_data.utr) {
		return (
			<Grid
				xs={12}
				md={12}
				lg={12}
				xl={12}
				sm={12}
				onClick={() => history("/allow-hmrc-past-employers-info")}
				item
			>
				<Card
					id={"employment-history-card-container"}
				>
					<CardHeader
						title={"Previously employers"}
						subheader={"Let Collegia access your past employments"}
					/>
					<CardActions>
						<Grid
							xs={12}
							sm={12}
							md={12}
							lg={12}
							container
							item
						>
							<Grid
								xs={6}
								sm={6}
								md={6}
								lg={6}
								alignContent={"flex-start"}
								direction={"column"}
								item
								container
							>
								<Button className={"MuiCardHeader-button"}>LET'S DO IT</Button>
							</Grid>
							<Grid
								xs={6}
								sm={6}
								md={6}
								lg={6}
								alignContent={"flex-end"}
								direction={"column"}
								item
								container
							>
								<IconButton
									className={"MuiCardHeader-icon-button"}
									aria-label="INFORM TO COLLEGIA YOUR EMPLOYMENT HISTORY"
									disableFocusRipple
									disableTouchRipple
									disableRipple
								>
									<ReportOutlinedIcon/>
								</IconButton>
							</Grid>
						</Grid>
					</CardActions>
				</Card>
			</Grid>
		)
	}

	return [];
}

/**
 * @param other_pensions
 * @param my_projections
 * @param erase
 * @param history
 * @param profile_data
 * @returns {JSX.Element}
 * @constructor
 */
const OtherPensionsList = (
	{
		other_pensions = [],
		my_projections = false,
		erase = () => {
		},
		history = () => {
		},
		profile_data = {},
	}
): JSX.Element => {
	let progress_level_classname = false,
		badge = false,
		ruler_progress_classname = false;
	const {t} = useTranslation();

	if (my_projections !== undefined) {
		progress_level_classname = my_projections.progress_level_classname;
		ruler_progress_classname = my_projections.ruler_progress_classname;
		badge = my_projections.badge;
	}

	return (
		<Wrapper t={t}>
			<Slide in={true} direction={"left"}>
				<div>
					<Grid
						direction={"column"}
						alignItems={"center"}
						alignContent={"center"}
						id={"other-pensions-wrapper"}
						container
						item
					>
						<PrimaryHeader
							history={history}
							backPage={"/your-pension"}
							backIcon
						>
							{t("individual.other_pensions.title")}
						</PrimaryHeader>
						{
							renderContent(
								other_pensions,
								erase,
								history,
								profile_data,
								t,
							)
						}
						<ExpectedPensionIncomeCard
							progressLevelClassName={progress_level_classname ?? false}
							badge={badge ?? false}
							rulerProgressClassName={ruler_progress_classname ?? false}
							totalYearlyIncome={my_projections.total_yearly_income ?? 0}
							retirementAge={67}
							progress={my_projections.progress ?? 0}
							currentProgress={my_projections.progress ?? 0}
							t={t}
							aliasClass
						/>
					</Grid>
				</div>
			</Slide>
		</Wrapper>
	);
}


/**
 * @param state
 * @returns {*}
 */
const mapStateToProps = state => {
	const {
		OtherPensions = [],
		Profile = [],
	} = state;

	return {
		...OtherPensions,
		...Profile,
	};
}

/**
 * @param dispatch
 * @returns {{myProjections: (function(): *)}}
 */
const mapDispatchToProps = dispatch => ({
	myProjections: dispatch(myProjections(null, true)),
	me: dispatch(me()),
	get: dispatch(get()),
	erase: (id: any = false) => dispatch(erase(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OtherPensionsList);