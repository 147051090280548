import React, {useEffect} from "react";
import Grid from "@mui/material/Grid";
import Wrapper from "../layout/Logged/Wrapper";
import {PrimaryHeader} from "../components/Headers/PrimaryHeader";
import Paper from "@mui/material/Paper";
import Slide from "@mui/material/Slide";
import {me, myProjections} from "../actions/Profile";
import {connect} from "react-redux";
import ContributionImage from "../images/Contribution.svg";
import PortfolioImage from "../images/Portfolio.svg";
import HistoryImage from "../images/History.svg";
import CalendarImage from "../images/CalendarIcon.svg";
import MenuCards from "../components/MyCollegiaAccount/MenuCards";
import {retrieveGenericProjectionParameters} from "../actions/GenericProjectionParemeters";
import {toggleIncomePot} from "../actions/PotToggle";
import {get} from "../actions/InvestmentApproach";
import {useTranslation} from "react-i18next";

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const MyCollegiaAccount = (props = {
	history: () => {
	},
}): JSX.Element => {
	const {profile_data, profile_loading, me} = props,
		{t} = useTranslation();

	useEffect(() => {
		if (!profile_data && !profile_loading) {
			retrieveGenericProjectionParameters();
			props.getInvestmentApproach(true)
		}
	}, [
		me,
		profile_loading,
		profile_data,
		props,
	]);

	const options = [
		{
			text: t("individual.collegia_account.inner_menu.contributions"),
			link: '/collegia-contribution',
			image: ContributionImage,
			alt: ""
		},
		{
			text: t("individual.collegia_account.inner_menu.portfolio"),
			link: '/portfolio',
			image: PortfolioImage,
			alt: ""
		},
		{
			text: t("individual.software_connections.cards.list.contributions_history").replace(".", ""),
			link: '/contributions-history',
			image: HistoryImage,
			alt: "",
		},
		{
			text: t("individual.collegia_account.inner_menu.upcoming_contributions"),
			link: '/upcoming-contributions',
			image: CalendarImage,
			alt: "",
		},
	];

	return (
		<Wrapper
			history={props?.history}
			profile_data={profile_data}
			hideFooter={true}
			t={t}
		>
			<Slide in={true} direction={"up"}>
				<div>
					<Grid
						direction={"column"}
						alignItems={"center"}
						alignContent={"center"}
						id={"address-container"}
						container
						item
					>
						<PrimaryHeader
							history={props.history}
							backIcon
							backPage={"/"}
						>
							{t("individual.collegia_account.title")}
						</PrimaryHeader>
						<Paper
							id={"paper-holder"}
						>
							<Grid
								item
								container
							>
								{
									options.filter((v) => !v.hidden)
										.map(({text, link, critical = false, image, alt}, index) =>
											<MenuCards
												key={`link-${index}`}
												history={props?.history}
												link={link}
												text={text}
												image={image}
												alt={alt}
												imageMargin={true}
											/>
										)
								}
							</Grid>
						</Paper>
					</Grid>
				</div>
			</Slide>
		</Wrapper>
	);
}


export default connect(
	({Profile = {}}) => ({...Profile}),
	dispatch => ({
		me: dispatch(me()),
		retrieveGenericProjectionParameters: () => dispatch(retrieveGenericProjectionParameters(false)),
		toggleIncomePot: (toggle = true) => dispatch(toggleIncomePot(toggle)),
		getInvestmentApproach: (scrollUp: boolean = false) => dispatch(get(scrollUp)),
		myProjections: () => dispatch(myProjections()),
	})
)(MyCollegiaAccount);