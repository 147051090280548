import React from "react";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import MobileInput from "../Home/SetUpPage/MobileNumber/MobileInput";
import {isValidPhoneNumber} from "libphonenumber-js";
import TextField from "@mui/material/TextField";

/**
 * @param value
 * @param code
 * @param setCode
 * @returns {JSX.Element|*[]}
 * @private
 */
const onlyNumber = (
	value: string = "",
	code: string = "",
	setCode: Function = () => {
	}
) => {
	if (!isNaN(value)) {
		setCode(value);
	} else {
		setCode(code);
	}
}

/**
 * @returns {boolean}
 * @private
 * @param mobileNumber
 * @param profile_data
 * @param update
 * @param generateAutoSignUpToken
 */
const _executeDefaultMobileChangeNumber = (
	mobileNumber = null,
	profile_data = {
		id: null,
	},
	update = () => {
	},
	generateAutoSignUpToken = () => {
	},
): void => {
	update(profile_data?.id, {
		profiles: {
			mobile: mobileNumber
		}
	});
	generateAutoSignUpToken({
		activation_tokens: {
			individual_id: profile_data?.id,
			mobile_number: mobileNumber,
		}
	});
}

/**
 * @param profile_data
 * @param mobileNumber
 * @param code
 * @param stepErrorText
 * @param token
 * @param errorMessage
 * @param setMobileNumber
 * @param setCode
 * @param setStepErrorText
 * @param update
 * @param generateAutoSignUpToken
 * @param confirmMobileCode
 * @param t
 * @returns {JSX.Element}
 * @constructor
 */
const MobileForm = (
	{
		profile_data = [],
		mobileNumber = "",
		code = "",
		stepErrorText = "",
		token = "",
		errorMessage = "",
		setMobileNumber = () => {
		},
		setCode = () => {
		},
		setStepErrorText = () => {
		},
		update = () => {
		},
		generateAutoSignUpToken = () => {
		},
		confirmMobileCode = () => {
		},
		t = key => key,
	}
): JSX.Element => {
	return (
		profile_data?.mobile && profile_data?.mobile !== "N/A" ?
			<Grid
				xl={12}
				lg={12}
				md={12}
				container
				item
				direction={"row"}
				justifyContent={"center"}
				id={"change-email-wrapper"}
			>
				<Grid
					xl={9.5}
					lg={9.5}
					md={12}
					sm={12}
					xs={12}
					item
					direction={"row"}
					container
					justifyContent={"center"}
					className={"grid"}
				>
					<Grid
						xl={6.55}
						lg={7.2}
						md={8}
						sm={12}
						xs={12}
						item
						container
						direction={"row"}
						display={"inline-block"}
						textAlign={"-webkit-center"}
					>
						<Typography
							className={"title"}
						>
							{t("individual.my_account.login_details.verify_account.title")}
						</Typography>
						<Typography
							className={"subtitle"}
							dangerouslySetInnerHTML={{
								__html: t("individual.confirm_your_mobile.form.security_reasons", {
									complement_message: `<small>: 
									${(profile_data?.mobile) ?? ""}</small>`
								})
							}}
						/>
						<Grid
							xl={8.2}
							lg={8.2}
							md={8.2}
							sm={6}
							xs={12}
							item
							container
						>
							<Typography
								className={"label-code"}
							>
								{t("individual.code_sent_by_sms")}
							</Typography>
							<TextField
								id={"code"}
								placeholder={"code-sent-by-sms"}
								className={"textfield2"}
								onChange={event => onlyNumber(event.target.value, code, setCode)}
								value={code}
								fullWidth
							/>
							<Fade in={errorMessage !== "" ?? false}>
								<small className={'collegia-text-danger default-error'}>
									{errorMessage}
								</small>
							</Fade>
							<Typography
								className={"label-send-again"}
							>
								{t("individual.did_not_receive_the_code")}
								<br/>
								<span
									onClick={() =>
										generateAutoSignUpToken({
												activation_tokens: {
													individual_id: profile_data?.id,
													mobile_number: mobileNumber,
												}
											}
										)
									}
								>
									{t("individual.send_again")}
									&nbsp;
								</span>
								Or
								<span
									onClick={() =>
										update(profile_data?.id, {
												profiles: {
													mobile: null,
													check_nin: false,
												}
											}
										)
									}
								>
									&nbsp;
									{t("individual.change_my_mobile_number")}
								</span>
							</Typography>
							<Button
								fullWidth
								onClick={() =>
									confirmMobileCode(token, {
										steps: {
											code: code,
											autoSignUp: true,
										}
									}, "/main-page")
								}
								disabled={!code}
							>
								{t("individual.login.continue_button")}
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			:
			<Grid
				xl={12}
				lg={12}
				md={12}
				container
				item
				direction={"row"}
				justifyContent={"center"}
				id={"change-email-wrapper"}
			>
				<Grid
					xl={9.5}
					lg={9.5}
					md={12}
					sm={12}
					xs={12}
					item
					direction={"row"}
					container
					justifyContent={"center"}
					className={"grid"}
				>
					<Grid
						xl={6.55}
						lg={7.2}
						md={8}
						sm={12}
						xs={12}
						item
						container
						direction={"row"}
						display={"inline-block"}
						textAlign={"-webkit-center"}
					>
						<Typography
							className={"title"}
						>
							{t("individual.my_account.login_details.verify_account.title")}
						</Typography>
						<Typography
							className={"subtitle"}
							dangerouslySetInnerHTML={{
								__html: t("individual.confirm_your_mobile.form.title", {
									complement_message:
										(mobileNumber.slice(0, 3) === "+44" || mobileNumber === "") ?
											`<br/>${t("individual.confirm_your_mobile.form.uk_warning")}` : ""
								})
							}}
						/>
						<Grid
							xl={8.2}
							lg={8.2}
							md={8.2}
							sm={6}
							xs={12}
							item
							container
						>
							<Typography
								className={"label-code"}
							>
								{t("individual.mobile_number")}
							</Typography>
							<MobileInput
								individual={profile_data}
								mobileNumber={mobileNumber}
								stepErrorText={stepErrorText}

								setMobileNumber={setMobileNumber}
								setStepErrorText={setStepErrorText}
								t={t}
							/>
							<Fade in={stepErrorText !== "" ?? false}>
								<small className={'collegia-text-danger default-error'}>
									{stepErrorText}
								</small>
							</Fade>
							<Button
								fullWidth
								onClick={() =>
									_executeDefaultMobileChangeNumber(
										mobileNumber,
										profile_data,
										update,
										generateAutoSignUpToken,
									)
								}
								disabled={!isValidPhoneNumber(mobileNumber)}
							>
								{t("individual.login.continue_button")}
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
	);
}

export default MobileForm;