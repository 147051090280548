import React from "react";

import {retrieveCollegiaAccessToken} from "../utils/Helpers";
import Slide from "@mui/material/Slide";
import Grid from "@mui/material/Grid";
import {connect} from "react-redux";
import Wrapper from "../layout/Logged/Wrapper";
import {me, update} from "../actions/Profile";
import Activated from "../components/ActiveYourPension/Activated";
import {PrimaryHeader} from "../components/Headers/PrimaryHeader";
import {useTranslation} from "react-i18next";

/**
 * @returns {*}
 */
const PensionActivated = ({
	                          history = () => {
	                          }
                          }) => {
	const {t} = useTranslation();

	if (!retrieveCollegiaAccessToken()) {
		window.location = "/login";
	}
	return (
		<Wrapper hideFooter={true} t={t}>
			<Slide
				in={true}
				direction={"up"}
			>
				<div>
					<Grid
						direction={"column"}
						alignItems={"center"}
						alignContent={"center"}
						id={"pension-activated-wrapper"}
						container
						item
					>
						<PrimaryHeader
							history={history}
							backIcon
							backPage={"/main-page"}
						>
							{t("individual.activate_your_pension.title")}
						</PrimaryHeader>
						<Activated
							history={history}
						/>
					</Grid>
				</div>
			</Slide>
		</Wrapper>
	);
}


/**
 * @param state
 * @returns {*}
 */
const mapStateToProps = state => {
	const {
		Profile = [],
	} = state;

	return {
		...Profile,
	}
};

/**
 * @param dispatch
 * @returns {{ninAndAddress: (function(*[]=): *)}}
 */
const mapDispatchToProps = dispatch => ({
	update: (id: any[] = false, data: any[] = false) => dispatch(update(id, data)),
	me: () => dispatch(me()),
});

export default connect(mapStateToProps ?? {}, mapDispatchToProps ?? {})(PensionActivated);