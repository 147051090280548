import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

/**
 * @param t
 * @returns {JSX.Element}
 * @constructor
 */
const FormHeader = (
	{
		t = key => key,
	}
): JSX.Element => (
	<Grid
		xs={12}
		md={12}
		sm={12}
		lg={12}
		item
		container
	>
		<Typography paragraph>
			{t("individual.other_pensions.new.subtitle")}
		</Typography>
	</Grid>
);

export default FormHeader;