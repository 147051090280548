import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CountUp from "react-countup";
import Slider from "@mui/material/Slider";

/**
 * @returns {number}
 * @private
 * @param genericProjectionParameters
 * @param percentage
 */
function _yearlyIncome(genericProjectionParameters: [], percentage = 0): number {
	let totalYearlyIncome = 0;

	if (genericProjectionParameters) {
		if (genericProjectionParameters.full_yearly_state_pension &&
			typeof genericProjectionParameters.full_yearly_state_pension.replace === "function") {

			totalYearlyIncome = parseFloat(genericProjectionParameters.full_yearly_state_pension.replace(",", ""));
		} else {
			totalYearlyIncome = genericProjectionParameters.full_yearly_state_pension;
		}
	}

	if (totalYearlyIncome > 0) {
		totalYearlyIncome = totalYearlyIncome / 52;
	}

	if (percentage) {
		if (percentage) {
			totalYearlyIncome = totalYearlyIncome * (parseInt(percentage) / 100);
		}
	}

	if (totalYearlyIncome <= -1) {
		totalYearlyIncome = Math.abs(totalYearlyIncome);
	}

	return totalYearlyIncome ?? 0;
}

/**
 * @param props
 * @returns {*}
 */
const SetUpCard = ({
	percentage = 0,
	marks = [],
	statePensionIncluded = false,
	genericProjectionParameters = [],
	setPercentage = () => {
	},
	t = (key) => key,
}) => (
	<Grid
		className={"card-state-pension"}
	>
		<Typography className={"typography-title-style"}>
			{t("individual.state_pension.include_question")}
		</Typography>
		<Grid
			className={"card-size-adjust-style"}
		>
			<Slider
				className={"slider-style"}
				aria-label={"Custom marks"}
				step={25}
				marks={marks}
				value={parseInt(percentage)}
				onChange={(event) => setPercentage(event.target.value)}
			/>
		</Grid>
		{
			statePensionIncluded ?
				<Grid
					xs={12}
					sm={12}
					md={12}
					lg={12}
					xl={12}
					alignContent={"center"}
					alignItems={"end"}
					direction={"row"}
					item
					container
				>
					<Grid
						xs={6}
						sm={6}
						md={6}
						lg={6}
						xl={6}
						item
						container
						alignContent={"flex-start"}
						alignItems={"flex-start"}
						direction={"column"}
					>
						<Grid
							className={"including-total-of"}
							item
						>
							<Typography>
								{t("individual.state_pension.total_of")}
							</Typography>
						</Grid>
					</Grid>
					<Grid
						xs={6}
						sm={6}
						md={6}
						lg={6}
						xl={6}
						item
						container
						alignContent={"flex-end"}
						alignItems={"flex-end"}
						direction={"column"}
					>
						<Grid
							item
						>
							<CountUp
								className={"weekly-value"}
								start={0}
								end={_yearlyIncome(genericProjectionParameters, percentage)}
								separator=","
								decimals={2}
								decimal="."
								duration={1}
								prefix={`£ `}
							/>
							<span className={"per-week"}>
								{` ${t("individual.week")}`}
                            </span>
						</Grid>
					</Grid>
				</Grid>
				:
				[]
		}
	</Grid>
);

export default SetUpCard;
