import {CONFIRM_CODE, RESEND_CODE} from "./types";
import ActivationToken from "../../services/ActivationToken";
import {me} from "../Profile";
import {retrieveByToken} from "../ActivationToken";

/**
 * @param data
 * @returns {function(...[*]=)}
 */
export const resendCode = (data = false) => dispatch => {
	if (data) {
		dispatch({
			type: RESEND_CODE,
			activation_code_loading: true,
		});

		new ActivationToken()
			.resendCode(data)
			.then(response => {
				dispatch({
					type: RESEND_CODE,
					activation_code_loading: false,
					activation_code_data: {
						...response.data.data
					},
				});
			})
			.catch(error => {
				console.error(error);
				dispatch({
					type: RESEND_CODE,
					activation_code_loading: false,
				});

			});
	}
};

/**
 * @param data
 * @param token
 * @returns {function(...[*]=)}
 */
export const confirmCode = (data = false, token = "") => dispatch => {
	if (data) {
		dispatch({
			type: CONFIRM_CODE,
			activation_code_loading: true,
			activationCodeData: {
				canProceedStepTwo: false,
			},
			errorMessage: ""
		});

		new ActivationToken()
			.confirmCode(data)
			.then(response => {
				if (response.data.data.canProceed) {
					dispatch({
						type: CONFIRM_CODE,
						activation_code_loading: false,
						activationCodeData: {
							canProceedStepTwo: response.data.data.canProceed
						},
					});
					if (token) {
						dispatch(retrieveByToken(token));
					}
				} else {
					dispatch({
						type: CONFIRM_CODE,
						activation_code_loading: false,
						activationCodeData: {
							canProceedStepTwo: false
						},
					});
				}
			})
			.catch(error => {
				console.error(error);
				dispatch({
					type: CONFIRM_CODE,
					activation_code_loading: false,
					errorMessage: error.response.data.data.message,
					canProceedStepTwo: false,
				});
			});
	} else {
		dispatch({
			type: CONFIRM_CODE,
			activation_code_loading: false,
			activationCodeData: {
				canProceedStepTwo: false,
			}
		});
	}
};

/**
 * @param data
 * @returns {(function(*): void)|*}
 */
export const generateAutoSignUpToken = (data: {} => {}) => dispatch => {
	if (data) {
		new ActivationToken()
			.generateAutoSignUpToken(data ?? false)
			.then(response => {
				if (response.data.data.canProceed) {
					dispatch({
						type: CONFIRM_CODE,
						activation_code_loading: false,
						...response?.data?.data,
					});
					dispatch(me());
				} else {
					dispatch({
						type: CONFIRM_CODE,
						activation_code_loading: false,
						stepErrorText: "Something went wrong, please contact Collegia support.",
					});
				}
			})
			.catch(error => {
				console.error(error);
				dispatch({
					type: CONFIRM_CODE,
					activation_code_loading: false,
					...error.data,
				});
			});
	}
}