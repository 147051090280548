import React, {useEffect, useState} from 'react';

import {connect} from "react-redux";
import {
	cancelPrepareEmandate,
	createIndividualSchedule,
	verifyIfTheMandateFinish
} from "../../actions/ExtraContributions";
import Wrapper from "../../layout/Logged/Wrapper";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {retrieveCollegiaAccessToken} from "../../utils/Helpers";
import {me} from "../../actions/Profile";
import {useNavigate} from "react-router-dom";
import Loading from "../../components/Loading";

const ConfirmMonthlyContributions = ({
	profile_data = [],
	profile_loading = false,
	me = () => {},
	cancelPrepareEmandate = () => {},
	verifyIfTheMandateFinish = () => {},
	verify_emandate_loading = false,
	createIndividualSchedule = () => {}
}): JSX.Element => {
	const push = useNavigate();
	const [link, setLink] = useState("");
	const navigate = useNavigate();
	useEffect(() => {
		me();
	}, [me]);

	useEffect(() => {
		const prepareEmandateId = profile_data?.prepare_emandate?.id;
		const verifyAndSetInterval = () => {
			verifyIfTheMandateFinish(prepareEmandateId);
		};
		verifyAndSetInterval();
		const intervalId = setInterval(verifyAndSetInterval, 10000);
		return () => clearInterval(intervalId);

	}, [profile_data?.prepare_emandate?.id, verifyIfTheMandateFinish]);

	useEffect(() => {
		if (profile_data?.prepare_emandate?.active) {
			if (process.env.REACT_APP_ENV === "production") {
				setLink("https://api.nuapay.com/tpp-ui/emandate-ui/redirect?conversationToken=" + profile_data.prepare_emandate?.token);
			} else {
				setLink("https://sandbox.nuapay.com/tpp-ui/emandate-ui/redirect?conversationToken=" + profile_data?.prepare_emandate?.token);
			}
		}
	}, [setLink, profile_data]);
	useEffect(() => {
		if (profile_data?.individual_monthly?.active === true && profile_data?.payment_schedule === null) {
			createIndividualSchedule();
		}
	}, [createIndividualSchedule, profile_data]);

	if (!retrieveCollegiaAccessToken()) {
		push("/login");
	}

	if (profile_loading) {
		return (
			<Loading absolute/>
		);
	}

	return (
		<Wrapper
			history={navigate}
			textHeader={"Complete your mandate"}
			hideFooter={true}
		>
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				container
				justifyContent={"center"}
				id={"retirement-age-style"}
			>
				{
					profile_data?.prepare_emandate?.active ?
						<Grid
							xs={12}
							sm={12}
							lg={12}
							md={12}
							alignContent={"center"}
							direction={"column"}
							item
							container
						>
							<Grid
								paddingBottom={"20px"}
							>
								<Typography
									variant={"h4"}
									align={"center"}
								>
									Finish your mandate to continue
								</Typography>
							</Grid>
							<Typography
								variant={"h5"}
								align={"center"}
							>
								{"Lost the link of the your mandate? Click "}
								<a href={link} target={"_blank"} rel={"noopener noreferrer"}>here</a>
								{" to be redirect"}
							</Typography>
							<Typography
								variant={"h5"}
								align={"center"}
							>
								Or click
								<span
									className={"span-red"}
									onClick={() => cancelPrepareEmandate(profile_data?.prepare_emandate?.id)}
								>
							{" here "}
						</span>
								to cancel the mandate
							</Typography>
						</Grid>
						:
						<Grid
							xs={12}
							sm={12}
							lg={12}
							md={12}
							alignContent={"center"}
							direction={"column"}
							item
							container
						>
							<Grid
								paddingBottom={"20px"}
							>
								<Typography
									variant={"h5"}
									align={"center"}
								>
									Great! You finish your mandate
									<br/>
									Now we are finish your monthly payment schedule please wait some minutes
									<br/>
									If this screen take so long, please enter in contact with the support
								</Typography>
							</Grid>
						</Grid>
				}
				{
					verify_emandate_loading ?
						<Grid
							container
							alignItems={"center"}
							alignContent={"center"}
						>
							<Loading/>
						</Grid>
						:
						<></>
				}
			</Grid>
		</Wrapper>
	);
}

const mapStateToProps = state => {
	const {
		ExtraContributions = [],
		Profile = [],
	} = state;

	return {
		...ExtraContributions,
		...Profile,
	};
}

/**
 * @param dispatch
 * @returns {{details: (function(string=): *)}}
 */
const mapDispatchToProps = dispatch => ({
	me: () => dispatch(me(true, false)),
	cancelPrepareEmandate: (id: number) => dispatch(cancelPrepareEmandate(id)),
	verifyIfTheMandateFinish: (id) => dispatch(verifyIfTheMandateFinish(id)),
	createIndividualSchedule: () => dispatch(createIndividualSchedule()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmMonthlyContributions);