import React, {useState} from "react";
import Wrapper from "../../layout/Logged/Wrapper";
import Grid from '@mui/material/Grid';
import Slide from '@mui/material/Slide';
import Paper from '@mui/material/Paper';
import FormHeader from "../../components/OtherPensions/New/FormHeader";
import Form from "../../components/OtherPensions/New/Form";
import {connect} from "react-redux";
import {create} from "../../actions/OtherPensions";
import {PrimaryHeader} from "../../components/Headers/PrimaryHeader";
import {useTranslation} from "react-i18next";
import {me} from "../../actions/Profile";
import {useNavigate} from "react-router-dom";

/**
 * @param create
 * @returns {JSX.Element}
 * @constructor
 */
const AddNewPension = (
	{
		create = () => {
		},
	}
): JSX.Element => {

	const [pensionProvider, setPensionProvider] = useState(""),
		[pensionScheme, setPensionScheme] = useState(""),
		[membershipNumber, setMembershipNumber] = useState(""),
		[totalPot, setTotalPot] = useState(0),
		{t} = useTranslation(),
		history = useNavigate();

	return (
		<Wrapper t={t} history={history}>
			<Slide in={true} direction={"left"}>
				<div>
					<Grid
						direction={"column"}
						alignItems={"center"}
						alignContent={"center"}
						id={"other-pensions-wrapper"}
						container
						item
					>
						<PrimaryHeader
							history={history}
							backPage={"/other-pensions"}
							backIcon
						>
							{t("individual.other_pensions.new.title")}
						</PrimaryHeader>
						<Paper
							id={"form-holder"}
						>
							<FormHeader t={t}/>
							<Form
								pensionProvider={pensionProvider}
								pensionScheme={pensionScheme}
								membershipNumber={membershipNumber}
								totalPot={totalPot}
								setPensionProvider={setPensionProvider}
								setPensionScheme={setPensionScheme}
								setMembershipNumber={setMembershipNumber}
								setTotalPot={setTotalPot}
								create={create}
								history={history}
								t={t}
							/>
						</Paper>
					</Grid>
				</div>
			</Slide>
		</Wrapper>
	);
}


const mapDispatchToProps = dispatch => ({
	create: (data: any = false) => dispatch(create(data)),
	me: dispatch(me()),
});

export default connect(false, mapDispatchToProps)(AddNewPension);
