import ConnectionsService from "../../services/Connections";
import {FETCH_CONNECTION_CODE} from "./types";
import {me} from "../Profile";


export const getByCode = (code:string = "") => dispatch => {
	if (code) {
		dispatch({
			type: FETCH_CONNECTION_CODE,
			loading: true,
		});
		new ConnectionsService()
			.getByCode(code ?? false)
			.then(response =>
				dispatch({
					type: FETCH_CONNECTION_CODE,
					loading: false,
					...response.data.data,
				}))
			.catch(error => {
				if (error.response) {
					if (error.response.status === 401) {
						localStorage.clear();
						window.location = "/login";
					}
				}
				dispatch({
					type: FETCH_CONNECTION_CODE,
					loading: false,
				});
				console.error(error);
			});
	}
}

/**
 * @param code
 * @param action
 * @param redirect
 * @returns {(function(*): void)|*}
 */
export const buttonActions = (code:string = "", action:string = "authorize", redirect = true) => dispatch => {
	if (code && action) {
		dispatch({
			type: FETCH_CONNECTION_CODE,
			loading: true,
		});

		if (action === "authorize") {
			new ConnectionsService()
				.authorize(code ?? false)
				.then(() => {
					if (redirect) {
						window.location.href = "/main-page";
					} else {
						dispatch(me());
					}
					dispatch({
						type: FETCH_CONNECTION_CODE,
						loading: false,
					});
				})
				.catch(error => {
					dispatch({
						type: FETCH_CONNECTION_CODE,
						loading: false,
					});
					console.error(error);
				});

		} else if (action === "deny") {
			new ConnectionsService()
				.deny(code ?? false)
				.then(() => {
					if (redirect) {
						window.location.href = "/main-page";
					} else {
						dispatch(me());
					}
					dispatch({
						type: FETCH_CONNECTION_CODE,
						loading: false,
					});
				})
				.catch(error => {
					dispatch({
						type: FETCH_CONNECTION_CODE,
						loading: false,
					});
					console.error(error);
				});
		} else {
			dispatch({
				type: FETCH_CONNECTION_CODE,
				loading: false,
			});
		}
	}
}