import React from 'react';
import {Grid, Link, Typography} from "@mui/material";
import footerLogo from "../images/copyright-logo.svg";

/**
 * @param props
 * @returns {*[]|*}
 * @private
 */
function _renderLogo(props: any = false): *[] | * {
	if (!props.removeLogo) {
		return (

			<Grid
				lg={12}
				className={"container-logo"}
				item
			>
				<img
					src={footerLogo}
					alt={"Collegia logo"}
				/>
			</Grid>
		);
	}

	return [];
}

/**
 * @stateless
 * @returns {*}
 */
const Footer = props => (
	<Grid
		alignItems={"center"}
		alignContent={"center"}
		id={"footer"}
		direction={"column"}
		item
		container
	>
		{_renderLogo(props ?? false)}

		<Grid
			lg={12}
			className={"copyrights"}
			item
		>
			<Typography
				component={"p"}
				align={"center"}
				variant={"caption"}
				className={"terms-and-conditions"}
			>
				<Link
					href={"https://www.collegia.co.uk/legal"}
					target={"_blank"}
				>
					Legal Help
				</Link>
			</Typography>
			<Typography
				align={"center"}
				component={"p"}
				variant={"subtitle1"}
			>
				{new Date().getFullYear()} © {" "}
				<Link
					href={"https://www.collegia.co.uk/"}
					target={"_blank"}
				>
					Collegia.co.uk
				</Link>
				- Oxford, United Kingdom.
			</Typography>
		</Grid>
	</Grid>
);

export default Footer;