import React, {useEffect, useState} from 'react';
import {retrieveCollegiaAccessToken} from "../utils/Helpers";
import {connect} from "react-redux";
import {retrieveByToken} from "../actions/ActivationToken";
import Loading from "../components/Loading";
import googlePlayButton from '../images/Google-Play-Badge-US2x.png';
import appStoreButton from '../images/App-Store-Badge-US-Black2x.png';
import mobileImage from '../images/iphone-c.png';
import {useTranslation} from "react-i18next";

/**
 * @param history
 * @param retrieveByToken
 * @param match
 * @param token_loading
 * @param language
 * @returns {JSX.Element}
 * @constructor
 */
const AccountActivated = (
	{
		history = {
			push: () => {
			}
		},
		retrieveByToken = (token = "", modalLoading = false) => {
		},
		match = {
			params: {
				token: ""
			}
		},
		token_loading = false,
		language = "GB",
	}
): JSX.Element => {
	const [token, setToken] = useState();
	const [tokenData, setTokenData] = useState(),
		{t, i18n} = useTranslation();

	useEffect(() => {
		i18n.changeLanguage(language);
	}, [
		language,
		i18n,
	]);

	useEffect(() => {
		setToken(
			match.params && match.params.token ?
				match.params.token :
				false,
		);

		setTokenData(false);

		if (tokenData) {
			const {
				individual
			} = tokenData;

			if (individual) {
				const {
					status = 3
				} = individual;
				if (status === -1) {
					history(`/account-deleted/${token}`);
				} else if (status === 1) {
					history("/home");
				} else if (status === 3) {
					history(`/activation/${token}`);
				} else if (status === 5) {
					history(`/main-page`);
				}
			}
		}

		const root = document.getElementById("root");

		if (root) {
			root.style.backgroud = "#f2f2f4";
		}
	}, [tokenData, token, history, match, retrieveByToken]);

	useEffect(() => {
		// removing the menu after the component mounted.
		const menu = document.getElementById("menu");

		if (menu) menu.remove();

		if (!token) setToken(token);

		if (token === "") {
			history("/home");
		}

		if (tokenData) {
			const {
				individual
			} = tokenData;

			if (individual) {
				const {
					status = 3
				} = individual;
				if (status !== 6 && status !== 1) {
					history(`/account-activated/${token}`);
				} else if (status === 1) {
					history("/home");
				} else if (status === 3) {
					history(`/activation/${token}`);
				}
			}
		}
	}, [history, token, tokenData]);

	const _renderContinueButton = (t = key => key) => {
		if (!retrieveCollegiaAccessToken()) {
			return (
				<a
					className={"btn-continue w-button"}
					href={"/login"}
				>
					{t("individual.account_activated.or_login_to_continue")}
				</a>
			)
		}

		return (
			<a
				className={"btn-continue w-button"}
				href={"/main-page"}
			>
				{t("individual.account_activated.continue_on_the_web")}
			</a>
		);
	};

	if (token_loading) {
		return (
			<Loading
				absolute
			/>
		);
	}

	return (
		<div className="body-2 margin-0-auto">
			<div className="section-10">
				<div className="div-block-4">
					<img
						src={mobileImage}
						width="446"
						srcSet={`
							${mobileImage} 500w, 
							${mobileImage} 800w, 
							${mobileImage} 1080w, 
							${mobileImage} 1600w, 
							${mobileImage} 2000w, 
							${mobileImage} 2196w
						`}
						sizes={"(max-width: 479px) 87vw, (max-width: 991px) 330px, 446px"}
						alt=""
						className="image-5"
					/>
				</div>
				<div className="div-block-5">
					<h1 className="heading-4">
						<strong
							className="bold-text"
							dangerouslySetInnerHTML={{
								__html: t("individual.account_activated.thanks_for_activating_your_account")
							}}
						/>
					</h1>
					<p
						className="paragraph-4"
						dangerouslySetInnerHTML={{
							__html: t("individual.account_activated.start_your_collegia_journey")
						}}
					/>
					<div
						className="div-block-6"
					>
						<a
							href={
								"https://apps.apple.com/" +
								"us/app/collegia/id1554864077"
							}
							target={"_blank"}
							rel="noreferrer"
						>
							<img
								src={appStoreButton}
								width="135"
								alt=""
							/>
						</a>

						<a
							href={"https://play.google.com/store/apps/details?id=com.collegia.mobile.application"}
							target={"_blank"}
							rel="noreferrer"
						>
							<img
								src={googlePlayButton}
								width="135"
								alt=""
								className="image-4"
							/>
						</a>
					</div>
					{_renderContinueButton(t)}
				</div>
			</div>
		</div>
	);
};

/**
 * @param state
 * @returns {*}
 */
const mapStateToProps = state => state.ActivationToken;

/**
 * @param dispatch
 * @returns {{retrieveByToken: (function(*=, *=): *)}}
 */
const mapDispatchToProps = dispatch => ({
	retrieveByToken:
		(token = false, modalLoading = true) =>
			dispatch(retrieveByToken(token, modalLoading, false)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountActivated);