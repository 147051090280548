import React from "react";
import {useState, useEffect} from "react";
import {connect} from "react-redux";
import {setPusherClient} from "react-pusher";
import Pusher from "pusher-js";
import * as Notifications from "../actions/Notifications";
import {me} from "../actions/Profile";

/**
 * @param profile_data
 * @param children
 * @param pushNotification
 * @param getNotifications
 * @returns {JSX.Element}
 * @constructor
 */
const NotificationProvider: React.Component = (
	{
		profile_data = {
			id: null,
		},
		children = () => <></>,
		pushNotification = () => {
		},
		getNotifications = () => {
		},
	}: {
		profile_data: any,
		children: any,
		pushNotification: Function,
		getNotifications: Function,
	}
): JSX.Element => {

	const [id, setId] = useState(null);
	const [pusherClient] = useState(new Pusher(process.env.REACT_APP_PUSHER_ID, {
		cluster: 'eu'
	}));

	useEffect(() => setPusherClient(pusherClient), [pusherClient]);

	useEffect(() => {
		if (!profile_data) {
			setId(JSON.parse(localStorage.getItem("profile_data"))?.id);
		} else {
			setId(profile_data.id);
		}

		const channel_name = `individual-notification-channel-${id}-${process.env.REACT_APP_ENV}`;
		const event_name = `individual-notification-event-${id}-${process.env.REACT_APP_ENV}`;

		const channel = pusherClient.subscribe(channel_name);

		channel.bind(event_name, event => {
			pushNotification(event?.message);
			getNotifications();
		});

		return () => {
			channel.unbind_all();
			pusherClient.unsubscribe(channel_name);
		};
	}, [
		id,
		pushNotification,
		pusherClient,
		getNotifications,
		profile_data,
	]);

	return <>{children}</>;
};

export default connect(
	state => state.Profile,
	dispatch => ({
		me: dispatch(me()),
		pushNotification: notif => dispatch(Notifications.notificationsSnackBar(notif)),
		getNotifications: () => dispatch(Notifications.getNotification()),
	}))(NotificationProvider);