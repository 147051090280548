import React, {useEffect} from 'react';
import Wrapper from "../layout/Logged/Wrapper";
import Grid from '@mui/material/Grid';
import Slide from '@mui/material/Slide';
import {removeDefaultLoggedLayout} from "../utils/Helpers";
import {connect} from "react-redux";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {me} from "../actions/Profile";
import {useTranslation} from "react-i18next";

/**
 * @param profile_data
 * @param history
 * @returns {JSX.Element}
 * @constructor
 */
const ActiveYourPension = (
		{
			profile_data = {
				national_insurance_number: "",
			},
			history = {
				push: () => {
				}
			},
		}
	): JSX.Element => {
		removeDefaultLoggedLayout();
		const {t} = useTranslation();


		useEffect(() => {
			if (profile_data?.national_insurance_number) {
				history("/main-page");
			}

			removeDefaultLoggedLayout();
		}, [profile_data, history]);

		return (
			<Wrapper>
				<Slide direction={"up"} in={true}>
					<Grid
						xs={12}
						sm={12}
						md={12}
						lg={12}
						id={"inner-active-your-pension-container"}
						container
						item
					>
						<Grid
							xs={12}
							sm={12}
							md={12}
							lg={12}
							item
						>
							<IconButton
								onClick={() => history("/main-page")}
								className={'header-close-button'}
								disableFocusRipple
								disableTouchRipple
								disableRipple
							>
								<CloseIcon/>
							</IconButton>
						</Grid>
						<Grid
							id={"body-wrapper"}
							item
						>
							<Grid
								xs={12}
								md={12}
								lg={12}
								sm={12}
								container
								item
							>
								<Grid
									xs={12}
									sm={12}
									md={12}
									lg={12}
									item
								>
									<Typography
										component={"p"}
										variant={"caption"}
										className={"information-caption"}
									>
										{t("individual.for_regulatory_purposes_we_need_your")}
										<br/>
									</Typography>

									<Typography
										component={"h1"}
										className={"information-caption"}
										variant={"caption"}
										dangerouslySetInnerHTML={{
											__html: t("individual.active_pension.national_insurance_number_full_address"),
										}}
									/>
								</Grid>
							</Grid>

							<Grid
								xs={12}
								sm={12}
								md={12}
								lg={12}
								item
							>
								<Button
									variant={"outlined"}
									onClick={() => history("/active-pension/nin-address")}
								>
									{t("individual.lets_do_it")}
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Slide>
			</Wrapper>
		);
	}
;

/**
 * @param state
 * @returns {*}
 */
const mapStateToProps = state => state.Profile;

/**
 * @param dispatch
 * @returns {{me: (function(): *)}}
 */
const mapDispatchToProps = dispatch => ({
	me: dispatch(me()),
});


export default connect(mapStateToProps, mapDispatchToProps)(ActiveYourPension);